import {styled, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import FooterBeforeLogin from "../navbar/FooterBeforeLogin";
import NavBarBeforeLogin from "../navbar/NavBarBeforeLogin";
import {dashboardinstructionAPi} from "../../http/allApis";

const OutletDiv = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  overflow: "auto",
  width: "100%",
  cursor: "default",
  [theme.breakpoints.down(1200)]: {
    height: "auto",
  },
}));

function LandingPage() {
  const theme = useTheme();
  const [anncBannerData, setAnncBannerData] = useState([]);
  const [enableAnnouncmentBanner, setEnableAnnouncmentBanner] = useState(false);
  const getBannerAnnouncement = () => {
    dashboardinstructionAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          // console.log(res.data.status);
          const anncBData = res?.data?.data;
          const annoucmentStatusValue = anncBData.filter(
            (item) => item.announcementStatus,
          );
          const enableAnnouncement =
            annoucmentStatusValue && annoucmentStatusValue?.length > 0
              ? true
              : false;
          const getFirstPositionAnnouncement =
            annoucmentStatusValue && annoucmentStatusValue?.length > 0
              ? annoucmentStatusValue?.[0]
              : {};
          setAnncBannerData(getFirstPositionAnnouncement);
          setEnableAnnouncmentBanner(enableAnnouncement);
        }
      })
      .catch((e) => {
        /*if (e?.response?.data?.status === 500) {
                    setCatchError(e?.response?.data?.message);
                } else {
                    setLoading(false);
                }*/
      });
  };
  useEffect(() => {
    window.scroll(0, 0);
    getBannerAnnouncement();
  }, []);
  return (
    <div>
      {enableAnnouncmentBanner ? (
        <div
          style={{
            ...(anncBannerData.backgroundType === false
              ? {
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage:
                    "url(" + anncBannerData.backgroundImage + ")",
                }
              : {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : anncBannerData.backgroundColor,
                }),
            display: "flex",
            height: "40%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "1rem 0.75rem 1rem 1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <div>
              <p
                style={{
                  textAlign: "center",
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : anncBannerData.textColor,
                }}
                dangerouslySetInnerHTML={{
                  __html: anncBannerData.description,
                }}
              ></p>
            </div>
          </div>
        </div>
      ) : null}
      <NavBarBeforeLogin />
      <OutletDiv>
        <Outlet />
      </OutletDiv>
      <FooterBeforeLogin />
    </div>
  );
}

export default LandingPage;

import React, {createContext, useContext, useState} from "react";

// Create a context to store the shared value
const SharedValueContext = createContext();

// Custom hook to access the shared value context
export const useSharedValue = () => useContext(SharedValueContext);

// SharedValueProvider component to wrap your application
export const SharedValueProvider = ({ children }) => {
  const [sharedValue, setSharedValue] = useState(null);
  const [readBoolean, setReadBoolean] = useState(true);

  return (
    <SharedValueContext.Provider
      value={{ sharedValue, setSharedValue, readBoolean, setReadBoolean }}
    >
      {children}
    </SharedValueContext.Provider>
  );
};

import {styled} from "@mui/material";
import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import AdminNavBar from "./Nav/AdminNavBar";

const OutletDiv = styled("div")(({ theme }) => ({
  padding: "1rem 2%",
  minHeight: "100vh",
  overflow: "auto",
  width: "100%",
  cursor: "default",
  backgroundColor: "#ddd",
  [theme.breakpoints.down(1200)]: {
    padding: ".5rem 4%",
    height: "auto",
  },
}));

function AdminDashboard() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <AdminNavBar />
      <OutletDiv>
        <Outlet />
      </OutletDiv>
    </div>
  );
}

export default AdminDashboard;

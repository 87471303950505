import {Box, Grid} from "@mui/material";
import React, {useState} from "react";
import {TransparentHead} from "../../styledcomponents/beforeLoginWidgets";
import PerformanceCategory from "./PerformanceCategory";
import PerformanceOverTime from "./PerformanceOverTime";
import PerformanceSummary from "./PerformanceSummary";
import PerformanceType from "./PerformanceType";

function PerformancePage() {
  const [perfPage, setPerfPage] = useState("summary");

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Box
            style={{
              border: "1.5px solid  #EBECF0",
              borderRadius: "6px",
              marginTop: ".5rem",
            }}
          >
            <div
              style={{
                padding: "1rem",
                borderBottom: "1.5px solid  #EBECF0",
              }}
            >
              <TransparentHead variant="h5" fontWeight={"bold"}>
                Welcome
              </TransparentHead>
            </div>
            <div
              style={{
                padding: "1rem",
              }}
            >
              <PerformanceType
                perfPage={perfPage}
                setPerfPage={(val) => setPerfPage(val)}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Box
            style={{
              border: "1.5px solid  #EBECF0",
              borderRadius: "6px",
              marginTop: ".5rem",
            }}
          >
            <div
              style={{
                padding: "1rem",
                borderBottom: "1.5px solid  #EBECF0",
              }}
            >
              <TransparentHead variant="h5" fontWeight={"bold"}>
                {perfPage === "summary"
                  ? `Performance - question summary`
                  : perfPage === "category"
                    ? `Performance - by category`
                    : `Performance over time`}
              </TransparentHead>
            </div>
            <div
              style={{
                padding: "1rem",
              }}
            >
              {perfPage === "summary" ? (
                <PerformanceSummary />
              ) : perfPage === "category" ? (
                <PerformanceCategory />
              ) : (
                <PerformanceOverTime />
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default PerformancePage;

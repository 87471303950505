import {Button, styled, Toolbar} from "@mui/material";

export const StyledNBToolBar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  padding: ".3rem 0",
  // minHeight: "50px",
}));

export const StyledNBAdminToolBar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#2c3643",
  padding: ".3rem 0",
  // minHeight: "50px",
}));

export const StyledBeforeToolBar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  minHeight: "50px",
  borderTop: "4px solid #f38424",
}));

export const StyledNBDiv = styled("div")(({ theme }) => ({
  outline: "none",
  display: "block",
  [theme.breakpoints.down(700)]: {
    display: "none",
  },
}));

export const StyledNBResDiv = styled("div")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down(700)]: {
    display: "block",
  },
}));

export const StyledNBButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
  "&:focus": {
    outline: "none",
  },
  color: theme.palette.primary.main,
  [theme.breakpoints.down(720)]: {
    fontSize: "smaller",
  },
  outline: "none",
  // marginRight: ".5rem",
}));

export const StyledNBAdminButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  outline: "none",
  "&:focus": {
    outline: "none",
  },
  color: "#bcc2ca",
  [theme.breakpoints.down(720)]: {
    fontSize: "smaller",
  },
}));

import {CircularProgress, Grid, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {accOptions} from "../staticData/staticDBData";
import {AboutParaTypo} from "../styledcomponents/beforeLoginWidgets";
import AccOption from "./AccOption";
import PlanValidity from "./PlanValidity";
import {FlexDiv, PlanValidityDiv, StyleBlueBox, StyledGreenButton,} from "../styledcomponents/styledDBwidgets";
import {ChevronRight} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getSubscriptionMockApi, getSubscriptionQbApi} from "../../http/allApis";
import {isNullOrEmpty} from "../shared/utils/commonValidators";
import ModelFile from "../landing/ModelFile";

function DashboardComp() {
  const theme = useTheme();
  const [expandId, setExpandId] = useState("panel1");
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const person = JSON.parse(sessionStorage.getItem("person"));
  const [questionTestOptions, setQuestionTestOptions] = useState([]);
  const [mockTestOptions, setMockTestOptions] = useState([]);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const planValidity = useSelector((state) => state.planValidity.data);
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/student/highyield");
  };

  const fetchData = async (type) => {
    try {
      const response =
          type === "mock" ? await getSubscriptionMockApi() : await getSubscriptionQbApi(false);

      const data = response.data.result.map((item) => ({
        value:
            !isNullOrEmpty(item.offerAmount)
                ? `${item.id} #${item.offerAmount}`
                : `${item.id} #${item.amount}`,
        label:
            !isNullOrEmpty(item.offerAmount)
                ? `${item.name} ₹${item.offerAmount}`
                : `${item.name} ₹${item.amount}`,
        ...item,
      }));

      type === "mock" ? setMockTestOptions(data) : setQuestionTestOptions(data);
    } catch (error) {
      setModelWin(true);
      setCatchError(error?.response?.data?.message || "An error occurred.");
    }
  };

  useEffect(() => {
    fetchData("mock");
    fetchData("qb");
  }, []);


  return (
    <div>
      <Typography variant="h5" fontWeight={500} color="#31a188">
        My Account - {person?.fullName?.toUpperCase()}
      </Typography>
      <Grid container spacing={isSmall ? 0 : 3}>
        <Grid item xs={12} md={6.5}>
          <Typography
            variant="h6"
            marginTop={"1.5rem"}
          >
            Please select which resource you would like to use:
          </Typography>
          {planValidity && planValidity?.length !== 0 ? (
            planValidity?.map((plan, id) => (
              <PlanValidity
                key = {id}
                planDetails = {plan}
                mockOptions = {mockTestOptions}
                questionExamOptions = {questionTestOptions}
              />
            ))
          ) : (
            <div>
              <div
                style={{
                  height: "100%",
                  margin: "1rem 0",
                  backgroundColor: "#b8f1d485",
                  borderRadius: "4px",
                  padding: "2rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {planValidity.length > 0 ? (
                  <CircularProgress style={{ color: "#f38424" }} />
                ) : (
                  <AboutParaTypo variant="body2">
                    You don't have any resource. Please add resource to use.
                  </AboutParaTypo>
                )}
              </div>
            </div>
          )}
          {planValidity?.length === 0 ? null : (
            <StyleBlueBox style={{ marginTop: "1rem" }}>
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={12} md={8}>
                  <FlexDiv>
                    <Typography
                      fontWeight={500}
                      variant={isSmall ? "p" : "h6"}
                      color={theme.palette.mode === "dark" ? "#fff" : "#5A5A5A"}
                    >
                      {"High Yield"}
                    </Typography>
                  </FlexDiv>
                </Grid>
                <Grid item xs={12} md={4}>
                  <PlanValidityDiv>
                    <StyledGreenButton
                      size={isSmall ? "small" : "medium"}
                      style={{ outline: "none" }}
                      onClick={handleNavigation}
                    >
                      Use this resource <ChevronRight />
                    </StyledGreenButton>
                  </PlanValidityDiv>
                </Grid>
              </Grid>
            </StyleBlueBox>
          )}
        </Grid>
        <Grid item xs={12} md={5.5}>
          <Typography variant="h6" fontWeight={500} marginTop={"1.5rem"}>
            Account options
          </Typography>
          <div style={{ marginTop: "1rem" }}>
            {accOptions?.map((option, id) => (
              <AccOption
                title={option.title}
                planValidity={planValidity}
                mockOptions = {mockTestOptions}
                questionOptions = {questionTestOptions}
                callBack={(val) => setExpandId(val)}
                expandId={expandId}
                text={option.text}
                id={id + 1}
                type={option.type}
              />
            ))}
          </div>
        </Grid>
      </Grid>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default DashboardComp;

import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import React, {useEffect, useRef, useState} from "react";
import {
  adminQBAnswersApi,
  QBQuestionAddApi,
  QBQuestionDeleteApi,
  QBQuestionEditApi,
  QBQuestionRefApi,
  questionApi,
  SettingCategoryApiId,
  SettingSubCategoryApiId,
  SettingSubjectApi,
} from "../../../http/allAdminApi";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  InputBase,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import AdminTextEditor from "../widgets/AdminTextEditor";
import {Form, Modal, Switch} from "antd";
import {default as swal} from "sweetalert2";
import {useNavigate} from "react-router";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";

function AdminQuestion() {
  const questionReferenceUseRef = useRef(null);
  const questionExplanationUseRef = useRef(null);
  const questionUseRef = useRef(null);
  const [checkBox, setCheckBox] = useState(true);
  const [rowsData, setRowsData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [subCatVal, setSubCatVal] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [explanationText, setExplanationText] = useState("");
  const [referenceText, setReferenceText] = useState("");
  const qbOpen = Boolean(anchorEl);
  const [searchVal, setSearchVal] = useState("");
  const [resData, setResData] = useState([]);
  const [showPage, setShowPage] = useState("Table");
  const [loading, setLoading] = useState(false);
  const [paramData, setParamData] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [answerId, setAnswerId] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [catOptions, setCatOptions] = useState([]);
  const [subject, setSubject] = useState("");
  const [catVal, setCatVal] = useState([]);
  const [category, setCategory] = useState("");
  const [modelQB, setModelQB] = useState(false);
  const navigate = useNavigate();

  const handleQuestion = () => {
    setLoading(true);
    questionApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setRowsData(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    handleQuestion();
  }, []);

  const handleApiClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (showPage === "Add" || showPage === "Edit") {
  //     SettingSubCategoryApi().then((res) => {
  //       let data = res?.data?.data || [];
  //       data.sort((a, b) => a.subCategory.localeCompare(b.subCategory));
  //       if (showPage === "Add" && data?.length > 0) {
  //         setSubCatVal(data[0].id);
  //       }
  //       setSubCatOptions(data);
  //     });
  //   }
  // }, [showPage]);

  useEffect(() => {
    if (subject != undefined && subject != null && subject != "") {
      SettingCategoryApiId(subject).then((res) => {
        setCatVal(res.data.data);
      });
    }
  }, [subject]);

  useEffect(() => {
    if (category != undefined && category != null && category != "") {
      SettingSubCategoryApiId(category).then((res) => {
        setSubCatOptions(res.data.data);
      });
    }
  }, [category]);

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      handleQuestion();
      setModelQB(false);
      setAnchorEl(null);
      setShowPage("Table");
      setSubCatVal("");
      setSubject("");
      setCategory("");
      setReferenceText("");
      setExplanationText("");
      setQuestionText("");
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleQuesAdd = () => {
    let payload = {
      subjectId: subject,
      categoryId: category,
      subCategoryId: subCatVal,
      question: questionText,
      questionExplain: explanationText,
      questionRef: referenceText,
      status: false,
    };
    QBQuestionAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
      });
  };

  const handleQuesEdit = () => {
    let payload = {
      id: paramData?.id,
      subCategoryId: subCatVal,
      subjectId: subject,

      categoryId: category,
      question: questionText,
      questionExplain: explanationText,
      questionRef: referenceText,
      status: checkBox,
    };

    QBQuestionEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
      });
  };
  const handleQuestionSave = () => {
    if (showPage === "Add") {
      handleQuesAdd();
    }
    if (showPage === "Edit") {
      handleQuesEdit();
    }
  };

  const handleAnswer = (val) => {
    let quesVal = { ...val };
    quesVal.answer = [];
    adminQBAnswersApi(val?.id).then((res) => {
      if (res.data.status === "Success") {
        quesVal.answer = res?.data?.data || [];
        navigate("/admin/answers/" + val?.id, {
          state: {
            questionData: quesVal,
          },
        });
      }
    });
  };
  const handleSubjectApi = () => {
    SettingSubjectApi().then((res) => {
      if (res?.data?.status === "Success") {
        let data = res?.data?.data;
        setCatOptions(data);
      }
    });
  };

  const handleAction = (val, data1) => {
    setAnchorEl(null);
    if (val === "Add") {
      setShowPage(val);
      setModelQB(true);
      setCheckBox(false);
      handleSubjectApi();
    } else if (val === "Edit") {
      setShowPage(val);
      setModelQB(true);
      handleSubjectApi();
      setAnswerId(data1);
      QBQuestionRefApi(data1.id).then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data;
          setQuestionText(data.question);
          setReferenceText(data.myReference);
          setExplanationText(data.explanation);
          setCheckBox(data.status);
          setCategory(data.categoryId);
          setSubject(data.subjectId);
          setSubCatVal(data.subCategoryId);
        }
      });
    } else {
      handleAnswer(data1);
    }
  };

  const deleteApi = (id) => {
    let payload = {
      id: id,
    };
    QBQuestionDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
      });
  };

  const handleDelete = () => {
    setAnchorEl(null);
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          deleteApi(paramData?.id);
        }
      });
  };

  const handleClose = () => {
    setSubCatVal("");
    setSubject("");
    setCategory("");
    setReferenceText("");
    setExplanationText("");
    setQuestionText("");
    setShowPage("Table");
    setModelQB(false);
  };

  const statusCon = () => {
    swal.fire({
      text: "To change the status add answers for this question",
      icon: "warning",
      confirmButtonText: "Yes",
    });
  };
  const handleStatusCond = (e) => {
    if (showPage === "Add") {
      statusCon();
      setCheckBox(false);
    } else if (showPage === "Edit") {
      adminQBAnswersApi(answerId?.id).then((res) => {
        if (res.data.status === "Success") {
          if (res?.data?.data?.length == 0) {
            setCheckBox(false);
            statusCon();
          } else {
            setCheckBox(!checkBox);
          }
        }
      });
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 80, hide: true },
    {
      field: "question",
      headerName: "Question",
      flex: 6,
      renderCell: (params) => {
        return (
          <div
            style={{
              // whiteSpace: "pre-wrap",
              width: "100%",
              overflow: "auto",
              padding: "1rem 0 1rem .2rem",
            }}
          >
            <AboutParaTypo variant="p">
              <div
                style={{
                  width: "100%",
                  fontSize: "15px",
                  // marginBottom: ".2rem",
                }}
                dangerouslySetInnerHTML={{
                  __html: params?.row?.question,
                }}
              />
            </AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              // padding: "3px",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      hide: true,
      flex: 3,
    },
    {
      field: "category",
      headerName: "Category",
      hide: true,
      flex: 2,

      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              overflow: "auto",
              padding: "1rem",
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.category}</AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "mainCategory",
      headerName: "Subject",
      flex: 1,

      hide: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            <AboutParaTypo variant="p">
              {params?.row?.mainCategory}
            </AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      hide: true,
      /*renderCell: (params) => {
        return (
            <div
                style={{
                  whiteSpace: "pre-wrap"
                }}
            >
              <AboutParaTypo variant="p">
                {params?.row?.image}
              </AboutParaTypo>
            </div>
        );
      }*/
    },
    {
      field: "age",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
                fontSize: "10px",
              }}
              onClick={(e) => {
                setParamData(params.row);
                setAnchorEl(e.currentTarget);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleInputChange = (event) => {
    const value = event.target.value.trim().toLowerCase();
    const filteredResults = rowsData?.filter((item) => {
      const searchText = value.replace(/\s/g, "");
      const fieldsToSearch = [
        item.id?.toString() || "",
        item.mainCategory || "",
        item.question || "",
        item.subCategory || "",
        item.image.toString() || "",
      ].map((field) => field.replace(/\s/g, "").toLowerCase());

      return fieldsToSearch.some((field) => field.includes(searchText));
    });
    setSearchVal(value);
    setResData(filteredResults);
  };

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        QUESTIONS
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "1rem",
        }}
      >
        {/* {showPage === "Add" || showPage === "Edit" ? ( */}
        <div style={{ width: "100%" }}>
          <Modal
            // style={{  }}
            width={1350}
            title={
              <Typography variant="h6" gutterBottom>
                Question{" "}
              </Typography>
            }
            open={modelQB}
            onOk={() => setModelQB(false)}
            onCancel={handleClose}
            footer={null}
          >
            <div>
              <TextField
                select
                label="Subject"
                size="small"
                value={subject}
                id="name"
                style={{
                  margin: ".5rem 0 1.5rem 0",
                }}
                SelectProps={{
                  MenuProps: {
                    style: { maxHeight: 300 },
                  },
                }}
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    border: "1px solid #31a188",
                    padding: "0.5rem 0 0.5rem 0.2rem",
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-formControl": {
                    top: "-8px",
                  },
                }}
                onChange={(e) => setSubject(e.target.value)}
              >
                <MenuItem value={""} />
                {catOptions?.map((item, id) => (
                  <MenuItem key={id} value={item.id}>
                    {item.subject}
                  </MenuItem>
                ))}
              </TextField>

              <div>
                <TextField
                  select
                  label="Category"
                  size="small"
                  value={category}
                  id="name"
                  style={{ margin: ".5rem 0 1.5rem 0" }}
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input": {
                      border: "1px solid #31a188",
                      padding: "0.5rem 0 0.5rem 0.2rem",
                      color: "#000",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiInputLabel-formControl": {
                      top: "-8px",
                    },
                  }}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem value={""} />
                  {catVal?.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      {item.category}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  size="small"
                  value={subCatVal}
                  label="Sub Category"
                  id="name"
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      border: "1px solid #31a188",
                      padding: "0.5rem 0 0.5rem 0.2rem",
                      color: "#000",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiInputLabel-formControl": {
                      top: "-8px",
                    },
                  }}
                  onChange={(e) => setSubCatVal(e.target.value)}
                >
                  <MenuItem value={""} />
                  {subCatOptions?.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      {item.subCategory}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <TransparentHead
              variant="h6"
              style={{
                color: "#2c3643",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Question
            </TransparentHead>
            <div
              style={{
                lineHeight: "1em",
                color: "#000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
                marginTop: "1rem",
              }}
            >
              <AdminTextEditor
                editorRef={questionUseRef}
                editorFor={"QBQuestion"}
                content={questionText}
                setContent={(val) => setQuestionText(val)}
              />
            </div>
            <TransparentHead
              variant="h6"
              style={{
                color: "#2c3643",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Explanation
            </TransparentHead>
            <div
              style={{
                lineHeight: "1em",
                color: "#000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
                marginTop: "1rem",
              }}
            >
              <AdminTextEditor
                editorRef={questionExplanationUseRef}
                editorFor={"QBExplanation"}
                content={explanationText}
                setContent={(val) => setExplanationText(val)}
              />
            </div>
            <TransparentHead
              variant="h6"
              style={{
                color: "#2c3643",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              Reference
            </TransparentHead>
            <div
              style={{
                lineHeight: "1em",
                color: "#000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
                marginTop: "1rem",
              }}
            >
              <AdminTextEditor
                editorRef={questionReferenceUseRef}
                editorFor={"QBReference"}
                content={referenceText}
                setContent={(val) => setReferenceText(val)}
              />
            </div>
            <div>
              <Form.Item label="Status">
                <Switch checked={checkBox} onChange={handleStatusCond} />
              </Form.Item>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor: "#dc3545",
                  color: "#fff",
                  marginRight: "1rem",
                  outline: "none",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  backgroundColor:
                    subCatVal === "" ||
                    category === "" ||
                    subject === "" ||
                    referenceText === "" ||
                    questionText === "" ||
                    explanationText === ""
                      ? "white"
                      : "#2c3643",
                  color:
                    subCatVal === "" ||
                    category === "" ||
                    subject === "" ||
                    referenceText === "" ||
                    questionText === "" ||
                    explanationText === ""
                      ? "black"
                      : "#fff",
                  outline: "none",
                }}
                disabled={
                  subCatVal === "" ||
                  category === "" ||
                  subject === "" ||
                  referenceText === "" ||
                  questionText === "" ||
                  explanationText === ""
                }
                onClick={handleQuestionSave}
              >
                Save
              </Button>
            </div>
          </Modal>
        </div>
        {/* ) : ( */}
        <div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <StyledGreenButton
              onClick={() => handleAction("Add")}
              style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
            >
              <Add fontSize="small" /> Add New
            </StyledGreenButton>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
              color: "black",
            }}
          >
            <InputBase
              placeholder="Search..."
              value={searchVal}
              size="small"
              style={{
                width: "35rem",
                lineHeight: "1em",
                color: "#000",
                border: "1px solid #000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
              }}
              onChange={handleInputChange}
            />
          </div>
          <AdminDataGrid
            loading={loading}
            columns={columns}
            rows={resData}
            // CustomPagination={Pagination}
          />
        </div>
        {/* )} */}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem
          id={"Answers"}
          onClick={() => handleAction("Answer", paramData)}
        >
          Answers
        </MenuItem>
        <MenuItem onClick={() => handleAction("Edit", paramData)}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete("Delete", paramData)}>
          Delete
        </MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminQuestion;

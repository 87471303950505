export const validityDBData = {
  result: [
    {
      id: 1,
      expiry: {
        date: "2023-05-28 05:03:57.000000",
        timezone_type: 3,
        timezone: "UTC",
      },
      name: "1 Month (30 days) Subscription",
      exam1: "Question Bank",
      subid: 1,
    },
    {
      id: 2,
      expiry: {
        date: "2023-02-04 05:53:01.000000",
        timezone_type: 3,
        timezone: "UTC",
      },
      name: "Mock Test 1",
      exam1: "Mock Test",
      subid: 5,
    },
  ],
};

export const mockTestContent = [
  "This mock exam simulates the NEET PG Exam and includes a total of 200 multiple choice questions from 19 subjects, over 3 and ½ hour period (210 minutes).",
  "The number and type of questions reflect recent NEET PG exams pattern. Therefore, this will give you a helpful estimate of your readiness for the final exam. Most of the questions in each Mock Test are different from the questions found in Question Bank and other Mock Tests.",
  "Please be aware that correct answers are not shown until you have finished the test. Answers can be reviewed once the test is finished.",
  "You have the option of saving and exiting the Mock Test. You may recommence partially completed Mock Test with the remaining time.",
  "You can take the Mock Test as many times as you wish. Please be aware that each Mock Test subscription is valid for 3 days from the date of purchase.",
];

export const accOptions = [
  {
    title: "Add a resource to my account",
    text: "When access to a particular resource has expired you will be given the option to extend from this page after logging in. Your question history, answers, notes and all other data will be preserved.",
    type: "qBank",
  },
  {
    title: "How do I extend my subscription?",
    text: "When access to a particular resource has expired you will be given the option to extend from this page after logging in. Your question history, answers, notes and all other data will be preserved.",
    type: "normal",
  },
  // {
  //   title: "Change my password",
  //   text: "When access to a particular resource has expired you will be given the option to extend from this page after logging in. Your question history, answers, notes and all other data will be preserved.",
  //   type: "normal",
  // },
  // {
  //   title: "Update my contact details",
  //   text: "When access to a particular resource has expired you will be given the option to extend from this page after logging in. Your question history, answers, notes and all other data will be preserved.",
  //   type: "normal",
  // },
  // {
  //   title: "Download receipt(s)",
  //   text: "When access to a particular resource has expired you will be given the option to extend from this page after logging in. Your question history, answers, notes and all other data will be preserved.",
  //   type: "normal",
  // },
];

//Profile Data

export const myProfileData = {
  result: [
    {
      id: 1,
      fullName: "kannan",
      mobile: "9445210355",
      email: "srkinfosystems@gmail.com",
      address1: "10, CLC Works Road",
      address2: "Chromepet",
      city: "Chennai",
      postalCode: "600044",
      state1: "Tamil Nadu",
      country: "IN",
      rdate: {
        date: "2022-10-31 16:12:29.000000",
        timezone_type: 3,
        timezone: "UTC",
      },
    },
  ],
};

export const allStates = {
  result: [
    {
      id: 1,
      stateCode: 35,
      state: "Andaman and Nicobar Islands",
    },
    {
      id: 3,
      stateCode: 37,
      state: "Andhra Pradesh",
    },
    {
      id: 4,
      stateCode: 12,
      state: "Arunachal Pradesh",
    },
    {
      id: 5,
      stateCode: 18,
      state: "Assam",
    },
    {
      id: 6,
      stateCode: 10,
      state: "Bihar",
    },
    {
      id: 7,
      stateCode: 4,
      state: "Chandigarh",
    },
    {
      id: 8,
      stateCode: 22,
      state: "Chattisgarh",
    },
    {
      id: 9,
      stateCode: 26,
      state: "Dadra and Nagar Haveli",
    },
    {
      id: 10,
      stateCode: 25,
      state: "Daman and Diu",
    },
    {
      id: 11,
      stateCode: 7,
      state: "Delhi",
    },
    {
      id: 12,
      stateCode: 30,
      state: "Goa",
    },
    {
      id: 13,
      stateCode: 24,
      state: "Gujarat",
    },
    {
      id: 14,
      stateCode: 6,
      state: "Haryana",
    },
    {
      id: 15,
      stateCode: 2,
      state: "Himachal Pradesh",
    },
    {
      id: 16,
      stateCode: 1,
      state: "Jammu and Kashmir",
    },
    {
      id: 17,
      stateCode: 20,
      state: "Jharkhand",
    },
    {
      id: 18,
      stateCode: 29,
      state: "Karnataka",
    },
    {
      id: 19,
      stateCode: 32,
      state: "Kerala",
    },
    {
      id: 20,
      stateCode: 38,
      state: "Ladakh",
    },
    {
      id: 21,
      stateCode: 31,
      state: "Lakshadweep Islands",
    },
    {
      id: 22,
      stateCode: 23,
      state: "Madhya Pradesh",
    },
    {
      id: 23,
      stateCode: 27,
      state: "Maharashtra",
    },
    {
      id: 24,
      stateCode: 14,
      state: "Manipur",
    },
    {
      id: 25,
      stateCode: 17,
      state: "Meghalaya",
    },
    {
      id: 26,
      stateCode: 15,
      state: "Mizoram",
    },
    {
      id: 27,
      stateCode: 13,
      state: "Nagaland",
    },
    {
      id: 28,
      stateCode: 21,
      state: "Odisha",
    },
    {
      id: 39,
      stateCode: 0,
      state: "Others",
    },
    {
      id: 29,
      stateCode: 34,
      state: "Pondicherry",
    },
    {
      id: 30,
      stateCode: 3,
      state: "Punjab",
    },
    {
      id: 31,
      stateCode: 8,
      state: "Rajasthan",
    },
    {
      id: 32,
      stateCode: 11,
      state: "Sikkim",
    },
    {
      id: 33,
      stateCode: 33,
      state: "Tamil Nadu",
    },
    {
      id: 34,
      stateCode: 36,
      state: "Telangana",
    },
    {
      id: 35,
      stateCode: 16,
      state: "Tripura",
    },
    {
      id: 36,
      stateCode: 9,
      state: "Uttar Pradesh",
    },
    {
      id: 37,
      stateCode: 5,
      state: "Uttarakhand",
    },
    {
      id: 38,
      stateCode: 19,
      state: "West Bengal",
    },
  ],
};

// invoice data

export const invoiceData = {
  data: {
    result: [
      {
        id: 1,
        invoicedate: {
          date: "2023-01-05 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "paymentID",
        amount: 1800,
        paiddate: {
          date: "2023-01-05 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 2,
        invoicedate: {
          date: "2023-01-23 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "paymentID",
        amount: 2500,
        paiddate: {
          date: "2023-01-23 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 3,
        invoicedate: {
          date: "2023-01-28 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "Payment Idpay_L9TWDkFOHPa4V1",
        amount: 1,
        paiddate: {
          date: "2023-01-28 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 4,
        invoicedate: {
          date: "2023-01-28 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "Payment Idpay_L9TWrhZ35G4t9d",
        amount: 1,
        paiddate: {
          date: "2023-01-28 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 5,
        invoicedate: {
          date: "2023-01-29 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "Payment Idpay_L9stAWmSCg0gXn",
        amount: 501,
        paiddate: {
          date: "2023-01-29 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 6,
        invoicedate: {
          date: "2023-01-29 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "10, CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "Payment Idpay_L9uPsHxgq9mcmy",
        amount: 1,
        paiddate: {
          date: "2023-01-29 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 7,
        invoicedate: {
          date: "2023-02-01 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "10, CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "Payment Idpay_LAzHd646IyIVuS",
        amount: 1,
        paiddate: {
          date: "2023-02-01 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
      {
        id: 8,
        invoicedate: {
          date: "2023-03-08 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
        fullName: "kannan",
        address1: "10, CLC Works Road",
        address2: "Chromepet",
        city: "Chennai",
        postalCode: "600044",
        state: "Tamil Nadu",
        stateCode: "33",
        country: "IN",
        mobile: "9445210355",
        status: true,
        remarks: "Payment Idpay_LP6bo5dEk7DYHc",
        amount: 1,
        paiddate: {
          date: "2023-03-08 00:00:00.000000",
          timezone_type: 3,
          timezone: "UTC",
        },
      },
    ],
  },
};

import React, {useEffect, useState} from "react";
import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {
    SettingCategoryAddApi,
    SettingCategoryApi,
    SettingCategoryDeleteApi,
    SettingCategoryEditApi,
    SettingSubjectApi,
} from "../../../http/allAdminApi";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    InputBase,
    Menu,
    MenuItem,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {Form, Modal, Switch} from "antd";
import {default as swal} from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";

function AdminCategory() {
  const [categoryData, setCategoryData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [catVal, setCatVal] = useState("");
  const [catOptions, setCatOptions] = useState([]);
  const [checkBox, setCheckBox] = useState(true);
  const [subjectVal, setSubjectVal] = useState("");
  const [modalFor, setModalFor] = useState("Add");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = categoryData?.filter((item) => {
      let finalValue = value.replace(/\s/g, "").toLowerCase();
      let finalCategory = item.category.replace(/\s/g, "").toLowerCase();
      let finalsubject = item.subject.replace(/\s/g, "").toLowerCase();

      const lowerCaseValue = value.toLowerCase();
      return (
        (item.id && item.id.toString().includes(value)) ||
        (finalCategory &&
          finalCategory.toLowerCase().includes(finalValue.toLowerCase())) ||
        (finalsubject &&
          finalsubject.toLowerCase().includes(finalValue.toLowerCase()))
      );
    });
    setResData(filteredResults);
  };

  const handleClose = () => {
    setModalData();
    setSubjectVal("");
    setCatVal("");
    setCatOptions([]);
    setOpenModal(false);
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      setSubjectVal("");
      setCatVal("");
      setOpenModal(false);
      handleCategory();
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleDeleteApi = (val) => {
    let payload = {
      categoryId: val,
    };
    SettingCategoryDeleteApi(payload)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setSuccessMsg(res?.data?.message);
          setAlertstatus("success");
          setAnchorEl(null);
          setOpen(true);
          setSubjectVal("");
          setCatVal("");
          setOpenModal(false);
          handleCategory();
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleMenu = (condition, id) => {
    setAnchorEl(null);
    if (condition === "edit") {
      mainCatApi();
      setCheckBox(paramData?.status);
      setModalData(paramData?.id);
      setSubjectVal(paramData?.subjectId);
      setCatVal(paramData?.category);
      setModalFor("Edit");
      setOpenModal(true);
    } else if (condition === "add") {
      mainCatApi();
      setModalFor("Add");
      setOpenModal(true);
    } else if (condition === "delete") {
      setAnchorEl(null);
      swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this, related categories, sub categories, questions and answers also will be deleted. You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            handleDeleteApi(paramData?.id);
          }
        });
    }
  };

  const handleAddApi = () => {
    let payload = {
      category: catVal,
      subjectId: subjectVal,
      status: checkBox,
    };
    SettingCategoryAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleEditApi = () => {
    let payload = {
      category: catVal,
      subjectId: subjectVal,
      categoryId: modalData,
      status: checkBox,
    };
    SettingCategoryEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const handleSave = (check) => {
    if (check === "Add") {
      handleAddApi();
    } else if (check === "Edit") {
      handleEditApi();
    }
  };

  const handleCategory = () => {
    SettingCategoryApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setCategoryData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };
  useEffect(() => {
    handleCategory();
  }, []);

  const mainCatApi = () => {
    SettingSubjectApi().then((res) => {
      setCatOptions(res.data.data);
    });
  };

  const onChange = (checked) => {
    setCheckBox(!checkBox);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
      hide: true,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 4,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem",
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.category}</AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              // padding: "3px",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 2,
      hide: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem",
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.subject}</AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "age",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Category
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleMenu("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add" ? "Add Category" : "Edit Category"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <div>
          <TextField
            select
            placeholder="Subject"
            size="small"
            value={subjectVal}
            // color="black"
            id="name"
            fullWidth
            onChange={(e) => setSubjectVal(e.target.value)}
            sx={{
              "& .MuiInputBase-input": {
                border: "1px solid #000",
                padding: "0.5rem 0.3rem 0.5rem 0.2rem",
                color: "#000",
              },
              "& .MuiInputLabel-root": {
                color: "#000",
              },
              "& .MuiInputLabel-formControl": {
                top: "-8px",
              },
            }}
            style={{ marginBottom: "1.5rem" }}
          >
            <MenuItem value={""} />
            {catOptions?.map((item, id) => (
              <MenuItem key={id} value={item.id}>
                {item.subject}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          placeholder="Category"
          // type="text"
          size="small"
          value={catVal}
          id="name"
          // style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          onChange={(e) => setCatVal(e.target.value)}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000",
            },
            "& .MuiInputLabel-root": {
              color: "#000",
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px",
            },
          }}
        />
        <Form.Item label="Status">
          <Switch checked={checkBox} onChange={onChange} />
        </Form.Item>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              backgroundColor:
                catVal === "" || subjectVal === "" ? "white" : "#2c3643",
              color: catVal === "" || subjectVal === "" ? "black" : "#fff",
              outline: "none",
            }}
            disabled={catVal === "" || subjectVal === ""}
            onClick={() => handleSave(modalFor)}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleMenu("edit")}>Edit</MenuItem>
        <MenuItem onClick={() => handleMenu("delete")}>Delete</MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminCategory;

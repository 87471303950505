import {IconButton, Radio, styled, TableCell, tableCellClasses, TableRow,} from "@mui/material";
import {Typography} from "antd";

export const StyledRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "#31a188",
  },
  marginLeft: ".5rem",
  color: "#31a188",
});

export const StyledQuizIconButton = styled(IconButton)({
  width: "fit-content",
  height: "fit-content",
  padding: 0,
  color: "#31a188",
  outline: "none",
});

export const StyledQuizDiv = styled("div")({
  width: "100%",
  border: "2px solid #f1f1f1",
  margin: "1.5rem 0",
  borderRadius: "4px",
});

export const StyledQuizBannerDiv = styled("div")({
  width: "100%",
  display: "flex",
  padding: ".5rem 0",
  justifyContent: "space-between",
  alignItems: "center",
});

export const StyledQuesDiv = styled("div")({
  border: "1px solid #adada",
  borderRadius: "4px",
  width: "100%",
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "10px",
  },
}));

export const RowResTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

export const RowResTypoInnerHtml = styled(Typography)(({ theme }) => ({
  width: "430px",
  overflow: "auto",
  [theme.breakpoints.between(900, 1300)]: {
    width: "350px",
    fontSize: "12px",
  },
  [theme.breakpoints.between(600, 900)]: {
    width: "400px",
    fontSize: "13px",
  },
  [theme.breakpoints.down(600)]: {
    width: "90px",
    fontSize: "12px",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    [theme.breakpoints.down(600)]: {
      fontSize: "13px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    [theme.breakpoints.down(600)]: {
      fontSize: "10px",
    },
  },
}));

export const StyledReviewDiv = styled("div")({
  marginTop: "1rem",
  backgroundColor: "rgba(43, 197, 94, 0.16)",
  padding: ".7rem",
  borderRadius: "6px",
  textAlign: "center",
});

export const StyledBadge = styled("div")({
  width: "fit-content",
  height: "fit-content",
  padding: " 0 .3rem",
  backgroundColor: "red",
  color: "#fff",
  borderRadius: "10px",
  fontSize: "10px",
});

export const StyledBadgeDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  height: "100%",
  paddingRight: "5px",
});

export const StyledReferenceDiv = styled("div")(({ theme }) => ({
  width: "auto",
  fontSize: "15px",
  color: theme.palette.mode === "dark" ? "gray" : "#5A5A5A",
  [theme.breakpoints.down(900)]: {
    width: "100%",
    overflow: "auto",
  },
}));

import {Visibility, VisibilityOff,} from "@mui/icons-material";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Snackbar,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AboutParaTypo, FilledTextField, LoginHeader,} from "../styledcomponents/beforeLoginWidgets";
import {StyledGreenButton,} from "../styledcomponents/styledDBwidgets";
import {myProfileData} from "../staticData/staticDBData";
import {useFormik} from "formik";
import * as yup from "yup";
import {changePassApi, ConfirmUserApi, forgotPwdApi, loginApi, pgNeetUser, pgNeetUserAuth,} from "../../http/allApis";
import {profileCheck} from "./profileCheck";
import moment from "moment";
import GoogleLoginButton from "../student/widgets/GoogleLoginButton";
import FBLoginButton from "../student/widgets/FBLoginButton";
import {Modal} from "antd";
import {StyledButton} from "../styledcomponents/styledQBExamWidgets";
import {getUserRolePrivileges} from "../../http/allAdminApi";

function Login({ handleToggle, reset }) {
  const navigate = useNavigate();
  const [forgotPwd, setForgotPwd] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [showLogin, setShowLogin] = useState(reset ? false : true);
  const [resetPwd, setResetPwd] = useState(reset ? true : false);
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetials, setLoginDetials] = React.useState();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  useEffect(() => {
    if (window.location?.search) {
      let decodedData = window.atob(window.location.search.split("?")[1]);
      let decodedMail = decodedData?.split(",")[0];
      let decodedDate = decodedData?.split(",")[1];
      setResetEmail(decodedMail);
      var dateToSubtract = moment.utc(decodedDate, "YYYY-MM-DD HH:mm:ss");
      if (dateToSubtract.isAfter()) {
        setResetPwd(true);
        setForgotPwd(false);
      } else {
        setForgotPwd(true);
        setResetPwd(false);
        setAlertOpen(true);
        setAlertstatus("warning");
        setMessage(
          "There was a problem validating your password reset request - The reset password link is invalid. Please try to reset your password again.",
        );
        recoverymail.values.regemail = decodedMail;
      }
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let inputRef = null;
  const handleKeyUp = (e, target) => {
    if (e.target && e.target.name === target) {
      if (e.keyCode === 13) {
        switch (target) {
          case "email":
            const passwordInput = document.getElementById("password");
            if (passwordInput) {
              passwordInput.focus();
            } else {
              console.error("Password input element not found.");
            }
            break;
          case "password":
            const loginButton = document.getElementById("login");
            if (loginButton) {
              loginButton.focus();
            } else {
              console.error("Login button element not found.");
            }
            break;
          default:
            break;
        }
      }
    } else {
      console.error("Target element not found or is null.");
    }
  };

  const recoverymail = useFormik({
    initialValues: {
      regemail: "",
    },
    validationSchema: yup.object({
      regemail: yup
        .string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Enter a valid Email Address ",
        )
        .trim("Empty Space not allowed")
        .strict()
        .required("Email is required"),
    }),
  });

  const cnfPassword = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      newPassword: yup
        .string()
        .strict()
        .min(8, "Minimum length 8 Characters")
        .trim("Empty Space not allowed")
        .required("Password required"),
      confirmPassword: yup
        .string()
        .strict()
        .min(8, "Minimum length 8 Characters")
        .trim("Empty Space not allowed")
        .required("Password required"),
    }),

    onSubmit: () => {},
  });

  const handleSocialLogin = (userVal) => {
    handleAfterLogin(userVal);
  };

  const handleAfterLogin = (userVal) => {
    if (userVal?.accessToken) {
      pgNeetUser(userVal)
        .then((res1) => {
          sessionStorage.setItem(
            "person",
            JSON.stringify(res1?.data?.result[0]),
          );
          if (userVal?.userRole === "Student") {
            if (profileCheck(res1?.data?.result[0])) {
              navigate("/user/dashboard");
              setStartLoader(false);
            } else {
              navigate("/user/profile");
            }
          } else {
            getUserRolePrivileges().then((res) => {
              if (res.data.status === "Success") {
                var userData = JSON.stringify(res?.data?.data);
                sessionStorage.setItem("credential", userData);
                navigate("/admin/dashboard", {
                  state: {
                    userData: userData,
                  },
                });
              }
            });
          }
        })
        .catch((err) => {});
    } else {
      pgNeetUserAuth(userVal?.id)
        .then((res1) => {
          sessionStorage.setItem("person", JSON.stringify(res1?.data?.data[0]));
          if (userVal?.userRole === "Student") {
            if (profileCheck(res1?.data?.data[0])) {
              navigate("/user/dashboard");
              setStartLoader(false);
            } else {
              navigate("/user/profile");
            }
          } else {
            navigate("/admin/dashboard");
          }
        })
        .catch((err) => {});
    }
  };

  const handleAlert = (msg, icon) => {
    setMessage(msg);
    setAlertstatus(icon);
    setAlertOpen(true);
  };

  // const handleEmailChange = (e) => {
  //   const { name, value } = e.target;
  //   const trimmedValue = value.replace(/\s/g, ''); // Remove all spaces

  //   formik.setFieldValue(name, trimmedValue);
  // };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // mobile: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Enter a valid Email Address ",
        )
        .strict()
        .trim()
        .required("Email is required"),
      password: yup
        .string()
        .strict()
        .trim("Empty Space not allowed")
        .required("Password required"),
    }),

    onSubmit: () => {
      sessionStorage.clear();
      // setStartLoader(true);
      var logindata = {
        password: formik.values.password,
        email: formik.values.email,
      };
      loginApi(logindata)
        .then((res) => {
          // setStartLoader(false);
          if (res.data.status === "Success") {
            sessionStorage.setItem("token", res?.data?.data?.accessToken);
            sessionStorage.setItem(
              "Tokentype",
              JSON.stringify(res?.data?.data),
            );
            handleAfterLogin(res.data.data);
          } else if (res.data.message) {
            setMessage(res.data.message);
            setAlertstatus("warning");
            setAlertOpen(true);
            return Promise.reject(res.data.message);
          } else if (
            res.data.error.description ===
            "You Are Already Login.You Need to Continue This Session."
          ) {
            setMessage(res.data.error.description);
            setAlertstatus("warning");
            setAlertOpen(true);
            setIsModalOpen(true);
            setLoginDetials(logindata);
            return Promise.reject(res.data.error.description);
          } else {
            setMessage(res.data.error.description);
            setAlertstatus("warning");
            setAlertOpen(true);
            return Promise.reject(res.data.error.description);
          }
        })
        .catch((err) => {})
        .finally(() => setStartLoader(false));
    },
  });

  const handleLoginIssue = (select) => {
    if (select === "Continue") {
      var logindata = {
        password: loginDetials.password,
        email: loginDetials.email,
      };
      var encoded = {
        eP: btoa(logindata.password),
        eE: btoa(logindata.email),
      };

      sessionStorage.setItem("ENC", JSON.stringify(encoded));
      ConfirmUserApi(logindata).then((res) => {
        if (res.data.status === "Success") {
          setIsModalOpen(false);
          loginApi(logindata)
            .then((res) => {
              if (res.data.status === "Success") {
                sessionStorage.setItem("token", res?.data?.data?.accessToken);
                sessionStorage.setItem(
                  "Tokentype",
                  JSON.stringify(res?.data?.data),
                );
                handleAfterLogin(res.data.data);
                if (res?.data?.data?.userRole !== "Student") {
                  getUserRolePrivileges().then((res) => {
                    if (res.data.status === "Success") {
                      var userData = JSON.stringify(res?.data?.data);
                      sessionStorage.setItem("credential", userData);
                    }
                  });
                }
              } else if (res.data.message) {
                setMessage(res.data.message);
                setAlertstatus("warning");
                setAlertOpen(true);
                return Promise.reject(res.data.message);
              } else {
                setMessage(res.data.error.description);
                console.log(res.data.error.description);
                setAlertstatus("warning");
                setAlertOpen(true);
                return Promise.reject(res.data.error.description);
              }
            })
            .catch((err) => {})
            .finally(() => setStartLoader(false));
        }
      });
    } else {
      navigate("/");
      sessionStorage.clear();
    }
  };

  const usermail = {
    mailId: recoverymail.values.regemail,
  };

  const handleMailsent = (e) => {
    e.preventDefault();
    forgotPwdApi(usermail)
      .then((res) => {
        if (res.data.status === "Success") {
          setMailSent(true);
          setShowLogin(false);
          setForgotPwd(false);
          setAlertOpen(false);
        } else {
          setMessage(res.data.message);
          setAlertstatus("warning");
          setOpen(true);
          return Promise.reject(res.data.message);
        }
      })
      .catch((err) => {});
  };

  const handleChangePassword = (e) => {
    if (cnfPassword.values.newPassword === cnfPassword.values.confirmPassword) {
      e.preventDefault();
      let payload = {
        email: resetEmail,
        newPassword: cnfPassword.values.newPassword,
        confirmPassword: cnfPassword.values.confirmPassword,
      };
      changePassApi(payload)
        .then((res) => {
          if (res.data.status === "Success") {
            setAlertOpen(true);
            setAlertstatus("success");
            setMessage(
              "Password changed successfully! Please login to continue",
            );
            setMailSent(false);
            setShowLogin(true);
            setForgotPwd(false);
            setResetPwd(false);
          } else {
            setMessage(res.data.message);
            setAlertstatus("warning");
            setOpen(true);
            return Promise.reject(res.data.message);
          }
        })
        .catch((err) => {});
    } else {
      alert(
        "Oops! It looks like the passwords you entered don't match. Please try again.",
      );
    }
  };

  const handleForgotPwd = () => {
    setForgotPwd(true);
    setShowLogin(false);
    setMailSent(false);
  };

  const handleCancel = (cond) => {
    if (cond === "Cancel") {
      setShowLogin(true);
      setForgotPwd(false);
      setAlertOpen(false);
    } else {
      setMailSent(false);
      setShowLogin(true);
    }
  };
  useEffect(() => {
    if (alertOpen) {
      setAlertOpen(false);
    }
  }, [formik.values.email, formik.values.password]);

  return (
    <div>
      {alertOpen ? (
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      ) : null}
      <Backdrop open={startLoader} />
      {showLogin ? (
        <form
          // data-testid="login-component"
          // autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <LoginHeader variant="h5" align="center" fontWeight={"bold"}>
              LOGIN
            </LoginHeader>
            <div style={{ height: "6rem" }}>
              <FilledTextField
                label="Email address"
                type={"email"}
                id="email"
                variant="filled"
                style={{ marginTop: "1rem" }}
                fullWidth
                ref={(input) => (inputRef = input)}
                onKeyUp={(e) => {
                  handleKeyUp(e, (inputRef = e.target.name));
                }}
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
              />
              <Typography
                variant="caption"
                color="error"
                style={{ marginLeft: ".5rem" }}
              >
                {formik.touched.email && formik.errors.email ? (
                  <Typography
                    variant="caption"
                    color="error"
                    style={{ marginLeft: ".5rem" }}
                  >
                    {formik.errors.email}
                  </Typography>
                ) : null}
              </Typography>
            </div>
            <div style={{ height: "6rem" }}>
              <FilledTextField
                id="name"
                variant="filled"
                style={{ marginTop: "1rem" }}
                fullWidth
                name="password"
                value={formik.values.password}
                label="Password"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        style={{ outline: "none" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {formik.touched.password && formik.errors.password ? (
                <Typography
                  variant="caption"
                  color="error"
                  style={{ marginLeft: ".5rem" }}
                >
                  {formik.errors.password}
                </Typography>
              ) : null}
            </div>

            <div
              style={{
                marginTop: "2rem",
              }}
            >
              <StyledGreenButton
                disabled={!(formik.isValid && formik.dirty) || startLoader}
                type="submit"
                style={{ width: "100%", outline: "none" }}
              >
                {startLoader ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  "Login "
                )}
              </StyledGreenButton>
            </div>
            <div
              style={{
                marginTop: ".5rem",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <AboutParaTypo
                style={{
                  color: "#31a188",
                  cursor: "pointer",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "end",
                }}
                variant="body2"
                onClick={handleForgotPwd}
              >
                Forgot your password?
              </AboutParaTypo>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {/* <AboutParaTypo
                variant="p"
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center"
                }}
              > */}
              <div
                style={{
                  content: '""',
                  display: "block",
                  width: "160px",
                  height: "1px",
                  background: "gray",
                  position: "absolute",
                  top: "50%",
                  left: 0,
                }}
              ></div>
              OR
              <div
                style={{
                  content: '""',
                  display: "block",
                  width: "160px",
                  height: "1px",
                  background: "gray",
                  position: "absolute",
                  top: "50%",
                  right: 0,
                }}
              ></div>
              {/* </AboutParaTypo> */}
            </div>

            {/* <div
              style={{
                width: "100%",
              }}
            > */}
            {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FBLoginButton
                  buttonFor={"login"}
                  callBackFunction={handleSocialLogin}
                  handleAlert={handleAlert}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <GoogleLoginButton
                    buttonFor={"login"}
                    callBackFunction={handleSocialLogin}
                    handleAlert={handleAlert}
                  />
                </div>
              </Grid>
            </Grid> */}
            {/* </div> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            > */}
            {/* <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
              <div style={{ width: "50%" }}>
              <FBLoginButton
                buttonFor={"login"}
                callBackFunction={handleSocialLogin}
                handleAlert={handleAlert}
              />
              </div> */}

            {/* </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}> */}
            {/* <div style={{ width: "50%", marginLeft: "1rem" }}> */}
            {/* <GoogleLoginButton
                buttonFor={"login"}
                callBackFunction={handleSocialLogin}
                handleAlert={handleAlert}
              /> */}
            {/* </div> */}
            {/* </Grid>
              
        </Grid> */}
            {/* </div> */}

            {/* <div
            style={{
              marginBottom: "1rem",
            }}
          > */}
            {/* <SocialButton variant="outlined" style={{ marginRight: "1rem" }}>
            <FacebookOutlined style={{ color: "#0a58ca" }} /> */}
            {/* Sign in with Facebook */}
            {/* </SocialButton> */}
            {/* </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} justifyContent="start"> */}
            {/* <SocialButton variant="outlined">
                  <img
                    src={gLogo}
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: ".5rem",
                    }}
                    alt="g"
                  />
                  Sign in with Google
                </SocialButton> */}

            {/* </Grid>
            </Grid> */}
            {/* </div> */}
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            > */}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <Grid container spacing={2}> */}
              {/* <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                // style={{ display: "flex", justifyContent: "end" }}
              > */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FBLoginButton
                  buttonFor={"login"}
                  callBackFunction={handleSocialLogin}
                  handleAlert={handleAlert}
                />
              </div>
              {/* </Grid> */}
              {/* <Grid item xs={12} sm={6} md={6} lg={6} justifyContent="start"> */}
              {/* <SocialButton variant="outlined">
                  <img
                    src={gLogo}
                    style={{
                      width: "18px",
                      height: "18px",
                      marginRight: ".5rem",
                    }}
                    alt="g"
                  />
                  Sign in with Google
                </SocialButton> */}
              <GoogleLoginButton
                buttonFor={"login"}
                callBackFunction={handleSocialLogin}
                handleAlert={handleAlert}
              />
              {/* </Grid> */}
              {/* </Grid> */}
              {/* <StyledFooterIconButton
                aria-label="Twitter"
                component="a"
                href="https://twitter.com/pgneet_net"
                target="_blank"
                rel="noopener"
                style={{
                  backgroundColor: "white",
                  outline: "none",
                  border: ".1px solid gray"
                }}
              >
                <img
                  src={Twi}
                  style={{ width: "20px", height: "20px" }}
                  alt="Twitter Icon"
                />
                {/* <FontAwesomeIcon size="xs" icon={faTwitterSquare} /> */}
              {/* </StyledFooterIconButton> */}
            </div>
            {/* <Divider style={{ marginTop: "1rem" }} /> */}
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AboutParaTypo variant="body2">
                Need an account?
                <span
                  onClick={() => handleToggle("signup")}
                  // target="_blank"
                  style={{
                    color: "#31a188",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {" "}
                  SIGN UP
                </span>
              </AboutParaTypo>
            </div>
          </div>
        </form>
      ) : null}
      {forgotPwd ? (
        <div>
          <LoginHeader variant="h5" align="center" fontWeight={"bold"}>
            Reset your password
          </LoginHeader>
          <div style={{ height: "6rem" }}>
            <FilledTextField
              type={"email"}
              variant="filled"
              style={{ marginTop: "1rem" }}
              name="regemail"
              id="regemail"
              margin="normal"
              fullWidth
              placeholder="example@mail.com"
              label={"Enter Email Address"}
              onBlur={recoverymail.handleBlur}
              onChange={recoverymail.handleChange}
              value={recoverymail.values.regemail}
              error={
                recoverymail.touched.regemail && recoverymail.errors.regemail
              }
              autoComplete="off"
            />
            {recoverymail.touched.regemail && recoverymail.errors.regemail ? (
              <Typography
                variant="caption"
                color="error"
                style={{ marginLeft: "1rem" }}
              >
                {recoverymail.errors.regemail}
              </Typography>
            ) : null}
          </div>
          <AboutParaTypo variant="caption">
            Enter your email address and we will send you a link to reset your
            password.
          </AboutParaTypo>
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <Button
              size="small"
              style={{
                border: "1px solid gray",
                color: "gray",
                marginRight: ".5rem",
              }}
              onClick={(e) => handleCancel("Cancel")}
            >
              Cancel
            </Button>
            <StyledGreenButton
              style={{
                border: "1px solid #31a188",
              }}
              onClick={handleMailsent}
              // disabled={!(recoverymail.isValid && recoverymail.dirty)}
            >
              Reset Password
            </StyledGreenButton>
          </div>
        </div>
      ) : null}
      {mailSent ? (
        <div
          style={{
            padding: "1rem",
          }}
        >
          <AboutParaTypo>
            {/* If an account matching your email exists, then an email was just
            sent that contains a link that you can use to reset your password.
            This link will expire in 1 hour. */}
            If an account matching your email exists, then an email will be sent
            to that address containing a link that you can use to reset your
            password.
          </AboutParaTypo>
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <AboutParaTypo>
              {/* If you don't receive an email please check your spam folder or */}
              This link will expire in 1 hour. If have not received an email
              then kindly check your spam folder or try again.{" "}
              <span
                onClick={handleForgotPwd}
                style={{
                  color: "#31a188",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
              >
                try again.
              </span>
            </AboutParaTypo>
          </div>
          <div
            style={{
              marginTop: "1rem",
            }}
          >
            <StyledGreenButton
              style={{
                cursor: "pointer",
                display: "flex",
                justifyItems: "end",
              }}
              onClick={(e) => handleCancel("Btl")}
            >
              Back to login
            </StyledGreenButton>
          </div>
        </div>
      ) : null}
      {resetPwd ? (
        <div>
          <LoginHeader variant="h5" align="center" fontWeight={"bold"}>
            Change your password
          </LoginHeader>
          <form autoComplete="off" onSubmit={cnfPassword.handleSubmit}>
            <div style={{ height: "6rem" }}>
              <FilledTextField
                id="newPassword"
                variant="filled"
                style={{ marginTop: "1rem" }}
                fullWidth
                name="newPassword"
                value={cnfPassword.values.newPassword}
                label="New Password"
                error={
                  cnfPassword.touched.newPassword &&
                  cnfPassword.errors.newPassword
                }
                type={"password"}
                autoComplete="off"
                onBlur={cnfPassword.handleBlur}
                onChange={cnfPassword.handleChange}
              />
              {cnfPassword.touched.newPassword &&
              cnfPassword.errors.newPassword ? (
                <Typography
                  variant="caption"
                  color="error"
                  style={{ marginLeft: ".5rem" }}
                >
                  {cnfPassword.errors.newPassword}
                </Typography>
              ) : null}
            </div>
            <div style={{ height: "6rem" }}>
              <FilledTextField
                id="cnfPassword"
                variant="filled"
                style={{ marginTop: "1rem" }}
                fullWidth
                name="confirmPassword"
                value={cnfPassword.values.confirmPassword}
                label="Confirm Password"
                error={
                  cnfPassword.touched.confirmPassword &&
                  cnfPassword.errors.confirmPassword
                }
                type={"password"}
                autoComplete="off"
                onBlur={cnfPassword.handleBlur}
                onChange={cnfPassword.handleChange}
              />
              {cnfPassword.touched.confirmPassword &&
              cnfPassword.errors.confirmPassword ? (
                <Typography
                  variant="caption"
                  color="error"
                  style={{ marginLeft: ".5rem" }}
                >
                  {cnfPassword.errors.confirmPassword}
                </Typography>
              ) : null}
            </div>
            <div
              style={{
                marginTop: "1rem",
              }}
            >
              <StyledGreenButton
                style={{
                  border: "1px solid #31a188",
                }}
                onClick={handleChangePassword}
                disabled={!(cnfPassword.isValid && cnfPassword.dirty)}
              >
                Change Password
              </StyledGreenButton>
            </div>
          </form>
        </div>
      ) : null}

      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            Login Alert{" "}
          </Typography>
        }
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Typography style={{ marginBottom: "1rem" }}>
          This message means that you've already signed into your account or
          session on this platform.
        </Typography>
        <Typography>
          To proceed, you don't need to log in again. Instead, you can pick up
          where you left off in your current session without re-entering your
          login credentials.
        </Typography>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledButton
            onClick={() => handleLoginIssue("Cancel")}
            style={{ outline: "none" }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => handleLoginIssue("Continue")}
            style={{
              outline: "none",
              marginLeft: "1rem",
              backgroundColor: "#31a188",
            }}
          >
            Continue
          </StyledButton>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;

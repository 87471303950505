import {AppBar, IconButton, Menu, MenuItem, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    StyledNBAdminButton,
    StyledNBAdminToolBar,
    StyledNBDiv,
    StyledNBResDiv,
} from "../../styledcomponents/styledNavBar";
import {ArrowDropDown, Close, PowerSettingsNew} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {useNavigate} from "react-router-dom";
import {userApiLogout} from "../../../http/allApis";
import "./Nav.css";
import {Modal} from "antd";
import {StyledButton} from "../../styledcomponents/styledQBExamWidgets";

function AdminNavBar() {
  const theme = useTheme();
  const isSmMd = useMediaQuery(theme.breakpoints.down(1200));
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [anchorE4, setAnchorE4] = useState(null);
  const [anchorE5, setAnchorE5] = useState(null);
  const navigate = useNavigate();
  const qbOpen = Boolean(anchorEl);
  const hyOpen = Boolean(anchorE2);
  const mockOpen = Boolean(anchorE3);
  const settingOpen = Boolean(anchorE4);
  const blogsOpen = Boolean(anchorE5);
  const [approve, setApprove] = useState(false);
  const [parsedUserData, setParsedUserData] = useState(null);

  useEffect(() => {
    var storedUserData = sessionStorage.getItem("credential");
    const userData = JSON.parse(storedUserData);
    console.log("User Roles", userData?.role?.trim().toLowerCase());
    console.log(userData);
    setParsedUserData(userData);
  }, []);
  const hanldeLogout = () => {
    const credential = JSON.parse(sessionStorage.getItem("Tokentype"));
    userApiLogout(credential)
      .then((res) => {
        if (res.data.status === "Success") {
          navigate("/");
          sessionStorage.clear();
        } else {
          alert(() => {
            "Something went wrong please try again try";
          });
        }
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          {
            navigate("/");
            sessionStorage.clear();
          }
        }
      });
  };
  const handleAction = (val) => {
    sessionStorage.setItem("paginationPage", 0);
    sessionStorage.setItem("paginationPageSize", 50);
    if (val === "Dashboard") {
      navigate("/admin/dashboard");
    } else if (val === "QbQuestions") {
      navigate("/admin/question");
    } else if (val === "QbExplanations") {
      navigate("/admin/textbook");
    } else if (val === "QbImportCSV") {
      navigate("/admin/importcsv");
    } else if (val === "HYTopics") {
      navigate("/admin/highyield/topic");
    } else if (val === "HYcategory") {
      navigate("/admin/highyield/category");
    } else if (val === "MockQuestions") {
      navigate("/admin/mock/question");
    } else if (val === "MockExplanations") {
      navigate("/admin/mock/textbook");
    } else if (val === "MockImportCSV") {
      navigate("/admin/mock/importcsv");
    } else if (val === "FAQs") {
      navigate("/admin/faq");
    } else if (val === "Invoices") {
      navigate("/admin/invoice");
    } else if (val === "Members") {
      navigate("/admin/members");
    } else if (val === "Roles") {
      navigate("/admin/roles");
    } else if (val === "Subject") {
      navigate("/admin/main/category");
    } else if (val === "Category") {
      navigate("/admin/category");
    } else if (val === "SubCategory") {
      navigate("/admin/sub-category");
    } else if (val === "State") {
      navigate("/admin/state");
    } else if (val === "Exam") {
      navigate("/admin/exam");
    } else if (val === "Subscription") {
      navigate("/admin/subscription");
    } else if (val === "Change-password") {
      navigate("/admin/change-password");
    } else if (val === "QuestionBanksetlimit") {
      navigate("/admin/qbsetlimit");
    } else if (val === "MockSetLimit") {
      navigate("/admin/mockquestionsetlimit");
    } else if (val === "HYcategory1") {
      navigate("/admin/highyield/category");
    } else if (val === "AddBlogHeading") {
      navigate("/admin/blogsheading");
    } else if (val === "CreateBlogsdescription") {
      navigate("/admin/createblogs");
    } else if (val === "SubscriptionOffers") {
      navigate("/admin/addoffer");
    } else if (val === "Instruction") {
      navigate("/admin/instruction");
    } else if (val === "Logout") {
      setApprove(true);
    }
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
    setAnchorE4(null);
    setAnchorE5(null);
  };
  return (
    <div>
      <AppBar position="sticky">
        <StyledNBAdminToolBar>
          <Typography
            variant="h5"
            sx={{ cursor: "pointer" }}
            onClick={(e) => handleAction("Dashboard")}
          >
            PGNEET<span style={{ color: "#3279b6" }}>.NET</span>
          </Typography>
          <StyledNBResDiv>
            {window.location.pathname === "/admin/menu" ? (
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  width: "20px",
                  height: "20px",
                  padding: 0,
                  outline: "none",
                }}
              >
                <Close />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => navigate("/admin/menu")}
                sx={{
                  width: "20px",
                  height: "20px",
                  padding: 0,
                  outline: "none",
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </StyledNBResDiv>
          <StyledNBDiv>
            {parsedUserData && parsedUserData?.role === "Super Admin" ? (
              <StyledNBAdminButton onClick={(e) => handleAction("Dashboard")}>
                Dashboard
              </StyledNBAdminButton>
            ) : null}
            {parsedUserData?.questionBank ? (
              <StyledNBAdminButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                id="demo-positioned-button"
                aria-controls={qbOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={qbOpen ? "true" : undefined}
              >
                Question Bank <ArrowDropDown style={{ color: "#bcc2ca" }} />
              </StyledNBAdminButton>
            ) : null}
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              open={qbOpen}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <MenuItem onClick={() => handleAction("QbQuestions")}>
                Questions
              </MenuItem>
              <MenuItem onClick={() => handleAction("QbExplanations")}>
                Explanations
              </MenuItem>
              <MenuItem onClick={() => handleAction("QbImportCSV")}>
                Import CSV
              </MenuItem>
            </Menu>
            {parsedUserData?.highYield ? (
              <StyledNBAdminButton
                onClick={(e) => setAnchorE2(e.currentTarget)}
                id="demo-positioned-button"
                aria-controls={hyOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={hyOpen ? "true" : undefined}
              >
                High Yield <ArrowDropDown style={{ color: "#bcc2ca" }} />
              </StyledNBAdminButton>
            ) : null}
            <Menu
              id="basic-menu"
              open={hyOpen}
              onClose={() => setAnchorE2(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorE2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleAction("HYTopics")}>
                Topics
              </MenuItem>
            </Menu>
            {parsedUserData?.mock ? (
              <StyledNBAdminButton
                onClick={(e) => setAnchorE3(e.currentTarget)}
                id="demo-positioned-button"
                aria-controls={mockOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={mockOpen ? "true" : undefined}
              >
                Mock <ArrowDropDown style={{ color: "#bcc2ca" }} />
              </StyledNBAdminButton>
            ) : null}
            <Menu
              id="basic-menu"
              open={mockOpen}
              onClose={() => setAnchorE3(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorE3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleAction("MockQuestions")}>
                Questions
              </MenuItem>
              <MenuItem onClick={() => handleAction("MockExplanations")}>
                Explanations
              </MenuItem>
              <MenuItem onClick={() => handleAction("MockImportCSV")}>
                Import CSV
              </MenuItem>
              {/* <MenuItem onClick={() => handleAction("MockSetLimit")}>
              Mock Question set limit
            </MenuItem> */}
            </Menu>
            {parsedUserData?.faqs ? (
              <StyledNBAdminButton onClick={() => handleAction("FAQs")}>
                FAQs
              </StyledNBAdminButton>
            ) : null}
            <StyledNBAdminButton
              onClick={(e) => setAnchorE5(e.currentTarget)}
              id="demo-positioned-button"
              aria-controls={blogsOpen ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={blogsOpen ? "true" : undefined}
              // style={{ marginRight: "0" }}
              // onClick={() => handleAction("Blogs")}
            >
              Blogs
              <ArrowDropDown style={{ color: "#bcc2ca" }} />
            </StyledNBAdminButton>
            <Menu
              id="basic-menu"
              open={blogsOpen}
              onClose={() => setAnchorE5(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorE5}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleAction("AddBlogHeading")}>
                Add Blog Heading
              </MenuItem>
              <MenuItem onClick={() => handleAction("CreateBlogsdescription")}>
                Create Blogs description
              </MenuItem>
              {/* <MenuItem onClick={() => handleAction("MockSetLimit")}>
              Mock Question set limit
            </MenuItem> */}
            </Menu>
            {parsedUserData?.role === "Super Admin" ? (
              <StyledNBAdminButton
                style={{ marginRight: "0" }}
                onClick={() => handleAction("Invoices")}
              >
                Invoices
              </StyledNBAdminButton>
            ) : null}
            {parsedUserData?.members ? (
              <StyledNBAdminButton
                style={{ marginRight: "0" }}
                onClick={() => handleAction("Members")}
              >
                Members
              </StyledNBAdminButton>
            ) : null}
            {parsedUserData?.role === "Super Admin" ? (
              <StyledNBAdminButton
                style={{ marginRight: "0" }}
                onClick={() => handleAction("Roles")}
              >
                Roles
              </StyledNBAdminButton>
            ) : null}
            <StyledNBAdminButton
              onClick={(e) => setAnchorE4(e.currentTarget)}
              id="demo-positioned-button"
              aria-controls={settingOpen ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={settingOpen ? "true" : undefined}
            >
              Settings <ArrowDropDown style={{ color: "#bcc2ca" }} />
            </StyledNBAdminButton>
            <Menu
              id="basic-menu"
              open={settingOpen}
              onClose={() => setAnchorE4(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorEl={anchorE4}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              {parsedUserData?.subject ? (
                <MenuItem onClick={() => handleAction("Subject")}>
                  Subject
                </MenuItem>
              ) : null}
              {parsedUserData?.category ? (
                <MenuItem onClick={() => handleAction("Category")}>
                  Category
                </MenuItem>
              ) : null}
              {parsedUserData?.subCategory ? (
                <MenuItem onClick={() => handleAction("SubCategory")}>
                  Sub Category
                </MenuItem>
              ) : null}
              {parsedUserData?.hyCategory ? (
                <MenuItem onClick={() => handleAction("HYcategory")}>
                  HY Category
                </MenuItem>
              ) : null}
              {parsedUserData?.exam ? (
                <MenuItem onClick={() => handleAction("Exam")}>Exam</MenuItem>
              ) : null}
              {parsedUserData?.subscription ? (
                <MenuItem onClick={() => handleAction("Subscription")}>
                  Subscription
                </MenuItem>
              ) : null}
              {parsedUserData?.boardCastMessage ? (
                <MenuItem onClick={() => handleAction("Instruction")}>
                  Announcement Banner
                </MenuItem>
              ) : null}
              {/* <MenuItem onClick={() => handleAction("SubscriptionOffers")}>
                Subscription Add Offers
              </MenuItem> */}
              {parsedUserData?.role === "Super Admin" ? (
                <MenuItem onClick={() => handleAction("Change-password")}>
                  Change password
                </MenuItem>
              ) : null}
            </Menu>
            <IconButton
              style={{ marginRight: "0", outline: "none" }}
              onClick={() => handleAction("Logout")}
            >
              <PowerSettingsNew style={{ color: "#bcc2ca", outline: "none" }} />
            </IconButton>
          </StyledNBDiv>
        </StyledNBAdminToolBar>
      </AppBar>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            Logout Alert{" "}
          </Typography>
        }
        width={370}
        open={approve}
        onOk={() => setApprove(false)}
        onCancel={() => setApprove(false)}
        footer={null}
      >
        <Typography style={{ marginBottom: "1rem" }}>
          Are you sure you wish to logout?
        </Typography>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledButton
            onClick={() => setApprove(false)}
            style={{ outline: "none" }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => hanldeLogout()}
            style={{
              outline: "none",
              marginLeft: "1rem",
              backgroundColor: "#31a188",
            }}
          >
            Logout
          </StyledButton>
        </div>
      </Modal>
    </div>
  );
}

export default AdminNavBar;

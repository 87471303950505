import {Box, Grid, List, ListItem, Typography, useTheme,} from "@mui/material";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {mockTestPlan} from "../Common/beforeLoginPageContents";
import {
    AboutBannerHead,
    AboutParaTypo,
    BlueContainerSection,
    ContentDiv,
} from "../styledcomponents/beforeLoginWidgets";
import {StyledGreenButton} from "../styledcomponents/styledDBwidgets";

function MockTestHome() {
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          Mock Test
        </AboutBannerHead>
      </BlueContainerSection>
      <ContentDiv>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Are you fully prepared for your final exam? Have you covered all
                syllabus areas? Are you ready for exam conditions? Our Mock Test
                provides the answers you need. It accurately mirrors the NEET PG
                and other Medical PG exams, designed based on the official exam
                blueprint, not randomly selected questions. Identify your
                weaknesses and strengths with our 200 MCQs, totalling 800 marks,
                in a 3-hour simulation.
              </AboutParaTypo>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                We have ten separate mock test practise papers for purchase
                consisting of freshly created and reconstructed questions from
                the most recent exams.
              </AboutParaTypo>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Note that regardless of the number of mocks subscribed,
                candidates have three days from the time of subscription to
                finish the mock test. Candidates may take the entire mock exam
                as many times as they like during this time, or they can save
                their progress and finish them later. Existing Mock test
                subscription(s) will expire after three days. The candidate will
                then need to resubscribe for the mock test(s). Therefore,
                candidates are advised to plan Mock test subscription carefully.
              </AboutParaTypo>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Each of the Ten Mock test subscription we offer is priced at ₹
                500.
                {/* Existing Mock test subscription(s) will expire after three
                days. The candidate will then need to resubscribe for the mock
                test(s). Therefore, candidates are advised to plan Mock test
                subscription carefully. */}
              </AboutParaTypo>
            </div>
            {/* <div>
              <List>
                {categoryList.map((item) => (
                  <ListItem sx={{ padding: "0 0 0 .5rem" }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <DoubleArrow
                        fontSize="small"
                        style={{ color: "#f38424" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <AboutParaTypo variant="p">{item}</AboutParaTypo>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div> */}
            {/* <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Mock Test package subscription will give you access to 10
                individual practice papers, all made of new and reconstructed
                questions from the most recent exams.
              </AboutParaTypo>
            </div> */}
            {/* <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                <b>Please note:</b> Candidates have 72 hours to complete the
                Mock Test from the time of subscription regardless of the number
                of Mocks subscribed. After 72 hours, existing Mock test
                subscription(s) will expire. Thereafter candidate will have to
                subscribe for Mock Test(s) again.
              </AboutParaTypo>
            </div> */}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Box
              style={{
                border: "1.5px solid #EBECF0",
                borderRadius: "6px",
                marginTop: ".5rem",
              }}
            >
              <div
                style={{
                  padding: ".5rem",
                  backgroundColor:
                    theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7",
                }}
              >
                <Typography variant="body2" fontWeight={600} marginLeft={1}>
                  Mock Test Subscription options:
                </Typography>
              </div>
              <div
                style={{
                  padding: ".3rem",
                }}
              >
                <div
                  style={{
                    border: "1px solid #EBECF0",
                    borderRadius: "4px",
                    padding: ".5rem",
                  }}
                >
                  <List>
                    {mockTestPlan.map((item) => (
                      <ListItem sx={{ padding: ".5rem 0 .5rem 0" }}>
                        <AboutParaTypo variant="body2">{item}</AboutParaTypo>
                      </ListItem>
                    ))}
                  </List>
                  <StyledGreenButton
                    style={{ fontWeight: "bold" }}
                    onClick={() => navigate("/login", { state: "signup" })}
                  >
                    Subscribe to Mock Test
                  </StyledGreenButton>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </ContentDiv>
    </div>
  );
}

export default MockTestHome;

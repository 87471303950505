import {Box, Divider, IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {AboutParaTypo, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import useStyles from "../../styles/FAQPageStyles";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment";
import {PictureAsPdf} from "@mui/icons-material";
import {NormalDiv, ResDiv} from "../../styledcomponents/styledDBwidgets";
import constantValues from "../../Common/ConstantValues";
import {getInvoicesApi} from "../../../http/allApis";
import ModelFile from "../../landing/ModelFile";

function Invoices() {
  const classes = useStyles();
  const [invoiceRows, setInvoiceRows] = useState([]);
  const person = JSON.parse(sessionStorage.getItem("person"));

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    getInvoicesApi()
      .then(function (response) {
        let mydata = response.data.result.map((item) => ({
          id: item.id,
          invoicedate: moment(item.invoicedate.date).format("DD-MM-YYYY"),
          amount: item.amount,
          status: item.status ? "Paid" : "UnPaid",
        }));
        setInvoiceRows(mydata);
      })
      .catch((response) => {
        setModelWin(true);
        setCatchError(response?.response?.data?.message);
      });
  };

  const columns = [
    { field: "id", headerName: "Invoice Number", flex: 2 },
    {
      field: "invoicedate",
      headerName: "Invoice Date",
      flex: 2,
      hideSortIcons: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 2,
      hideSortIcons: true,
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      hideSortIcons: true,
      align: "left",
    },
    {
      field: "value",
      headerName: "Action",
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        let dataUrl = window.btoa(params.row.id + "userId" + person?.id);
        let pdfurl =
          constantValues.PGNEET_PROD_URL + "/auth/invoicepdf?id=" + dataUrl;
        return (
          <a
            href={pdfurl}
            target="_blank"
            aria-label="pdfinvoice"
            rel="noreferrer"
          >
            <IconButton
              style={{
                outline: "none",
              }}
              aria-label="print PDF"
              color="primary"
            >
              <PictureAsPdf />
            </IconButton>
          </a>
        );
      },
    },
  ];

  return (
    <div>
      <Box className={classes.faqPageMainBox}>
        <div className={classes.boxHeader}>
          <TransparentHead variant="h5" fontWeight={"bold"}>
            Invoices
          </TransparentHead>
        </div>
        <div
          style={{
            padding: "1rem",
          }}
        >
          <NormalDiv>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={invoiceRows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                //   pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          </NormalDiv>
          <ResDiv>
            {invoiceRows.map((item) => (
              <Box
                className={classes.faqPageMainBox}
                style={{ marginTop: ".5rem", backgroundColor: "rgba(43, 197, 94, 0.16)" }}
              >
                <div style={{ padding: ".5rem" }}>
                  <AboutParaTypo variant="body2">
                    <b>Invoice Number : </b>
                    {item.id}
                  </AboutParaTypo>
                </div>
                <Divider />
                <div style={{ padding: ".5rem" }}>
                  <AboutParaTypo variant="body2">
                    <b>Invoice Date : </b>
                    {item.invoicedate}
                  </AboutParaTypo>
                </div>
                <Divider />
                <div style={{ padding: ".5rem" }}>
                  <AboutParaTypo variant="body2">
                    <b>Amount : </b>
                    {item.amount}
                  </AboutParaTypo>
                </div>
                <Divider />
                <div style={{ padding: ".5rem" }}>
                  <AboutParaTypo variant="body2">
                    <b>Status : </b>
                    {item.status}
                  </AboutParaTypo>
                </div>
                <Divider />
                <div style={{ padding: ".5rem" }}>
                  <AboutParaTypo variant="body2">
                    <b>Action : </b>{" "}
                    <IconButton
                      style={{
                        outline: "none",
                      }}
                      aria-label="print PDF"
                      color="primary"
                    >
                      <PictureAsPdf />
                    </IconButton>
                  </AboutParaTypo>
                </div>
                <Divider />
              </Box>
            ))}
          </ResDiv>
        </div>
      </Box>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default Invoices;

import React, {useEffect, useState} from "react";
import {Grid, TextField, Typography} from "@mui/material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {pgNeetUser, updateProfileApi} from "../../../http/allApis";
import {useNavigate} from "react-router-dom";

function ProfileDetails({ edit, snackbarcall }) {
  const [updatedProf, setUpdatedProf] = useState();
  let profDtls = sessionStorage.getItem("person");
  const navigator = useNavigate();
  const person =
    profDtls != "undefined"
      ? JSON.parse(sessionStorage.getItem("person"))
      : {
          fullName: "",
          mobileNumber: "",
          address1: "",
          address2: "",
          city: "",
          postalCode: "",
          state1: "",
          email: "",
          country: "",
          signupType: "",
        };
  const myProfileDetails =
    profDtls != "undefined"
      ? JSON.parse(profDtls)
      : {
          fullName: "",
          mobileNumber: "",
          address1: "",
          address2: "",
          city: "",
          postalCode: "",
          state1: "",
          email: "",
          country: "",
        };
  // const {
  //   loading,
  //   resData: allStates,
  //   modelWin,
  //   catchError
  // } = useFetch("/getallstates");
  const [profileDetails, setProfileDetails] = useState({
    fullName: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    state1: "",
    email: "",
    country: "",
  });

  const value = sessionStorage.getItem("Tokentype");
  const tokenType = JSON.parse(value);
  const handleUpdatedProf = () => {
    pgNeetUser(tokenType)
      .then((res1) => {
        sessionStorage.setItem("person", JSON.stringify(res1?.data?.result[0]));
        setUpdatedProf(res1?.data?.result[0]);
      })
      .catch((err) => {});
  };

  const handleSubmit = () => {
    console.log(profileDetails?.email, "profileDetails?.email");
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var string = /^[a-z\s]+$/i;
    if (!string.test(profileDetails?.city)) {
      snackbarcall("City Field Allow Alphabet only!", "error");
    }
    // else if (person?.signupType == "Facebook") {
    //   if (!emailPattern.test(profileDetails?.email)) {
    //     snackbarcall("Please Enter Vaild Mail Id", "error");
    //   }
    // }
    else {
      let payload = {
        fullName: profileDetails?.fullName,
        mobile: profileDetails?.mobileNumber,
        address1: profileDetails?.address1,
        address2: profileDetails?.address2,
        city: profileDetails?.city,
        postalCode: profileDetails?.postalCode,
        state: profileDetails?.state1,
        email: profileDetails?.email,
        country: profileDetails?.country,
      };
      updateProfileApi(payload, tokenType)
        .then((res) => {
          if (res.data.result === true) {
            snackbarcall("Profile updated successfully!", "success");
            handleUpdatedProf();
            navigator("/user/dashboard");
          } else {
            snackbarcall("Error!", "warning");
          }
        })
        .catch((e) => {
          snackbarcall("Error!", "warning");
          console.log(e);
        });
    }
  };

  const checkData = {
    fullName: myProfileDetails?.fullName || "",
    mobileNumber: myProfileDetails?.mobileNumber || "",
    address1: myProfileDetails?.address1 || "",
    address2: myProfileDetails?.address2 || "",
    city: myProfileDetails?.city || "",
    postalCode: myProfileDetails?.postalCode || "",
    state1: myProfileDetails?.state || "",
    email: myProfileDetails?.email || "",
    country: myProfileDetails?.country || "",
  };

  const handleFirstData = () => {
    let profData = { ...profileDetails };
    profData.fullName = myProfileDetails?.fullName || "";
    profData.mobileNumber = myProfileDetails?.mobileNumber || "";
    profData.address1 = myProfileDetails?.address1 || "";
    profData.address2 = myProfileDetails?.address2 || "";
    profData.city = myProfileDetails?.city || "";
    profData.postalCode = myProfileDetails?.postalCode || "";
    profData.state1 = myProfileDetails?.state || "";
    profData.email = myProfileDetails?.email || "";
    profData.country = myProfileDetails?.country || "";

    setProfileDetails(profData);
  };

  useEffect(() => {
    handleFirstData();
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  // const handlePin = (e) => {
  //   // e.preventDefault();
  //   let profData = { ...profileDetails };
  //   profData.postalCode = e;
  //   console.log(e, "Val");
  //   if (profData.postalCode.length === 6) {
  //     pinapicall(e);

  //     // setProfileDetails((prevProfileDetails) => ({
  //     //   ...prevProfileDetails,
  //     //   postalCode: value
  //     // })
  //   } else {
  //     snackbarcall("Enter pincode 6 digit number only allowed!", "error");
  //   }
  //   // );
  //   // }

  //   console.log(profileDetails, "PD");
  // };
  // const pinapicall = (pin) => [
  //   fetch(`https://api.postalpincode.in/pincode/${pin}`)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         return response.json();
  //       } else {
  //         throw Error(response.statusText);
  //       }
  //     })
  //     .then((res) => {
  //       if (res[0].Status == "Success") {
  //         if (
  //           res[0].PostOffice[0].State === "NA" ||
  //           res[0].PostOffice[0].Name === "NA"
  //         ) {
  //           setProfileDetails({
  //             ...profileDetails,
  //             state1: " ",
  //             city: " "
  //           });
  //         } else {
  //           setProfileDetails((val) => {
  //             return {
  //               ...val,
  //               state1: res[0].PostOffice[0].State,
  //               city: res[0].PostOffice[0].Name
  //             };
  //           });
  //         }
  //       } else {
  //         throw Error(res);
  //       }
  //     })
  //     .catch((err) => {})
  // ];
  const handleChange = (e, val) => {
    let profData = { ...profileDetails };
    if (val === "Name") {
      profData.fullName = e.target.value;
    } else if (val === "Mobile") {
      profData.mobileNumber = e.target.value;
    } else if (val === "Address1") {
      profData.address1 = e.target.value;
    } else if (val === "Address2") {
      profData.address2 = e.target.value;
    } else if (val === "City") {
      profData.city = e.target.value;
    } else if (val === "PCode") {
      profData.postalCode = e.target.value;
    } else if (val === "State") {
      profData.state1 = e.target.value;
    } else if (val === "Email") {
      profData.email = e.target.value;
    } else if (val === "Country") {
      profData.country = e.target.value;
    }
    setProfileDetails(profData);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <TextField
              label={"Name"}
              fullWidth
              disabled={edit}
              value={profileDetails.fullName}
              onChange={(e) => handleChange(e, "Name")}
            />
          ) : (
            <Typography>
              <b>Name :</b>{" "}
              <span style={{ wordBreak: "break-all" }}>
                {profileDetails.fullName}
              </span>
            </Typography>
          )}
        </Grid>
        {/* {person?.signupType != "Facebook" ? null : (
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              label={"Email"}
              fullWidth
              disabled={edit}
              value={profileDetails.email}
              onChange={(e) => handleChange(e, "Email")}
              onKeyDown={handleKeyDown}
            />
            <Typography>
              <b>Email :</b> {profileDetails.email}
            </Typography>
          </Grid>
        )} */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <>
              <TextField
                type="number"
                label={"Mobile"}
                required
                value={profileDetails.mobileNumber}
                disabled={edit}
                fullWidth
                onChange={(e) => handleChange(e, "Mobile")}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {(profileDetails.mobileNumber.length != 0 &&
                  profileDetails.mobileNumber.length < 10) ||
                (profileDetails.mobileNumber.length != 0 &&
                  profileDetails.mobileNumber.length > 10)
                  ? "Please enter a valid  mobile-no"
                  : null}
              </span>
            </>
          ) : (
            <Typography>
              <b>Mobile :</b> {profileDetails.mobileNumber}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <TextField
              label={"Address1"}
              value={profileDetails.address1}
              disabled={edit}
              fullWidth
              onChange={(e) => handleChange(e, "Address1")}
            />
          ) : (
            <Typography>
              <b>Address1 :</b>{" "}
              <span style={{ wordBreak: "break-all" }}>
                {profileDetails.address1}
              </span>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <TextField
              label={"Address2"}
              value={profileDetails.address2}
              disabled={edit}
              fullWidth
              onChange={(e) => handleChange(e, "Address2")}
            />
          ) : (
            <Typography>
              <b>Address2 :</b>{" "}
              <span style={{ wordBreak: "break-all" }}>
                {profileDetails.address2}
              </span>
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <>
              <TextField
                // type="text"
                label={"PIN Code"}
                value={profileDetails.postalCode}
                disabled={edit}
                fullWidth
                onChange={(e) => handleChange(e, "PCode")}
                // inputProps={{
                //   maxLength: 6
                // }}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                }
              />
              {/* <span style={{ color: "red", fontSize: "13px" }}>
                {(profileDetails?.postalCode?.length != 0 &&
                  profileDetails?.postalCode?.length < 6) ||
                (profileDetails?.postalCode?.length != 0 &&
                  profileDetails?.postalCode?.length > 6)
                  ? "Please enter a valid 6-digit PIN code"
                  : null}
              </span> */}
            </>
          ) : (
            <Typography>
              <b>PIN Code :</b> {profileDetails.postalCode}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <TextField
              label={"City"}
              value={profileDetails.city}
              disabled={edit}
              fullWidth
              onChange={(e) => handleChange(e, "City")}
            />
          ) : (
            <Typography>
              <b>City :</b>{" "}
              {profileDetails.city.length > 30
                ? profileDetails.city.substring(0, 30) + "..."
                : profileDetails.city}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <TextField
              id="outlined-select-currency"
              // select
              label="State"
              disabled={edit}
              fullWidth
              onChange={(e) => handleChange(e, "State")}
              value={profileDetails.state1}
            >
              {/* {allStates?.result?.map((item) => (
                <MenuItem value={item.state} key={item.stateCode} id={item.id}>
                  {item.state}
                </MenuItem>
              ))} */}
            </TextField>
          ) : (
            <Typography>
              <b>State :</b> {profileDetails.state1}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          {!edit ? (
            <TextField
              label={"Country"}
              fullWidth
              disabled={edit}
              value={profileDetails.country}
              onChange={(e) => handleChange(e, "Country")}
            />
          ) : (
            <Typography>
              <b>Country :</b>{" "}
              <span style={{ wordBreak: "break-all" }}>
                {profileDetails.country}
              </span>
            </Typography>
          )}
        </Grid>
      </Grid>
      {!edit ? (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            marginTop: "1rem",
          }}
        >
          <StyledGreenButton
            disabled={
              JSON.stringify(checkData) === JSON.stringify(profileDetails) ||
              // profileDetails?.postalCode?.length !== 6 ||
              profileDetails?.postalCode == "" ||
              profileDetails?.mobileNumber?.length !== 10 ||
              profileDetails?.postalCode == "" ||
              profileDetails?.mobileNumber == "" ||
              profileDetails?.city == "" ||
              profileDetails?.address1 == "" ||
              profileDetails?.address2 == "" ||
              profileDetails?.fullName == "" ||
              profileDetails?.country == "" ||
              profileDetails?.state1 == ""
            }
            onClick={handleSubmit}
          >
            Save Profile
          </StyledGreenButton>
        </div>
      ) : null}
      {/* {modelWin && <ModelFile modelWin={modelWin} catchError={catchError} />} */}
    </div>
  );
}

export default ProfileDetails;

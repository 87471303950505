import React, {useEffect, useState} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";
import {Bar} from "react-chartjs-2";
import {AboutParaTypo} from "../../styledcomponents/beforeLoginWidgets";
import useFetch from "../../customHooks/useFetch";
import {Backdrop, CircularProgress} from "@mui/material";
import {qbresultCategoryApi} from "../../../http/allApis";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Performance - by category",
    },
  },
};

function PerformanceCategory() {
  const [myscore, setMyscore] = useState([]);
  const [userscore, setUserscore] = useState([]);
  const [category, setCategory] = useState([]);
  const { loading: chartLoad, resData: chartResdata } =
    useFetch("/chartcategory");

  const labels = category;
  const data = {
    labels,
    datasets: [
      {
        label: "Your Score",
        data: myscore,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Average User Score",
        data: userscore,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  useEffect(() => {
    if (chartResdata) {
      let mydata = chartResdata?.myscore?.map((item) => item.score);
      setMyscore(mydata);
      let mydata1 = chartResdata?.userscore?.map((item) => item.score);
      setUserscore(mydata1);
    }
  }, [chartResdata]);

  useEffect(() => {
    getResultCategory();
  }, []);

  const getResultCategory = () => {
    qbresultCategoryApi()
      .then((res) => {
        let mydata = res.data.result.map((item) => item.mainCategory);
        setCategory(mydata);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return chartLoad ? (
    <Backdrop open={chartLoad} invisible>
      <CircularProgress style={{ color: "#f38424" }} />
    </Backdrop>
  ) : (
    <div>
      <AboutParaTypo variant="body2" gutterBottom>
        The chart below shows your performance by category.
      </AboutParaTypo>

      <Bar options={options} data={data} />
    </div>
  );
}

export default PerformanceCategory;

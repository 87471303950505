import React, {useEffect, useRef, useState} from "react";
import {TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {SettingExamAddApi, SettingExamApi, SettingExamDeleteApi, SettingExamEditApi,} from "../../../http/allAdminApi";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    InputBase,
    Menu,
    MenuItem,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import AdminTextEditor from "../widgets/AdminTextEditor";
import {default as swal} from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";
import {Modal} from "antd";

function AdminExam() {
  const examUseRef = useRef(null);
  const [examData, setExamData] = useState([]);
  const [examId, setExamId] = useState("");
  const [exam, setExam] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPage, setShowPage] = useState("Table");
  const [examTE, setExamTE] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [modelExam, setModelExam] = useState(false);
  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = examData?.filter((item) => {
      let finalValue = value.replace(/\s/g, "").toLowerCase();
      let finalExam = item?.exam?.replace(/\s/g, "").toLowerCase();
      return (
        (item.id && item.id.toString().includes(value)) ||
        (finalExam && finalExam.toString().includes(finalValue.toLowerCase()))
      );
    });
    setResData(filteredResults);
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setModelExam(false);
      setOpen(true);
      handleExamA();
      setAnchorEl(null);
      setShowPage("Table");
      setExam("");
      setExamTE("");
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleClose = () => {
    setShowPage("Table");
    setModelExam(false);
    setExam("");
    setExamTE("");
  };

  const handleSubscriptionMenu = (condition) => {
    setAnchorEl(null);
    if (condition === "Add") {
      setShowPage("Add");
      setModelExam(true);
    } else if (condition === "Edit") {
      setShowPage("Edit");
      setModelExam(true);
      setExamId(paramData?.id);
      setExam(paramData?.exam);
      setExamTE(paramData?.description);
    } else if (condition === "Delete") {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          // cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            handleExamDelete(paramData?.id);
          }
        });
    }
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const handleExamAdd = () => {
    let payload = {
      examName: exam,
      examDescription: examTE,
    };
    SettingExamAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleExamEdit = () => {
    let payload = {
      examId: examId,
      examName: exam,
      examDescription: examTE,
    };
    SettingExamEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleExamDelete = (id) => {
    let payload = {
      examId: id,
    };
    SettingExamDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleExamSave = () => {
    if (showPage === "Add") {
      handleExamAdd();
    } else if (showPage === "Edit") {
      handleExamEdit();
    }
  };

  const handleExamA = () => {
    SettingExamApi()
      .then((res) => {
        if (res?.data?.data) {
          setResData(res?.data?.data);
          setExamData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };
  useEffect(() => {
    handleExamA();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "exam",
      headerName: "Exam",
      flex: 7,
    },
    {
      field: "age",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
                margin: ".5rem",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Exam
      </TransparentHead>
      <div>
        {/* {showPage === "Add" || showPage === "Edit" ? ( */}
        <div style={{ width: "100%" }}>
          <Modal
            // style={{  }}
            width={1350}
            title={
              <Typography variant="h6" gutterBottom>
                Add Exam{" "}
              </Typography>
            }
            open={modelExam}
            onOk={() => setModelExam(false)}
            onCancel={handleClose}
            footer={null}
          >
            <div
              style={{
                margin: "1rem 0",
                backgroundColor: "#fff",
                padding: "2rem 1rem",
              }}
            >
              <TextField
                type="text"
                size="small"
                value={exam}
                label="Exam"
                id="exam"
                style={{ margin: ".5rem 0 1.5rem 0" }}
                fullWidth
                sx={{
                  "& .MuiInputBase-input": {
                    border: "1px solid #000",
                    padding: "0.5rem 0.3rem 0.5rem 0.2rem",
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-formControl": {
                    top: "-8px",
                  },
                }}
                onChange={(e) => setExam(e.target.value)}
              />
              <TransparentHead
                variant="h6"
                style={{ color: "#2c3643", fontWeight: "bold" }}
              >
                Description
              </TransparentHead>
              <div
                style={{
                  lineHeight: "1em",
                  color: "#000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem",
                  marginTop: "1rem",
                }}
              >
                <AdminTextEditor
                  editorRef={examUseRef}
                  editorFor={"exam"}
                  content={examTE}
                  setContent={(val) => setExamTE(val)}
                />
              </div>
              <div
                style={{
                  marginTop: "2rem",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: "#dc3545",
                    color: "#fff",
                    marginRight: "1rem",
                    outline: "none",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor:
                      exam === "" || examTE === "" ? "white" : "#2c3643",
                    color: exam === "" || examTE === "" ? "black" : "#fff",
                    outline: "none",
                  }}
                  disabled={exam === "" || examTE === ""}
                  onClick={handleExamSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        {/* ) : ( */}
        <div
          style={{
            margin: "1rem 0",
            backgroundColor: "#fff",
            padding: "2rem 1rem",
          }}
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <StyledGreenButton
              // onClick={() => setShowPage("Add")}
              onClick={() => handleSubscriptionMenu("Add")}
              style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
            >
              <Add fontSize="small" /> Add New
            </StyledGreenButton>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
              color: "black",
            }}
          >
            <InputBase
              placeholder="Search..."
              value={searchVal}
              size="small"
              style={{
                width: "35rem",
                lineHeight: "1em",
                color: "#000",
                border: "1px solid #000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
              }}
              onChange={handleInputChange}
            />
          </div>
          <AdminDataGrid loading={loading} columns={columns} rows={resData} />
        </div>
        {/* )} */}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleSubscriptionMenu("Edit")}>Edit</MenuItem>
        <MenuItem onClick={() => handleSubscriptionMenu("Delete")}>
          Delete
        </MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminExam;

import {ChevronRight} from "@mui/icons-material";
import {Alert, Card, Divider, Grid, Snackbar, Typography, useMediaQuery, useTheme,} from "@mui/material";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    FlexDiv,
    PlanValidityDiv,
    StyleBlueBox,
    StyledGreenButton,
    StyledRedButton,
} from "../styledcomponents/styledDBwidgets";
import {Modal} from "antd";
import {orderIdApi, paymentCheckApi} from "../../http/allApis";
import useRazorpay from "react-razorpay";
import ModelFile from "../landing/ModelFile";
import {StyledButton} from "../styledcomponents/styledQBExamWidgets";
import {filterById, isEqualIgnoreCaseAndTrimmed, mergedArray} from "../shared/utils/commonValidators";
import {CardActions, CardContent} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {setSelectedPlanData} from "../redux/planValiditySlice";

function PlanValidity({ planDetails, mockOptions, questionExamOptions, setDummy, dummy }) {
  const person = JSON.parse(sessionStorage.getItem("person"));
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [extendModalOpen, setExtendModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  const Razorpay = useRazorpay();
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const isPlanExpired = moment(planDetails?.expiry.date) > moment() ? true : false;
  const [selectedExtendSubscription, setSelectedExtendSubscription] = React.useState(null);
  const isQuestionBank = isEqualIgnoreCaseAndTrimmed(planDetails?.exam1, "Question Bank");
  const dispatch = useDispatch();

  let id = planDetails?.id;
  let name = planDetails?.name;


  const handleNavigation = () => {
    if (isQuestionBank) {
      navigate("/student/qbexam", {
        state: { expiryDate: planDetails?.expiry.date, PlanValidity: planDetails },
      });
    } else {
      navigate("/student/mocktest", {
        state: {
          mockDetails: planDetails,
        },
      });
    }
  };

  const getExtendData = (planDetailsData) => {
    const mergedQnMockOptionsData = mergedArray(questionExamOptions, mockOptions);
    const filteredOptions = filterById(mergedQnMockOptionsData, planDetailsData.subid);

    if (filteredOptions) {
      setSelectedExtendSubscription(filteredOptions); // Set the selected subscription
      const payableAmount = filteredOptions.offerAmount
          ? filteredOptions.offerAmount
          : filteredOptions.amount; // Calculate the payable amount
      setSubscriptionAmount(payableAmount); // Set the subscription amount in state
      console.log("Subscription Amount:", payableAmount);
    }
    setExtendModalOpen(true);
  };

  const paymentcheck = (signature, orderId, paymentId, receipt, selectedqb, selectedmock) => {
    let payload = {
      extend: true,
      amount: subscriptionAmount,
      fullName: person.fullName,
      email: person.email,
      mobile: person.mobileNumber,
      orderid: orderId,
      selectedqb: selectedqb,
      selectedmock: selectedmock,
      paymentid: paymentId,
      signature: signature,
      receipt: receipt,
    };
    paymentCheckApi(payload)
      .then(function (response) {
        if (response.data.result === true) {
          setDummy(!dummy);
          setMessage("Resource added Successfully!");
          setAlertstatus("success");
          setOpen(true);
          setSubscriptionAmount(0);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setMessage("Error!");
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePayment = () => {
    const payloadMack = {
      subId: selectedExtendSubscription.id.toString(),
      discount: "0",
    };
    const emptyObject = {
      subId: "",
      discount: "0"
    };
    let payload = {
      amount: subscriptionAmount * 100,
      selectedqbPay: isQuestionBank ? payloadMack : emptyObject,
      selectedmockPay: !isQuestionBank ? [payloadMack] : [emptyObject],
    };
    orderIdApi(payload)
        .then(function (response) {
          const options = {
            key: "rzp_live_tur63s7UHuhIQr",
            // key: "rzp_test_VuryPxMzbyAwLO",
            amount: subscriptionAmount * 100,
            currency: "INR",
            order_id: response.data.result.orderid,
            handler: (res) => {
              paymentcheck(
                  res.razorpay_signature,
                  res.razorpay_order_id,
                  res.razorpay_payment_id,
                  response.data.result.receipt,
                  payload.selectedqbPay,
                  payload.selectedmockPay
              );
            },
            prefill: {
              name: person.fullName,
              email: person.email,
              contact: person.mobileNumber,
            },
            theme: {
              color: "#31a188",
              borderRadius: "4",
            },
            payment_capture: "1",
            // timeout: 1000,
          };
          const rzpay = new Razorpay(options);
          rzpay.open();
          setExtendModalOpen(false);
        })
        .catch(function (error) {
          console.log(error);
        });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (planDetails) {
      dispatch(setSelectedPlanData(planDetails));
    }
  }, [planDetails, dispatch]);

  return (
    <div>
      <StyleBlueBox key={id} id={id}>
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} md={8}>
            <FlexDiv>
              <Typography
                fontWeight={500}
                variant={isSmall ? "p" : "h6"}
                color={theme.palette.mode === "dark" ? "#fff" : "#5A5A5A"}
              >
                {isEqualIgnoreCaseAndTrimmed(planDetails?.exam1, "Question Bank") ? planDetails?.exam1 : name}
              </Typography>
              <Typography
                variant={isSmall ? "caption" : "p"}
                fontSize={isSmall ? 13 : 14}
                color={
                  isPlanExpired
                    ? theme.palette.mode === "dark"
                      ? "#fff"
                      : "#5A5A5A"
                    : "error"
                }
              >
                {isPlanExpired
                  ? `Expires: ${moment(planDetails?.expiry.date).format("Do MMM YY")}`
                  : `Expired on: ${moment(planDetails?.expiry.date).format("Do MMM YY")}`}
              </Typography>
            </FlexDiv>
          </Grid>
          <Grid item xs={12} md={4}>
            <PlanValidityDiv>
              {isPlanExpired ? (
                <StyledGreenButton
                  size={isSmall ? "small" : "medium"}
                  style={{ outline: "none" }}
                  onClick={() => handleNavigation()}
                >
                  Use this resource <ChevronRight />
                </StyledGreenButton>
              ) : (
                <StyledRedButton
                  onClick={() => getExtendData(planDetails)}
                  size={isSmall ? "small" : "medium"}
                >
                  Extend
                </StyledRedButton>
              )}
            </PlanValidityDiv>
          </Grid>
        </Grid>

        <Modal
          width={400}
          open={extendModalOpen}
          footer={null}
          onCancel={() => setExtendModalOpen(false)}
        >
          <div>
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
            > Extend Subscription
            </span>
          </div>

          <Divider
            style={{
              marginTop: ".5rem",
              marginBottom: "2rem",
            }}
          />
          <Card>
            <CardContent sx={{ height: 100 }}>
              <Typography variant="h7" component="div">
                {isEqualIgnoreCaseAndTrimmed(planDetails?.exam1, "Question Bank") ? selectedExtendSubscription?.label?.replace("Subscription", "Validity ") : selectedExtendSubscription?.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {isEqualIgnoreCaseAndTrimmed(planDetails?.exam1, "Question Bank")
                    ? ""
                    : "An extra 3 days will be added to the subscription period"}
              </Typography>
            </CardContent>
            <CardActions style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <span style={{ marginBottom: "1rem", fontSize: "14px" }}>
                Amount &#8377;
                {subscriptionAmount}
              </span>
            </CardActions>
          </Card>
          <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Ensures vertical alignment
                marginTop: "2rem",
                gap: "1rem", // Use gap for consistent spacing between buttons
              }}
          >
            <StyledButton
                disabled={subscriptionAmount === 0}
                onClick={handlePayment}
                style={{
                  outline: "none",
                  backgroundColor: "#31a188",
                  color: "white",
                }}
            >
              Extend
            </StyledButton>
            <StyledButton
                onClick={() => setExtendModalOpen(false)}
                style={{
                  outline: "none",
                  backgroundColor: "#dc3545",
                  color: "white",
                }}
            >
              Cancel
            </StyledButton>
          </div>
        </Modal>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertstatus}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </StyleBlueBox>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default PlanValidity;

import React, {useEffect, useRef, useState} from "react";
import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    InputBase,
    Menu,
    MenuItem,
    Snackbar,
    TextField,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {
    HyRefApi,
    HyTopicAddApi,
    HyTopicDeleteApi,
    HyTopicEditApi,
    TopicsApi,
    TopicsCategoryApi,
} from "../../../http/allAdminApi";
import AdminTextEditor from "../widgets/AdminTextEditor";
import {Form, Modal, Switch} from "antd";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {default as swal} from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";

function AdminHYTopic() {
  const highYieldUseRef = useRef(null);
  const [checkBox, setCheckBox] = useState(true);
  const [hyTopics, setHyTopics] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [subCatVal, setSubCatVal] = useState("");
  const [topic, setTopic] = useState("");
  const [referenceText, setReferenceText] = useState("");
  const [showPage, setShowPage] = useState("Table");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const handleApiClose = () => {
    setOpen(false);
  };

  const [modelHighYield, setModelHighYield] = useState(false);

  const deleteApi = (id) => {
    let payload = {
      id: id,
    };
    HyTopicDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };
  const handleTopicCat = () => {
    TopicsCategoryApi().then((res) => {
      if (res?.data?.status === "Success") {
        setSubCatOptions(res?.data?.data);
      }
    });
  };

  const handleTopicMenu = (val, data) => {
    setAnchorEl(null);
    if (val === "Add") {
      setShowPage(val);
      handleTopicCat();
      setModelHighYield(true);
    } else if (val === "Edit") {
      setShowPage(val);
      handleTopicCat();
      setModelHighYield(true);

      HyRefApi(data.id).then((res) => {
        if (res?.data?.status === "Success") {
          let hyData = res?.data?.data;
          setSubCatVal(hyData?.highCategoryId);
          setTopic(hyData?.topic);
          setReferenceText(hyData?.explanation);
          setCheckBox(hyData?.status);
        }
      });
    }
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setReferenceText("");
      setTopic("");
      setSubCatVal("");
      setAlertstatus("success");
      setOpen(true);
      handleHYtopic();
      setAnchorEl(null);
      setShowPage("Table");
      setModelHighYield(false);
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleTopicAdd = () => {
    let payload = {
      highCategoryId: subCatVal,
      topic: topic,
      status: checkBox,
      explanation: referenceText,
    };
    HyTopicAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleTopicEdit = () => {
    let payload = {
      highCategoryId: subCatVal,
      id: paramData?.id,
      topic: topic,
      status: checkBox,
      explanation: referenceText,
    };
    HyTopicEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleTopicSave = () => {
    if (showPage === "Add") {
      handleTopicAdd();
    } else if (showPage === "Edit") {
      handleTopicEdit();
    }
  };

  const handleDelete = (data) => {
    setAnchorEl(null);
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          deleteApi(data.id);
        }
      });
  };

  const handleClose = () => {
    setModelHighYield(false);

    setShowPage("Table");
    setReferenceText("");
    setTopic("");
    setSubCatVal("");
  };

  const handleHYtopic = () => {
    setLoading(true);
    TopicsApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setHyTopics(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    handleHYtopic();
  }, []);

  // useEffect(() => {
  //   if (showPage === "Add" || showPage === "Edit") {
  //     TopicsCategoryApi().then((res) => {
  //       let data = res?.data?.data || [];
  //       data.sort((a, b) => a.category.localeCompare(b.category));
  //       data.sort((a, b) => a.topic.localeCompare(b.topic));
  //       if (showPage === "Add" && data?.length > 0) {
  //         setSubCatVal(data[0].id);
  //       }
  //       setSubCatOptions(data);
  //     });
  //   }
  // }, [showPage]);

  const columns = [
    { field: "id", headerName: "ID", width: 60, hide: true },
    {
      field: "topic",
      headerName: "Topic",
      flex: 4,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              overflow: "auto",
              padding: "1rem",
            }}
          >
            <AboutParaTypo variant="p">{params?.row?.topic}</AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              // padding: "3px",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 2,
      hide: true,
    },
    {
      field: "age",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleInputChange = (event) => {
    const value = event.target.value;
    const filteredResults = hyTopics?.filter((item) => {
      let finalValue = value.replace(/\s/g, "").toLowerCase();
      let finalCategory = item.category.replace(/\s/g, "").toLowerCase();
      let finalTopic = item.topic.replace(/\s/g, "").toLowerCase();

      return (
        item.id.toString().includes(value) ||
        finalCategory.toLowerCase().includes(finalValue.toLowerCase()) ||
        finalTopic.toLowerCase().includes(finalValue.toLowerCase())
      );
    });
    setSearchVal(value);
    setResData(filteredResults);
  };

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        High Yield Topics
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        {/* {showPage === "Add" || showPage === "Edit" ? ( */}
        <div style={{ width: "100%" }}>
          <Modal
            // style={{  }}
            width={1350}
            // title={
            //   <Typography variant="h6" gutterBottom>
            //     Highyield category{" "}
            //   </Typography>
            // }
            open={modelHighYield}
            onOk={() => setModelHighYield(false)}
            onCancel={handleClose}
            footer={null}
          >
            <div>
              <TransparentHead
                variant="h6"
                style={{ color: "#2c3643", fontWeight: "bold" }}
              >
                Highyield category
              </TransparentHead>
              <div style={{ marginTop: "1rem", color: "black" }}>
                <TextField
                  select
                  size="small"
                  value={subCatVal}
                  placeholder="Highyield category"
                  id="name"
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  onChange={(e) => setSubCatVal(e.target.value)}
                  sx={{
                    "& .MuiInputBase-input": {
                      border: "1px solid #31a188",
                      padding: "0.3rem 0 0.3rem 0.2rem",
                      color: "#000",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiInputLabel-formControl": {
                      top: "-8px",
                    },
                  }}
                >
                  <MenuItem value={""} />
                  {subCatOptions?.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      {item.category}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <TransparentHead
                variant="h6"
                style={{ color: "#2c3643", fontWeight: "bold" }}
              >
                Topic
              </TransparentHead>
              <div style={{ marginTop: "1rem" }}>
                <TextField
                  size="small"
                  value={topic}
                  placeholder="Topic"
                  id="name"
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: 300 },
                    },
                  }}
                  onChange={(e) => setTopic(e.target.value)}
                  sx={{
                    "& .MuiInputBase-input": {
                      border: "1px solid #31a188",
                      padding: "0.3rem 0 0.3rem 0.2rem",
                      color: "#000",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                    "& .MuiInputLabel-formControl": {
                      top: "-8px",
                    },
                  }}
                ></TextField>
              </div>

              <TransparentHead
                variant="h6"
                style={{
                  color: "#2c3643",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Content
              </TransparentHead>
              <div
                style={{
                  lineHeight: "1em",
                  color: "#000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem",
                  marginTop: "1rem",
                }}
              >
                <AdminTextEditor
                  editorRef={highYieldUseRef}
                  editorFor={"QBReference"}
                  content={referenceText}
                  setContent={(val) => setReferenceText(val)}
                />
              </div>
              <div>
                <Form.Item label="Status">
                  <Switch
                    checked={checkBox}
                    onChange={() => setCheckBox(!checkBox)}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: "#dc3545",
                    color: "#fff",
                    marginRight: "1rem",
                    outline: "none",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor:
                      referenceText === "" || topic === "" || subCatVal === ""
                        ? "white"
                        : "#2c3643",
                    color:
                      referenceText === "" || topic === "" || subCatVal === ""
                        ? "black"
                        : "#fff",
                    outline: "none",
                  }}
                  disabled={
                    referenceText === "" || topic === "" || subCatVal === ""
                  }
                  onClick={handleTopicSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        {/* ) : ( */}
        <div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <StyledGreenButton
              onClick={() => handleTopicMenu("Add")}
              style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
            >
              <Add fontSize="small" /> Add New
            </StyledGreenButton>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
              color: "black",
            }}
          >
            <InputBase
              placeholder="Search..."
              value={searchVal}
              size="small"
              style={{
                width: "35rem",
                lineHeight: "1em",
                color: "#000",
                border: "1px solid #000",
                margin: ".4rem 0 .5rem 0",
                padding: ".3rem 0 .3rem .2rem",
              }}
              onChange={handleInputChange}
            />
          </div>
          <AdminDataGrid loading={loading} columns={columns} rows={resData} />
        </div>
        {/* )} */}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleTopicMenu("Edit", paramData)}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete(paramData)}>Delete</MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminHYTopic;

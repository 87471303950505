import axios from "axios";
import { useEffect, useState } from "react";
import constantValues from "../Common/ConstantValues";

function useFetch(url, flag) {
  const tokenType = JSON.parse(sessionStorage.getItem("Tokentype"));
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState(null);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  useEffect(() => {
    if (!url || !flag) return;

    const headers = {
      Authorization: `${tokenType?.tokenType} ${token}`,
    };

    const fetchData = async () => {
      setLoading(true);
      try {
        let response;

        if (url === "/getplanvalidity") {
          response = await axios.get(`${constantValues.PGNEET_PROD_URL}${url}`, { headers });
        } else if (url === "/chartsummary") {
          const jsonData = JSON.parse(sessionStorage.getItem("planData"));
          const params = { planId: jsonData?.id };
          response = await axios.get(`${constantValues.PGNEET_PROD_URL}${url}`, { headers, params });
        }

        if (response) {
          setResData(response.data);
        }
      } catch (error) {
        setModelWin(true);
        setCatchError(error?.response?.data?.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, flag]);

  return { loading, resData, modelWin, catchError };
}

export default useFetch;

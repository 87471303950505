import {Typography} from "@mui/material";
import {Modal} from "antd";
import React from "react";
import {StyledButton} from "../styledcomponents/styledQBExamWidgets";
import {useNavigate} from "react-router-dom";
import Error2 from "../../../src/assets/5221808.jpg";

function ModelFile({ catchError, modelWin }) {
  const navigate = useNavigate();

  const handleNavigator = () => {
    navigate("/");
    sessionStorage.clear();
  };

  return (
    <Modal
      open={modelWin}
      onOk={handleNavigator}
      onCancel={handleNavigator}
      footer={null}
    >
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img src={Error2} alt="Error2" width={"60%"} height={"60%"} />
      </div>
      <div>
        <Typography
          style={{
            marginBottom: "1rem",
            marginLeft: "9rem",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {catchError === undefined ? "Your Session is Expired." : catchError}
        </Typography>
      </div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <StyledButton onClick={handleNavigator} style={{ outline: "none" }}>
          Ok
        </StyledButton>
      </div>
    </Modal>
  );
}

export default ModelFile;

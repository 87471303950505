import React from "react";
import {
    AboutBannerHead,
    AboutParaTypo,
    BlueContainerSection,
    ContentDiv,
} from "../styledcomponents/beforeLoginWidgets";

function CopyRights() {
  window.scroll(0, 0);
  return (
    <div>
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          Copyright Credits
        </AboutBannerHead>
      </BlueContainerSection>
      <ContentDiv>
        <div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              At PGNEET©, we have taken great care to obtain permission to use
              portions of websites, teaching materials, and other individuals'
              work. We are fully aware of the legal and ethical importance of
              giving proper copyright credit. Our utmost effort has been made to
              provide clear and legible credit where applicable.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              It's worth noting that some of the information we use is sourced
              from online materials, educational videos, and peer group
              discussions, much of which is available on websites with 'free
              download options,' 'fair usage policies,' and 'copyright-free
              content.' Given the widespread availability of this material,
              particularly previous exam materials and explanations, it can be
              challenging to ascertain the legitimate owner of the content to
              give proper credit.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              Nonetheless, we have undertaken diligent efforts to identify
              copyright ownership within the work itself. This includes reaching
              out to individuals or entities associated with the work to request
              copyright information. For example, we may send emails to
              addresses listed on websites or contact the publishers of relevant
              books.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              We have been transparent in determining how much of the work we
              will use and for what educational purposes. We understand that the
              use of small portions of copyrighted material for educational
              purposes falls under the concept of 'fair use,' which allows such
              use without explicit permission from the copyright owner. We
              firmly believe that our use of the material aligns with the
              principles of fair use. It's important to note that fair use is a
              set of guidelines rather than a strict law.
            </AboutParaTypo>
          </div>
        </div>
        <div>
          {/* <div style={{ marginTop: "1rem" }}>
            <SectionHead variant="h5" fontWeight={"bold"}>
              Credits:
            </SectionHead>
          </div> */}
          <div style={{ marginTop: "1rem" }}>
            <AboutParaTypo variant="p">
              At PGNEET©, we not only value copyright acknowledgment but also
              understand the significance of content curation. We recognize that
              content curation is not about indiscriminate copying but rather
              about thoughtfully selecting and sharing content that adds value
              to the educational journey of our users. Every effort is taken to
              ensure that curated pieces are attributed appropriately to their
              original sources, and context is provided to help users grasp the
              significance of the content. We are committed to maintaining the
              highest standards of content curation to enrich the educational
              experience for all our users.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <AboutParaTypo variant="p">
              We’d like to use this opportunity to extend our sincere gratitude
              to our peers, seniors, teachers, and authors of medical entrance
              exam books and courses. We ourselves have benefited from these
              resources during our own PG entrance exam preparations, especially
              in times when internet and online teaching resources were not as
              readily available.
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <AboutParaTypo variant="p">
              If you believe there is any aspect of content curation that
              requires attention or improvement or if you believe there is a
              copyright infringement concern, please feel free to reach out to
              us at{" "}
              <a
                href="mailto:admin@pgneet.net"
                style={{ color: "blue", textDecoration: "none" }}
              >
                admin@pgneet.net
              </a>
              , and we will take the necessary steps to address your concerns.
            </AboutParaTypo>
          </div>
          {/* <div style={{ marginTop: "2rem" }}>
            <AboutParaTypo variant="p">
              Q6. Adapted from / Text and Image Credits - Development of the
              Vertebrate Kidney. Source: Developmental Biology 8th edition
              Copyright @2006 by The McGraw-Hill Companies, Adapted from work by
              Ashley Sawle [CC BY-SA 3.0], via Wikimedia Commons
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <AboutParaTypo variant="p">
              Q9. Adapted from / Text and Image Credits - Reproduced with
              permission fror Exviroranent Health Perspectives. Sadler T.W.
              Exviroranent Health Perspectives Suppleraents Volurae 108, Number
              S3, June 2000.4 to 7 weeks : reference Sleisenger 10 ed.Source:
              http://www embryology. chf universities of Fibourg Lausanne and
              Bern)
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <AboutParaTypo variant="p">
              Q11. Adapted from / Text and Image Credits - Umbilical cord. Image
              courtesy S Bhimji MD, Image Credit : Ultrasound in Obstet & Gyne,
              Volume: 27, Issue: 4. Pages: 349-354, First published: 24 March
              2006. DOI: (10.1002/u0g.2762)
            </AboutParaTypo>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <AboutParaTypo variant="p">
              Q12. Adapted from / Text and Image Credits - Encyclopedia
              Britannica 2008 Dirty Medicine youtube channel
              https://www.youtube.com/watch?v=81ADk8Kybxw&ab_channel=DirtyMedicine
            </AboutParaTypo>
          </div> */}
        </div>
      </ContentDiv>
    </div>
  );
}

export default CopyRights;

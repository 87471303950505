import {ChevronRight} from "@mui/icons-material";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import NoOfQuestionSelect from "../student/widgets/NoOfQuestionSelect";
import PrevTimeTest from "../student/widgets/PrevTimeTest";
import TimeDurationTextField from "../student/widgets/TimeDurationTextField";
import {StyledButton, TimedTestDiv,} from "../styledcomponents/styledQBExamWidgets";
import {useNavigate} from "react-router-dom";
import {categoryCountApi, categorySubCountApi, selectedQuestionsTTApi,} from "../../http/allApis";
import TimedTestModal from "../student/widgets/TimedTestModal";
import ChartComponent from "../student/widgets/ChartComponent";
import {useSelector} from "react-redux";

function TimedTest() {
  const [noOfQuestions, setnoOfQuestion] = useState(10);
  const [timeDuration, setTimeDuration] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [maxnumber, setMaxnumber] = useState(0);
  const [tqdisplay, setTqdisplay] = useState(0);
  const [aqdisplay, setAqdisplay] = useState(0);
  const [dropvalue, setDropValue] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [questiondata, setQuestiondata] = useState([]);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalWrong, setTotalWrong] = useState(0);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
    // Access the plan validity data from Redux
    const planValidity = useSelector((state) => state.planValidity.selectedPlanData);
  useEffect(() => {
    getCategory();
  }, []);
  const getMaxnumber = (dropvalue, category1) => {
    setDropValue(dropvalue);
    setSelectedCategory(category1);
    let payload = {
      category: category1,
      dropvalue: dropvalue,
      planId: planValidity?.id,
    };
    categorySubCountApi(payload)
      .then((response) => {
        setMaxnumber(response.data.finalcount);
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        } else {
          console.log("Error");
        }
      });
  };
  const getCategory = () => {
    let payload = {
      planId: planValidity?.id,
    };
    categoryCountApi(payload)
      .then((response) => {
        const result = response.data.result;
        setCategory(result || []);
        setTotalCorrect(response.data.totalCorrect);
        setTotalWrong(response.data.totalWrong);
        setTqdisplay(
          result.reduce(
            (prevTqdisplay, item) => prevTqdisplay + item.totalQuestionCount,
            0,
          ),
        );
        setAqdisplay(
          result.reduce((prevTqdisplay, item) => prevTqdisplay + item.count, 0),
        );
      })
      .catch((response) => {
        setModelWin(true);
        setCatchError(response?.response?.data?.message);
      });
  };

  const startTimedTest = () => {
    let payload = {
      lot: timeDuration,
      noq: noOfQuestions,
      categoryId: selectedCategory,
      planId: planValidity?.id,
    };
    selectedQuestionsTTApi(payload)
      .then((response) => {
        let mydata = response.data.result.map((item) => item.questionid);

        navigate("/student/timed-test", {
          state: {
            questionIds: mydata,
            referid: response.data.referid,
            scoredata: response.data.scoredata,
            timeDuration: timeDuration * 60,
            componentIdentity: "timedTestStarted",
            // startNumber: myStartNumberData
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* <Typography variant="h6" fontWeight={500} fontSize={18} color="#31a188">
        New timed test
      </Typography> */}
      {/* <TimedTestDiv>
        <Typography
          variant="h6"
          fontWeight={500}
          fontSize={17}
          color={theme.palette.text.primary}
          marginTop={"1rem"}
        >
          The duration of the test will automatically default to the equivalent
          amount of time given in the real exam
        </Typography>
      </TimedTestDiv> */}
      <Typography variant="h6" fontWeight={500} fontSize={18} color="#31a188">
        Filter your Timed Test questions below ({maxnumber} questions selected):
      </Typography>
      <Box>
        <ChartComponent
          category={category}
          maxnumber={maxnumber}
          tqdisplay={tqdisplay}
          aqdisplay={aqdisplay}
          getMaxnumber={getMaxnumber}
          dropvalue={dropvalue}
          totalCorrect={totalCorrect}
          totalWrong={totalWrong}
        />
      </Box>
      {/* <Box sx={{ mt: "1rem" }}>
        <QuestionSelection
          dropvalue={dropvalue}
          setDropValue={(val) => setDropValue(val)}
        />
      </Box> */}
      <TimedTestDiv>
        <NoOfQuestionSelect callBack={(val) => setnoOfQuestion(val)} />
      </TimedTestDiv>
      <TimedTestDiv>
        <TimeDurationTextField
          callBack={(val) => setTimeDuration(val)}
          noOfQuestions={noOfQuestions}
        />
      </TimedTestDiv>
      <TimedTestDiv>
        <StyledButton
          style={{ outline: "none" }}
          disabled={
            noOfQuestions === 0 || timeDuration === "" || timeDuration === "0"
          }
          onClick={() => setIsModalOpen(true)}
        >
          Start The Timed Test <ChevronRight />
        </StyledButton>
      </TimedTestDiv>
      <TimedTestDiv>
        <PrevTimeTest type={"timed-test"} handleRow={""} />
      </TimedTestDiv>
      <TimedTestModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        noOfQuestions={noOfQuestions}
        timeDuration={timeDuration}
        isSmall={isSmall}
        startTimedTest={startTimedTest}
      />
    </div>
  );
}

export default TimedTest;

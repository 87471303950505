import Axios from "axios";
import React, {useEffect} from "react";
import constantValues from "../components/Common/ConstantValues";

const BlogUnsubscription = () => {
  const location = window?.location?.search;
  let authToken = location;
  let email;
  if (location != "") {
    let splitOne = authToken.split("?");
    let secondSplit = splitOne[1].split("pg-auth=");
    email = secondSplit[1].split("&pg-identity.email=");
  }
  useEffect(() => {
    if (location != "") {
      let payload = {
        "pg-auth": email[0],
        "pg-identity.email": email[1],
      };
      Axios.post(
        constantValues.PGNEET_PROD_URL + "/auth/blog-unSubscribe",
        null,
        {
          params: payload,
        },
      )
        .then((res) => {
          if (res?.data?.status === "Success") {
          }
        })
        .catch((err) => {
          console.log("Something wrong");
        });
    }
  }, []);
  return (
    <div style={{ margin: "1rem 0 0 1rem" }}> Unsubscribe Successfully</div>
  );
};

export default BlogUnsubscription;

import {makeStyles} from "@mui/styles";

export default makeStyles((theme) => ({
  faqPageMainBox: {
    border: "1.5px solid  #EBECF0",
    borderRadius: "6px",
    marginTop: ".5rem",
  },
  boxHeader: {
    padding: "1rem",
    borderBottom: "1.5px solid  #EBECF0",
  },
}));

export const whyPgneetContent = {
  FQR: "We streamline your study and revision process for maximum efficiency. Our approach focuses on grasping key concepts before delving into specifics. We employ Spaced Repetition, a memory technique that optimizes recall intervals, ensuring better retention.",
  ORI: "Our resources include:",
};

export const whyPgneetContent2 = [
  "Question Bank: Over 8000 quality questions, regularly updated.",
  "Mock Test: Simulates actual exams, enhancing your ability to answer ambiguous questions.",
  "High Yield Notes: Organized for last-minute revision, constantly updated.",
  "Exam Intelligence: Provides the most relevant revision topics, based on examination board reports.",
  "Behavioural Insights: Emphasizes repeated MCQs and high-yield topics, with user note integration.",
  "Social Feedback: We value and apply user feedback to improve our services.",
];
const scrollToTop = () => {
  // window.scrollTo(0, y - 70);
};
window.scroll(0, 0);

const WQB = (
  <>
    {" "}
    Our{" "}
    <span style={{ color: "#31a188" }} onClick={scrollToTop}>
      Question Bank
    </span>{" "}
    is meticulously curated to encompass all relevant topics and adapts to the
    evolving format of the entrance exams. It utilizes an intelligent database
    to ensure only the highest-quality questions are available, and it offers
    performance comparisons with other users to identify strengths and
    weaknesses, aiding your focused revision.
  </>
);
const MQB = (
  <>
    {" "}
    To prepare you for success on your first attempt, we provide 'Timed’{" "}
    <span style={{ color: "#31a188" }} onClick={scrollToTop}>
      Mock tests
    </span>{" "}
    to simulate exam conditions and deepen your understanding of key concepts.
    Additionally, our{" "}
    <span style={{ color: "#31a188" }} onClick={scrollToTop}>
      High-yield notes
    </span>{" "}
    are backed by the latest evidence, facilitating efficient and effective
    revision.
  </>
);

export const welcomeContent = {
  content1: `PGNEET© provides a comprehensive online revision course tailored for doctors preparing for medical postgraduate entrance exams such as NEET PG, INI CET, DNB PDCET, and FMGE. Our materials are regularly updated to align with the latest trends in medical postgraduate entrance exams, featuring 'best answer 1 of 4' style MCQs.`,
  content2: WQB,
  content3: MQB,
  content4: `Register with us to access our valuable resources and receive assistance in your journey to excel in medical postgraduate entrance exams.`,
};
const offerConentPNMock = (
  <>
    <span style={{ fontWeight: "bold" }}>Please note </span>&nbsp;that every
    Mock test is valid for 3 days from the date of purchase. Therefore, we
    advise candidates to plan Mock test subscription carefully.
  </>
);

const offerConentPNQB = (
  <>
    <span style={{ fontWeight: "bold" }}>Please note</span>&nbsp;that
    'High-yield' notes are provided free with Question bank subscription only
    and NOT with Mock test subscription.
  </>
);

export const offerContent = {
  content1: {
    title: "Question Bank",
    text: "We have sat the NEET PG, INI CET, DNB PDCET, and FMGE exams ourselves; we understand it very well and we present you ‘all you’ll ever need’ resource for medical PG entrance exams preparation!",
    QBPricing1: "QBank 1 month subscription ₹ 2,499",
    QBPricingFirst: "(30 days validity from the time of purchase)",
    QBPricing2: "QBank 3-month subscription ₹ 3,999",
    QBPricingSec: "(90 days validity from the time of purchase)",
    QBPricing3: "QBank 6-month subscription ₹ 5,999",
    QBPricingThird: "(180 days validity from the time of purchase)",
  },
  content2: {
    title: "Mock Test",
    line1: `Is your exam technique bulletproof?`,
    line2: "Can you apply your knowledge under pressure?",
    line3: "Can you cope with unfamiliar questions?",
    line4: "Put your knowledge to the test with our Mock Tests",
    MQBPricing1:
      "Each of the Ten Mock test subscription we offer is priced at ₹ 500. ",
    MQBPricingFirst: offerConentPNMock,
  },
  content3: {
    title: "High Yield Notes",
    text: `We recognise that to be successful learning should be contextual and focussed on important topics. Therefore, we have included High-yield notes relevant to the syllabus of the exam.`,
    text1: offerConentPNQB,
  },
};

// about page

export const aboutListPara = {
  content1: `At PGNEET©, we're dedicated to providing affordable, high-quality revision resources for medical professionals. Our platform was born out of a need for straightforward, cost-effective, and user-friendly revision tools, driven by our own experiences with postgraduate exams.`,
  content2: `Our website, managed by field specialists and clinical authors, focuses on online MCQs tailored for Indian Medical PG Entrance Exams like NEET PG, INI CET, DNB PDCET, and FMGE. We prioritize quality content and offer performance comparison with peers to assess your readiness accurately.`,
  content3: `Our extensive database features meticulously curated questions from past tests. Join us to benefit from our quality-driven approach to medical exam preparation.`,
  // content4: `In addition to our commitment to quality, our platform offers essential features that empower you to assess your readiness for the exam by comparing your performance with your peers. This feature aids in gauging your level of preparedness accurately.`,
  // content5: `Most questions within our extensive database are meticulously curated from past tests, with the aim of surpassing our own standards and becoming the primary resource for a wide spectrum of candidates. We invite you to join our platform and harness the benefits of our quality-driven approach to medical exam preparation.`
};

export const fundingNobleCause = {
  content1: `We are dedicated to making a positive impact on the mental health of children and adolescents. A portion of our profits goes towards establishing a charity for this cause. Our goal is to provide resources and support, creating a safe environment for those in need.`,
  content2: `This reflects our commitment to social responsibility and a brighter future for young individuals facing mental health challenges. Stay tuned for updates as we start this journey.`,
  // content3: `This endeavour reflects our commitment to social responsibility and our genuine desire to contribute to the well-being of future generations. By channelling a portion of our business profits towards this charitable cause, we hope to foster a brighter and healthier future for young individuals facing mental health challenges.`,
  // content4: `Since we are just starting out, we have nothing to show you. Therefore, keep checking back for updates.`
};

export const aboutQuestions = [
  {
    qnDesc: `1. Why choose PGNEET.NET?`,
    answer: `PGNEET.NET © is a comprehensive online revision course that has the ability to be immensely popular. We offer an extensive database, combined with peer reviewed questions, and a system that provides you with feedback on your performance at such a competitive price. PGNEET. NET© offers all of this along with timed tests that simulate the experience of real Medical PG Entrance Exams conducted by NATIONAL BOARD OF EXAMINATIONS (NBE).`,
  },
  {
    qnDesc: `2. Do your questions reflect those found in the exam?`,
    answer: `Yes, our questions are written in accordance with the syllabus produced by the NATIONAL BOARD OF EXAMINATIONS (NBE). We analyse the questions using a data metric analysis similar to that used by the NBE to ensure that questions that would not appear in the exam are removed. This means that you don't waste time on questions that are too hard or difficult and wouldn't be found in the actual exam.`,
  },
  {
    qnDesc: `3. Do you include questions from previous exams?`,
    answer: `Yes, lots. Up to 40% of the exam questions will be repeated from previous exams so we focus on including a high volume of these.`,
  },
  {
    qnDesc: `4. How should I approach answering the questions?`,
    answer: `Questions can be answered by category or can be selected at random. We suggest using the random allocation as there may be clues to the answer based on the category you are practicing. Additionally, the random allocation equates to the experience of the actual exam.`,
  },
];

//question bank

export const categoryList = [
  "Anatomy",
  "Physiology",
  "Biochemistry",
  "Pharmacology",
  "Microbiology",
  "Pathology",
  "Forensic Medicine",
  "Social and Preventive Medicine",
  "Medicine",
  "Dermatology and Venereology",
  "Surgery",
  "Orthopaedics",
  "Anaesthesia",
  "Radiodiagnosis and Radiotherapy",
  "Obstetrics and Gynaecology",
  "Paediatrics",
  "ENT",
  "Ophthalmology",
  "Psychiatry",
];

export const blogList = [
  "All",
  "Anatomy",
  "Physiology",
  "Biochemistry",
  "Pharmacology",
  "Microbiology",
  "Pathology",
  "Forensic Medicine",
  "Social and Preventive Medicine",
  "Medicine",
  "Dermatology and Venereology",
  "Surgery",
  "Orthopaedics",
  "Anaesthesia",
  "Radiodiagnosis and Radiotherapy",
  "Obstetrics and Gynaecology",
  "Paediatrics",
  "ENT",
  "Ophthalmology",
  "Psychiatry",
];

export const blogListStatic = [
  {
    ViewHeading: "International Coming Out Day",
    imageUrl:
      "https://psychiatry-uk.com/2020/wp-content/uploads/2023/10/International-Coming-Out-Day.png",
    Viewcontact:
      "The 11th October is International ‘Coming Out Day’. ‘Coming out’ refers to telling people about your sexual orientation or gender identity. Coming out is a personal decision that is different for every single person and can be done in many different ways. Some may use their social media to tell everyone they know in one go, whilst others may prefer to tell a couple of their closest friends and see how it goes. It is important that you only tell those you feel comfortable with, and do not feel forced to come out. Many LGBTQ+ individuals prefer to only come",
  },
  {
    ViewHeading: "Easy Ways To Improve Your Sleep Hygiene ",
    imageUrl:
      "https://psychiatry-uk.com/2020/wp-content/uploads/2023/09/sleep-hygiene.jpg",
    Viewcontact:
      "Tiny tips to help you get the sleep you need  Introduction A good night’s sleep is just as, if not more, important than a good diet and exercise regime. So, are you getting the recommended 7 and 9 hours of sleep per night ⁽¹⁾? However one of the causes of insomnia is known to be worrying about not sleeping, so it is important to remember that missing the occasional night is not a big deal and that there are lots of ways to improve your sleep, for example here are some sleep hygiene tips to help you improve the snooze.",
  },
  {
    ViewHeading: "Proud of being Autistic! Autistic Pride Day 18th June",
    imageUrl:
      "https://psychiatry-uk.com/2020/wp-content/uploads/2023/06/Autistic-Pride-flag.jpg",
    Viewcontact:
      "Did you know that today is Autistic Pride Day? Although June is most recognised for celebrating LGBTQIA+ Pride, this isn’t the only Pride celebration to be found in June. Have you heard of ‘Autistic Pride?’ What is Autistic Pride? Autistic Pride is a day for autistic people to celebrate their neurodiversity and feel proud of who they are. Autism’s self-advocacy movement is intrinsic to Autistic Pride day, and it is a time where we should step back, and allow ourselves to listen to autistic people and what they have to say. Autistic and proud of it | New Scientist When",
  },
  {
    ViewHeading: "Proud of being Autistic! Autistic Pride Day 18th June",
    imageUrl:
      "https://psychiatry-uk.com/2020/wp-content/uploads/2023/06/Autistic-Pride-flag.jpg",
    Viewcontact:
      "Did you know that today is Autistic Pride Day? Although June is most recognised for celebrating LGBTQIA+ Pride, this isn’t the only Pride celebration to be found in June. Have you heard of ‘Autistic Pride?’ What is Autistic Pride? Autistic Pride is a day for autistic people to celebrate their neurodiversity and feel proud of who they are. Autism’s self-advocacy movement is intrinsic to Autistic Pride day, and it is a time where we should step back, and allow ourselves to listen to autistic people and what they have to say. Autistic and proud of it | New Scientist When",
  },
  {
    ViewHeading: "Easy Ways To Improve Your Sleep Hygiene ",
    imageUrl:
      "https://psychiatry-uk.com/2020/wp-content/uploads/2023/09/sleep-hygiene.jpg",
    Viewcontact:
      "Tiny tips to help you get the sleep you need  Introduction A good night’s sleep is just as, if not more, important than a good diet and exercise regime. So, are you getting the recommended 7 and 9 hours of sleep per night ⁽¹⁾? However one of the causes of insomnia is known to be worrying about not sleeping, so it is important to remember that missing the occasional night is not a big deal and that there are lots of ways to improve your sleep, for example here are some sleep hygiene tips to help you improve the snooze.",
  },
  {
    ViewHeading: "Proud of being Autistic! Autistic Pride Day 18th June",
    imageUrl:
      "https://psychiatry-uk.com/2020/wp-content/uploads/2023/06/Autistic-Pride-flag.jpg",
    Viewcontact:
      "Did you know that today is Autistic Pride Day? Although June is most recognised for celebrating LGBTQIA+ Pride, this isn’t the only Pride celebration to be found in June. Have you heard of ‘Autistic Pride?’ What is Autistic Pride? Autistic Pride is a day for autistic people to celebrate their neurodiversity and feel proud of who they are. Autism’s self-advocacy movement is intrinsic to Autistic Pride day, and it is a time where we should step back, and allow ourselves to listen to autistic people and what they have to say. Autistic and proud of it | New Scientist When",
  },
];

export const mockTestPlan = [
  "Mock Test 1 - ₹ 500",
  "Mock Test 2 - ₹ 500",
  "Mock Test 3 - ₹ 500",
  "Mock Test 4 - ₹ 500",
  "Mock Test 5 - ₹ 500",
  "Mock Test 6 - ₹ 500",
  "Mock Test 7 - ₹ 500",
  "Mock Test 8 - ₹ 500",
  "Mock Test 9 - ₹ 500",
  "Mock Test 10 - ₹ 500",
];
export const blogWelcome = {
  content1: `We are delighted to welcome you to PGNEET©, blog and newsletter community! As a
  valued subscriber, you will now receive regular updates, insightful articles, and
  important news related to medical postgraduate entrance exams, including NEET
  PG, INI CET, DNB PDCET, and FMGE.`,
  content2: `Our blog is a valuable resource for staying up-to-date with the latest trends, tips, and
  strategies in the world of medical exams. You can expect informative articles, exam
  preparation guides, success stories, and much more delivered directly to your inbox.`,
  content3: `By subscribing to our newsletter, you are ensuring that you stay informed and well-
  prepared on your journey to success in these competitive exams.`,
  content4: `Thank you for joining us, and we look forward to sharing valuable insights with you. If
  you ever have any questions or suggestions, please feel free to reach out to us.`,
  content5: `
  Happy reading!`,
};

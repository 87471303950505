import React, {useEffect, useState} from "react";
import {AboutParaTypo, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {MemberApi} from "../../../http/allAdminApi";
import {Backdrop, Checkbox, CircularProgress, InputBase,} from "@mui/material";
import moment from "moment";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {Modal} from "antd";

function AdminMembers() {
  const [rowsData, setRowsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [mailId, setmailId] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [modelPd, setModelpd] = useState(false);
  const [planDet, setPlanDet] = useState();
  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = rowsData?.filter((item) => {
      // return (
      //   item.id.toString().includes(value) ||
      //   item.fullName.toLowerCase().includes(value.toLowerCase()) ||
      //   item.email.toLowerCase().includes(value.toLowerCase()) ||
      //   item.city.toLowerCase().includes(value.toLowerCase()) ||
      //   item.mobile.toLowerCase().includes(value.toLowerCase())
      // );
      const lowerCaseValue = value.toLowerCase();
      return (
        (item.id && item.id.toString().includes(value)) ||
        (item.fullName &&
          item.fullName.toLowerCase().includes(lowerCaseValue)) ||
        (item.email && item.email.toLowerCase().includes(lowerCaseValue)) ||
        (item.city && item.city.toLowerCase().includes(lowerCaseValue)) ||
        (item.mobile && item.mobile.toLowerCase().includes(lowerCaseValue))
      );
    });
    setResData(filteredResults);
  };

  const handleMembers = () => {
    MemberApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setRowsData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };

  useEffect(() => {
    handleMembers();
  }, []);

  const handleCheck = (value) => {
    const index = mailId.indexOf(value.email);
    if (index === -1) {
      setmailId((prevState) => [...prevState, value.email]);
    } else {
      setmailId((prevState) =>
        prevState.filter((email) => email !== value.email),
      );
    }
  };
  const handleSend = (val) => {
    if (val === "single") {
      const singleEmails = mailId.map((row) => row).join(";");
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${singleEmails}`,
        `_blank`,
      );
    } else {
      const multipleEmails = rowsData.map((row) => row.email).join(";");
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${multipleEmails}`,
        `_blank`,
      );
    }
  };
  const handleNavigator = () => {
    setModelpd(false);
  };

  const columns = [
    {
      field: "1",
      headerName: "Select",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <Checkbox
            onChange={() => handleCheck(params.row)}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      hide: true,
    },
    {
      field: "invoicedate",
      headerName: "Date of user registration ",
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return moment(params?.row?.rdate?.date).format("YYYY- MM- DD");
      },
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem",
            }}
          >
            <AboutParaTypo variant="p">
              {moment(params?.row?.rdate?.date).format("DD - MM - YYYY")}
            </AboutParaTypo>
          </div>
        );
      },
    },
    { field: "fullName", headerName: "Full Name", flex: 1, hide: true },
    {
      field: "UserDate",
      headerName: "Plan Detials",
      flex: 2,
      renderCell: (params) => {
        const handleClick = () => {
          setModelpd(true);
          setPlanDet(params?.row);
        };

        return (
          <div>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              Subscription Details
            </div>
          </div>
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      flex: 3,
      hide: true,
    },
    { field: "mobile", headerName: "Mobile", flex: 1, hide: true },
    { field: "city", headerName: "City", flex: 1, hide: true },
  ];

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Members
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "1rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <div
          style={{ marginTop: "1rem", display: "flex", justifyContent: "end" }}
        >
          <div>
            <StyledGreenButton
              style={{ textTransform: "none" }}
              onClick={() => handleSend("single")}
              disabled={mailId.length === 0}
            >
              Email selected users
            </StyledGreenButton>
          </div>
          <div style={{ marginLeft: "1rem", position: "relative" }}>
            <StyledGreenButton
              style={{
                backgroundColor: "orangered",
                textTransform: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => handleSend("multiple")}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Email all user
            </StyledGreenButton>
            {showTooltip && (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "gray",
                  color: "#fff",
                  borderRadius: "4px",
                  width: "150px",
                  textAlign: "center",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "999",
                }}
              >
                If you send all mail click here
              </div>
            )}
          </div>
        </div>

        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
      <div>
        <Modal
          open={modelPd}
          onOk={handleNavigator}
          onCancel={handleNavigator}
          footer={null}
        >
          {planDet && (
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "4px",
                outline: "none",
              }}
            >
              <h2 style={{ marginBottom: "2rem" }}>Subscription Details</h2>
              {planDet?.userPlan?.map((plan, index) => (
                <div key={index}>
                  <p>
                    <strong
                      style={{
                        color:
                          moment(plan.expiry) < moment() ? "red" : "inherit",
                      }}
                    >
                      Plan Name:
                    </strong>{" "}
                    {plan.name}
                  </p>
                  <p>
                    <strong
                      style={{
                        color:
                          moment(plan.expiry) < moment() ? "red" : "inherit",
                      }}
                    >
                      Subscription Date:
                    </strong>{" "}
                    {moment(plan.subscriptionDate).format("DD-MM-YYYY")}
                  </p>
                  <p>
                    <strong
                      style={{
                        color:
                          moment(plan.expiry) < moment() ? "red" : "inherit",
                      }}
                    >
                      Expiry Date:
                    </strong>{" "}
                    {moment(plan.expiry).format("DD-MM-YYYY")}
                  </p>
                  {/* {moment(plan.expiry) < moment() && ( */}
                  <hr style={{ borderTop: "1px solid gray" }} />
                  {/* )} */}
                </div>
              ))}
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default AdminMembers;

import React, {useEffect, useState} from "react";
import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import constantValues from "../../Common/ConstantValues";
import {Backdrop, Button, CircularProgress, InputBase, Menu, MenuItem,} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import {InvoiceAPi} from "../../../http/allAdminApi";
import moment from "moment";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";

function AdminInvoice() {
  const [invoice, setInvoice] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const person = JSON.parse(sessionStorage.getItem("person"));
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = invoice?.filter((item) => {
      return (
        item.id.toString().includes(value) ||
        item.email.toLowerCase().includes(value.toLowerCase()) ||
        item.mobile.toLowerCase().includes(value.toLowerCase()) ||
        item.remarks.toLowerCase().includes(value.toLowerCase())
      );
    });
    setResData(filteredResults);
  };

  const handleView = () => {
    setAnchorEl(null);
    let dataUrl = window.btoa(paramData?.id + "userId" + person?.id);
    let pdfurl =
      constantValues.PGNEET_PROD_URL + "/auth/invoicepdf?id=" + dataUrl;
    window.open(pdfurl, "_blank");
  };

  const handleInvoive = () => {
    InvoiceAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setInvoice(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };

  useEffect(() => {
    handleInvoive();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 60, hide: true },
    {
      field: "invoicedate",
      headerName: "Date of Purchase ",
      flex: 1,
      hide: true,
      valueGetter: (params) => {
        return moment(params?.row?.invoicedate?.date).format("YYYY- MM- DD");
      },
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              padding: "1rem",
            }}
          >
            <AboutParaTypo variant="p">
              {moment(params?.row?.invoicedate?.date).format("YYYY - MM - DD")}
            </AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      hide: true,
    },
    { field: "mobile", headerName: "Mobile", flex: 1, hide: true },
    {
      field: "remarks",
      headerName: "Payment reference",
      flex: 2,
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.7,
      valueGetter: (params) => {
        return params.row.status ? "Paid" : "Pending";
      },
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              // padding: "3px",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Paid" : "Pending"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.7,
      // align: "right",
      // hide: true,
      // valueGetter: (params) => {
      //   return params?.row?.amount.toFixed(2);
      // },
      renderCell: (params) => {
        return (
          <AboutParaTypo variant="body2">
            {params?.row?.amount.toFixed(2)}
          </AboutParaTypo>
        );
      },
    },
    {
      field: "age",
      headerName: "Action",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Invoices
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "1rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid
          loading={loading}
          exportOpt={true}
          columns={columns}
          rows={resData}
        />
      </div>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={handleView}>view PDF</MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminInvoice;

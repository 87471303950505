import {Box, Divider, Typography, useTheme} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {quizQuestions} from "../../staticData/quizStaticData";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {StyledButton} from "../../styledcomponents/styledQBExamWidgets";
import Counter from "../widgets/Timer";
import ScoreBoard from "./ScoreBoard";
import {saveAndExitApi} from "../../../http/allApis";

function TimeScoreBoard({
  isSmall,
  referid,
  timeDuration,
  scoredata: scoreData,
  type,
  mockDetails,
  handleTestCompleted,
  getMockAttendedQues,
  getTimedTest,
}) {
  const theme = useTheme();
  const [totalScore, setTotalCourse] = useState(0);
  const [balanceseconds, setBalanceSeconds] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    let markScore = scoreData.filter((obj) => obj.mark === 1);
    let finalScore = Math.round(
      (markScore.length / quizQuestions.length) * 100,
    );
    setTotalCourse(finalScore);
  }, []);

  const saveandexit = () => {
    let payload = {
      timebalance: balanceseconds,
      referid: referid,
    };
    saveAndExitApi(payload, type)
      .then(function (response) {
        if (response.data.result === true) {
          if (type === "mock") {
            navigate("/student/mocktest", {
              state: {
                mockDetails: mockDetails,
              },
            });
          } else {
            navigate("/student/qbexam");
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "end",
        flexDirection: "column",
      }}
    >
      {isSmall ? (
        <div
          style={{
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <Typography variant="body2" style={{ display: "flex" }}>
            Time Left : &nbsp;
            <b>
              <Counter
                handleTestCompleted={handleTestCompleted}
                setBalanceSeconds={(val) => setBalanceSeconds(val)}
                timeDuration={timeDuration}
              />
            </b>
          </Typography>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <StyledButton variant="contained" onClick={saveandexit}>
              Save and Exit
            </StyledButton>
          </div>
        </div>
      ) : (
        <div>
          <Box
            style={{
              border: "1.5px solid #EBECF0",
              borderRadius: "6px",
              marginTop: ".5rem",
              width: "15rem",
              textAlign: "center",
            }}
          >
            <div
              style={{
                padding: ".5rem",
                backgroundColor:
                  theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7",
              }}
            >
              <Typography variant="body2" fontWeight={600} marginLeft={1}>
                Timed Test
              </Typography>
            </div>
            <Divider />
            <div
              style={{
                margin: ".3rem",
                border: "1px solid #f1f1f1",
                padding: "1rem",
              }}
            >
              <Typography variant="body1" paragraph>
                <Counter
                  handleTestCompleted={handleTestCompleted}
                  setBalanceSeconds={(val) => setBalanceSeconds(val)}
                  timeDuration={timeDuration}
                />
              </Typography>
              <StyledGreenButton variant="contained" onClick={saveandexit}>
                Save and Exit
              </StyledGreenButton>
            </div>
          </Box>
          <ScoreBoard
            totalScore={totalScore}
            type={type}
            scoreData={scoreData}
            getMockAttendedQues={getMockAttendedQues}
            getTimedTest={getTimedTest}
          />
        </div>
      )}
    </div>
  );
}

export default TimeScoreBoard;

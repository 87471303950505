import {Grid, useMediaQuery, useTheme,} from "@mui/material";
import React, {useState} from "react";
import ScoreBoard from "./quiz/ScoreBoard";
import TimeScoreBoard from "./quiz/TimeScoreBoard";
import {useLocation, useNavigate} from "react-router-dom";
import {resultDataApi, testCompletedApi,} from "../../http/allApis";
import {QBScoreEndButton} from "../styledcomponents/styledQBExamWidgets";
import QuestionPage from "./quiz/QuestionPage";

function QuizPage({ type }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [totalScore, setTotalCourse] = useState(0);
  const { state } = useLocation();
  const questiondata = state?.questionIds || [];
  const qdStartNumber = state?.startnumber || [];
  const [scoredata, setScoredata] = useState(state?.scoredata || []);
  const mydropvalue = state?.dropvalue || 1;
  const referid = state?.referid || 0;
  const pageFor = state?.pageFor;
  const reviewFor = state?.reviewFor;
  const timeDuration = state?.timeDuration || 0;
  const mockDetails = state?.mockDetails;
  const planId = state?.planId;
  const [notAttendQues, setnotAttendQues] = useState();
  const [scoreClick, setscoreClick] = useState("");
  const [mockSelectQues, setMockSelectQues] = useState();
  const [flagVal, setFlagVal] = useState();
  const handleTestCompleted = () => {
    let payload = {
      referid: referid,
    };
    testCompletedApi(payload, type)
      .then((res) => {
        if (type === "mock") {
          handleReview("mock");
        } else {
          handleReview("timed-test");
        }
      })
      .catch((e) => {});
  };
  const handleReview = (val) => {
    if (
      (pageFor === "review" && reviewFor === "timed-test") ||
      (pageFor === "review" && reviewFor === "mock")
    ) {
      let payload = {
        referid: referid,
      };
      resultDataApi(payload, reviewFor)
        .then((res) => {
          navigate("/student/review", {
            state: {
              referid: referid,
              reviewFor: reviewFor,
              planId: planId,
            },
          });
        })
        .catch(() => {});
    } else if (reviewFor === "overall") {
      navigate("/student/review", { state: { planId: planId } });
    } else {
      navigate("/student/review", {
        state: {
          reviewFor: val,
          scoreData: scoredata,
          referid: referid,
          planId: planId,
        },
      });
    }
  };

  const getnotAttendQues = (backId, flag, click) => {
    setnotAttendQues(backId);
    setscoreClick(click);
    setFlagVal(flag);
  };

  const getmockAttendedQues = (backId, flag) => {
    setMockSelectQues(backId);
    setFlagVal(flag);
    setscoreClick("mockscoredata");
  };

  const getTimedTest = (backId, flag) => {
    setMockSelectQues(backId);
    setFlagVal(flag);
  };

  return (
    <div>
      {isSmall && type != "quiz" ? (
        <TimeScoreBoard
          isSmall={isSmall}
          referid={referid}
          timeDuration={timeDuration}
          scoredata={scoredata}
          type={type}
          mockDetails={mockDetails}
          handleTestCompleted={handleTestCompleted}
          getMockAttendedQues={getmockAttendedQues}
          getTimedTest={getTimedTest}
        />
      ) : null}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={8}>
          <div>
            <QuestionPage
              scoredata={scoredata}
              setScoredata={(val) => setScoredata(val)}
              referid={referid}
              questiondata={questiondata}
              qdStartNumber={qdStartNumber}
              type={type}
              mydropvalue={mydropvalue}
              setTotalCourse={(val) => setTotalCourse(val)}
              pageFor={pageFor}
              reviewFor={reviewFor}
              notAttendQues={notAttendQues}
              scoreClick={scoreClick}
              mockSelectQues={mockSelectQues}
              flagVal={flagVal}
              planId={planId}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          {type === "quiz" || pageFor === "review" ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <QBScoreEndButton onClick={() => handleReview("quiz")}>
                  {pageFor === "review" ? "Go to result" : "End and review"}
                </QBScoreEndButton>
              </div>
              <ScoreBoard
                totalScore={totalScore}
                type={type}
                scoreData={scoredata}
                questiondata={questiondata}
                mydropvalue={mydropvalue}
                getnotAttendQues={getnotAttendQues}
                getMockAttendedQues={getmockAttendedQues}
                flagVal={flagVal}
              />
            </div>
          ) : (
            <div>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <QBScoreEndButton onClick={handleTestCompleted}>
                  Finish and Mark
                </QBScoreEndButton>
              </div>
              {isSmall ? null : (
                <div>
                  <TimeScoreBoard
                    type={type}
                    handleTestCompleted={handleTestCompleted}
                    referid={referid}
                    timeDuration={timeDuration}
                    scoredata={scoredata}
                    mockDetails={mockDetails}
                    getMockAttendedQues={getmockAttendedQues}
                    getTimedTest={getTimedTest}
                    flagVal={flagVal}
                  />
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default QuizPage;

import React, {useState} from "react";
import {BlogArticalDiv, TransparentPara,} from "../styledcomponents/beforeLoginWidgets";
import {Alert, Avatar, CardMedia, Divider, Grid, Snackbar, TextField, Typography,} from "@mui/material";
import {StyledGreenButton} from "../styledcomponents/styledDBwidgets";
import moment from "moment";
import {addBlogComment} from "../../http/allApis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTelegram, faWhatsapp, faXTwitter,} from "@fortawesome/free-brands-svg-icons";
import {useNavigate} from "react-router-dom";
import "./blog.css";
import {faCopy,} from "@fortawesome/free-solid-svg-icons";
import {useSharedValue} from "../Common/SharedValueContext";

function ArticleFile({ articleData, setReadmoreBoolen }) {
  const [valueCom, setValueCom] = useState({
    comment: "",
    name: "",
    email: "",
    website: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const { sharedValue } = useSharedValue();
  // const [sharedValue, setSharedValue] = useState({});
  const [isCopied, setIsCopied] = useState(false);

  // useEffect(() => {
  //   // if(articleData!==undefined&&articleData!==null){

  //   // }
  //   let payload = {
  //     id: articleData?.id
  //   };
  //   blogDetailApi(payload).then((res) => {
  //     console.log(res.data.data);
  //     setSharedValue(res.data.data);
  //   });
  // }, [articleData]);
  const navigate = useNavigate();

  const handleBack = () => {
    setReadmoreBoolen(true);
    navigate("/blog");
  };
  const telegramURL =
    "https://t.me/share/url?url=https://www.pgneet.net/blog&text=" +
    sharedValue.title;
  const whatsappURL =
    "https://api.whatsapp.com/send?phone=+91-99402 50516" +
    "&text=" +
    sharedValue.title;
  const twitterURL =
    "https://twitter.com/intent/tweet?url={url}&text={title}&via={user_id}&hashtags={hash_tags}";

  const handleCommentvalue = (e, cond) => {
    if (cond === "comment") {
      let objValue = { ...valueCom };
      objValue.comment = e.target.value;
      setValueCom(objValue);
    } else if (cond === "email") {
      let objValue = { ...valueCom };
      objValue.email = e.target.value;
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]/.test(objValue.email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      setValueCom(objValue);
    } else if (cond === "name") {
      let objValue = { ...valueCom };
      objValue.name = e.target.value;
      setValueCom(objValue);
    } else if (cond === "website") {
      let objValue = { ...valueCom };
      objValue.website = e.target.value;
      setValueCom(objValue);
    }
  };
  const handleApiClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    addBlogComment(valueCom, sharedValue?.id)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setValueCom({
            comment: "",
            name: "",
            email: "",
            website: "",
          });
          setSuccessMsg(res?.data?.message);
          setAlertstatus("success");
          setOpen(true);
          window.scroll(0, 0);
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /* const [likeCount, setLikeCount] = useState(501234);
  const [dislikeCount, setDislikeCount] = useState(253252);*/

  const [activeBtn, setActiveBtn] = useState("none");

  /*const handleLikeClick = () => {
    if (activeBtn === "none") {
      setLikeCount(likeCount + 1);
      setActiveBtn("like");
      return;
    }

    if (activeBtn === 'like'){
      setLikeCount(likeCount - 1);
      setActiveBtn("none");
      return;
    }

    if (activeBtn === "dislike") {
      setLikeCount(likeCount + 1);
      setDislikeCount(dislikeCount - 1);
      setActiveBtn("like");
    }
  };

  const handleDisikeClick = () => {
    if (activeBtn === "none") {
      setDislikeCount(dislikeCount + 1);
      setActiveBtn("dislike");
      return;
    }

    if (activeBtn === 'dislike'){
      setDislikeCount(dislikeCount - 1);
      setActiveBtn("none");
      return;
    }

    if (activeBtn === "like") {
      setDislikeCount(dislikeCount + 1);
      setLikeCount(likeCount - 1);
      setActiveBtn("dislike");
    }
  };*/

  const Comment = ({ fullName, message }) => (
    <>
      <div
        style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        <Avatar>{fullName.charAt(0)}</Avatar>
        <div style={{ marginLeft: ".5rem" }}>
          <div style={{ margin: "0", fontWeight: "bold", fontSize: "15px" }}>
            {fullName}
          </div>
          <div style={{ margin: "0 1rem", fontSize: "12px" }}>{message}</div>
        </div>
      </div>
    </>
  );
  const copyUrlToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
  };
  const handleIcon = (cond) => {
    if (cond === "whatapp") {
      const sharedMessage = ` Blog heading: ${sharedValue?.title} - Click this link: ${window.location.href}`;
      window.open(
        `https://web.whatsapp.com/send?text=${encodeURIComponent(
          sharedMessage,
        )}`,
      );
    } else if (cond === "facebook") {
      const sharedMessage = `${sharedValue?.title} - ${window.location.href}`;
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=https://www.pgneet.net/blog/article/29`,
        // ${encodeURIComponent(sharedMessage)}`
      );
      // window.open(`https://www.facebook.com/sharer/sharer.php?u=pgneet.net`);
    } else if (cond === "instgram") {
      window.open("https://www.instagram.com/pgneet_net/");
    } else if (cond === "twitter") {
      window.open("https://twitter.com/pgneet_net");
    }
  };
  return (
    <div>
      <BlogArticalDiv>
        <h1>{sharedValue?.title}</h1>
        <Typography sx={{ fontSize: 11 }} color="text.secondary" gutterBottom>
          {/* By {sharedValue?.author},{" "} */}
          {/* {moment
            .utc(sharedValue?.createdDate?.toString())
            .local()
            .startOf("seconds")
            .format("MMMM Do YYYY")} */}
        </Typography>

        <CardMedia component="img" image={sharedValue.titleImage} />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TransparentPara
              style={{ marginTop: "2rem" }}
              dangerouslySetInnerHTML={{ __html: sharedValue?.description }}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            backgroundColor: "#d3d3d3",
          }}
        />
        <span style={{ fontSize: "13px", padding: "0.4rem 0.4rem 0 0" }}>
          By &nbsp;<span style={{ color: "blue" }}>{sharedValue?.author}</span>{" "}
          &nbsp;|&nbsp;
          <span>
            {moment
              .utc(sharedValue?.createdDate?.toString())
              .local()
              .startOf("seconds")
              .format("MMMM Do YYYY")}
          </span>
          &nbsp; |&nbsp;{" "}
          <span style={{ color: "blue" }}>{sharedValue?.title}</span>
        </span>
        <Divider
          sx={{
            backgroundColor: "#d3d3d3",
          }}
        />
        <div
          style={{ marginTop: "1rem", display: "flex", justifyItems: "end" }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={0.9}
              style={{ fontSize: "15px", fontWeight: "bold" }}
            >
              Share{" "}
            </Grid>

            <Grid item xs={7}>
              <FontAwesomeIcon
                icon={faCopy}
                style={{
                  margin: "0 .25rem",
                  width: 20,
                  height: 20,

                  cursor: "pointer",
                }}
                onClick={copyUrlToClipboard}
              />
              {/* <FontAwesomeIcon
                icon={faFacebook}
                style={{ margin: "0 .25rem", width: 20, height: 20 }}
              /> */}
              {/* <FacebookShareButton url={window.location.href}>
                <FacebookIcon
                  size={30}
                  round
                  style={{ margin: "0 .25rem", cursor: "pointer" }}
                  onClick={() => handleIcon("facebook")}
                />
              </FacebookShareButton> */}
              <FontAwesomeIcon
                icon={faFacebook}
                // icon={faInstagram}
                style={{ margin: "0 .25rem", width: 20, height: 20 }}
                onClick={() => handleIcon("facebook")}
              />
              <FontAwesomeIcon
                icon={faInstagram}
                style={{
                  margin: "0 .25rem",
                  width: 20,
                  height: 20,
                  cursor: "pointer",
                }}
                onClick={() => handleIcon("instgram")}
              />
              <FontAwesomeIcon
                icon={faXTwitter}
                onClick={() => handleIcon("twitter")}
                style={{
                  margin: "0 .25rem",
                  width: 20,
                  height: 20,
                  cursor: "pointer",
                }}
              />
              <FontAwesomeIcon
                icon={faTelegram}
                url={telegramURL}
                style={{ margin: "0 .25rem", width: 20, height: 20 }}
              />
              {/* <WhatsappShareButton> */}
              <FontAwesomeIcon
                icon={faWhatsapp}
                style={{
                  margin: "0 .25rem",
                  cursor: "pointer",
                  width: 20,
                  height: 20,
                }}
                onClick={() => handleIcon("whatapp")}
                // style={{
                //   margin: "0 .25rem",
                //   width: 20,
                //   height: 20,
                //   cursor: "pointer"
                // }}
              />
              {/* </WhatsappShareButton> */}
            </Grid>
            {/*<Grid item xs={4}>
              <div style={{textAlign: "end"}}>
                <FontAwesomeIcon icon={faThumbsUp} onClick={handleLikeClick}/>
                <span style={{padding: "0 0.5rem"}}> {likeCount}</span>
                <FontAwesomeIcon icon={faThumbsDown} onClick={handleDisikeClick}/>
                <span style={{padding: "0 0.5rem"}}> {dislikeCount}</span>
              </div>
            </Grid>*/}
          </Grid>
        </div>
        {isCopied && (
          <span
            style={{
              color: "rgb(243, 132, 36)",
              fontWeight: "500",
              fontSize: "20px",
            }}
          >
            Copied!
          </span>
        )}

        <div style={{ marginTop: "1rem" }}>
          {/* {sharedValue?.approvedComments?.lenght !== 0 ? ( */}
          <div style={{ fontSize: "20px", marginBottom: ".5rem" }}>
            Comments:
          </div>
          {/* ) : (
             <div style={{ fontSize: "20px" }}>No Comments:</div>
           )} */}
          {sharedValue?.approvedComments?.map((comment, index) => (
            <Comment
              key={index}
              fullName={comment?.fullName}
              message={comment?.message}
            />
          ))}
        </div>

        <div style={{ fontSize: "20px", marginTop: "2rem" }}>
          Leave a Comment
        </div>
        <div style={{ marginTop: ".5rem" }}>
          <textarea
            style={{
              width: "100%",
              height: "150px",
              borderColor: "gray",
              padding: "10px",
            }}
            name="body"
            placeholder="Write a Comments........"
            in
            onChange={(e) => handleCommentvalue(e, "comment")}
            value={valueCom.comment}
          />
          <div style={{ marginTop: "1rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Email-Id*"
                  size="Normal"
                  fullWidth
                  value={valueCom.email}
                  onChange={(e) => handleCommentvalue(e, "email")}
                />
                {valueCom.email !== "" && emailError ? (
                  <span style={{ color: "red", fontSize: "13px" }}>
                    Please enter vaild email Id*
                  </span>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Name*"
                  size="Normal"
                  fullWidth
                  value={valueCom.name}
                  onChange={(e) => handleCommentvalue(e, "name")}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Website"
                  size="Normal"
                  fullWidth
                  value={valueCom.website}
                  onChange={(e) => handleCommentvalue(e, "website")}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            style={{
              marginTop: "2rem",
              outline: "none",
            }}
            onClick={handleBack}
          >
            Back{" "}
          </StyledGreenButton>
          <StyledGreenButton
            style={{
              marginTop: "2rem",
              outline: "none",
              marginLeft: "1rem",
            }}
            disabled={
              emailError ||
              valueCom.comment === "" ||
              valueCom.email === "" ||
              valueCom.name === ""
            }
            onClick={handleSubmit}
          >
            Posts Comment
          </StyledGreenButton>
        </div>
        {/*<div style={{backgroundColor: "#f9f9fb", padding: "20px", marginTop: "60px"}}>
              <h4 style={{fontSize: "28", lineHeight: "1.26"}}>Share This Story, Choose Your Platform!</h4>

          </div>*/}
      </BlogArticalDiv>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ArticleFile;

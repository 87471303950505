import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {CssBaseline} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {ToggleThemeProvider} from "./components/Common/ToggleThemeProvider";
import { Provider } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import {store} from "./components/redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <Provider store={store}> {/* Add Redux Provider here */}
        <ToggleThemeProvider>
          <BrowserRouter>
            <CssBaseline>
              <App />
            </CssBaseline>
          </BrowserRouter>
        </ToggleThemeProvider>
      </Provider>
  </React.StrictMode>,
);

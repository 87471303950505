import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from "@mui/material";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {RowResTypo, StyledTableCell, StyledTableRow,} from "../../styledcomponents/styledQuizWidgets";
import {infoDataTimedTestApi, mockSelectedQuestionsTTApi, resultDataApi,} from "../../../http/allApis";
import {formatTime} from "../../Common/formatTime";
import {List} from "antd";
import ModelFile from "../../landing/ModelFile";
import {useSelector} from "react-redux";

function PrevTimeTest({ type, subid, handleRow }) {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  // Access the plan validity data from Redux
  const planValidity = useSelector((state) => state.planValidity.selectedPlanData);
  useEffect(() => {
    let payload = {
      subid: subid,
      planId: planValidity?.id,
    };
    infoDataTimedTestApi(payload, type)
      .then((res) => {
        let mydata = res.data.result.map((item) => ({
          id: item?.id,
          date: item?.date.date,
          lot: item?.lot,
          noq: item?.noq,
          timebalance: item?.timebalance,
          status: item?.status,
          score: item?.score,
          overAllScore: item?.overAllScore,
        }));
        setRows(mydata);
        handleRow(mydata);
      })
      .catch((response) => {
        console.log(response);
        //   setModelWin(true);
        //   setCatchError(response?.response?.data?.message);
      });
  }, []);

  const handleContinue = (val) => {
    let payload = {
      referid: val?.id,
    };
    if (type === "mock") {
      mockSelectedQuestionsTTApi(payload, type)
        .then((res) => {
          let mydata = res?.data?.result?.map((item) => item?.id);
          navigate("/student/mock-test", {
            state: {
              questionIds: mydata,
              referid: val?.id,
              scoredata: res?.data?.scoredata,
              timeDuration: val.timebalance,
              componentIdentity: "preMockQuesStarted",
            },
          });
        })
        .catch(() => {});
    } else {
      mockSelectedQuestionsTTApi(payload, type)
        .then((res) => {
          let mydata = res?.data?.result?.map((item) => item?.id);
          navigate("/student/timed-test", {
            state: {
              questionIds: mydata,
              referid: val?.id,
              scoredata: res?.data?.scoredata,
              timeDuration: val.timebalance,
              componentIdentity: "pretimedTestStarted",
            },
          });
        })
        .catch(() => {});
    }
  };

  const handleReview = (val) => {
    let payload = {
      referid: val?.id,
    };
    resultDataApi(payload, type)
      .then((res) => {
        navigate("/student/review", {
          state: {
            referid: val?.id,
            reviewFor: type,
          },
        });
      })
      .catch(() => {});
  };

  return (
    <div>
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow
              sx={{
                borderTop: "2px solid #f1f1f1",
                borderBottom: "2px solid #f1f1f1",
              }}
            >
              <TableCell>Date</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Score</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length !== 0
              ? rows?.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      width={type === "mock" ? 130 : "auto"}
                    >
                      <RowResTypo variant="caption">
                        {moment(row?.date).format("Do MMM YY")}
                      </RowResTypo>
                    </StyledTableCell>
                    <StyledTableCell width={type === "mock" ? 200 : "auto"}>
                      {type === "mock" ? (
                        <RowResTypo variant="caption">
                          200 questions over 200 minutes Completed
                        </RowResTypo>
                      ) : (
                        <RowResTypo variant="caption">
                          {row.noq} questions over {row.lot} mins
                          <br />
                          <span>
                            <b>
                              {" "}
                              {row.status === 0 || row.status === 2
                                ? "In progress. " +
                                  formatTime(row.timebalance).minutes +
                                  ":" +
                                  formatTime(row.timebalance).seconds +
                                  " remaining"
                                : "Completed"}
                            </b>
                          </span>
                        </RowResTypo>
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      width={type === "mock" ? 130 : "auto"}
                    >
                      <RowResTypo variant="caption">
                        {" "}
                        <span style={{ color: "red" }}>
                          {row?.score} / {row?.overAllScore}
                        </span>
                      </RowResTypo>
                    </StyledTableCell>
                    <StyledTableCell width={type === "mock" ? 200 : "auto"}>
                      {row.status === 0 || row.status === 2 ? (
                        <RowResTypo
                          variant="caption"
                          onClick={() => handleContinue(row)}
                          sx={{ color: "blue", cursor: "pointer" }}
                        >
                          Continue{" "}
                        </RowResTypo>
                      ) : (
                        <RowResTypo
                          variant="caption"
                          onClick={() => handleReview(row)}
                          sx={{ color: "blue", cursor: "pointer" }}
                        >
                          Review{" "}
                        </RowResTypo>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : null}
          </TableBody>
        </Table>
        {rows.length === 0 ? (
          <List
            bordered
            style={{ width: "100%", borderRadius: "0" }}
            dataSource={[]}
          />
        ) : null}
      </TableContainer>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default PrevTimeTest;

import {Box, Grid, Typography, useTheme,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {offerContent} from "../Common/beforeLoginPageContents";
import {
    AboutBannerHead,
    AboutParaTypo,
    BlueContainerSection,
    ContentDiv,
    TransparentPara,
} from "../styledcomponents/beforeLoginWidgets";
import {StyledGreenButton} from "../styledcomponents/styledDBwidgets";
import {dashboardPricingAPi} from "../../http/allApis";

function QuestionBankHome() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [qbOffer, setQBoffer] = useState();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleOffer = (item) => {
    return item.examId === 1;
  };
  useEffect(() => {
    dashboardPricingAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data.filter(handleOffer);
          const firstThreeItems = data.slice(0, 3);
          setQBoffer(firstThreeItems);
          //   setHeadingList(res?.data?.data);
        }
      })
      .catch((e) => {
        // if (e?.response?.data?.status === 500) {
        //   setModelWin(true);
        //   setCatchError(e?.response?.data?.message);
        // } else {
        //   setLoading(false);
        // }
      });
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          Question Bank
        </AboutBannerHead>
      </BlueContainerSection>
      <ContentDiv>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Our Question Bank revision course packages offer access to High
                Yield notes and subject-based question banks with evidence-based
                answers and explanations. You have the flexibility to choose
                between 1, 3, and 6 months of access to these invaluable
                resources from the date of purchase.
              </AboutParaTypo>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Our Question Bank online revision course encompasses an
                ever-expanding bank of over 8000 questions, including numerous
                questions from previous exams. It comprehensively covers 19
                subjects of the revised curriculum: Anatomy, Physiology,
                Biochemistry, Pharmacology, Microbiology, Pathology, Forensic
                Medicine, Social and Preventive Medicine, Medicine, Dermatology
                and Venereology, Surgery, Orthopaedics, Anaesthesia,
                Radiodiagnosis and Radiotherapy, Obstetrics and Gynaecology,
                Paediatrics, ENT, Ophthalmology, and Psychiatry.
              </AboutParaTypo>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                All questions adhere to the format found in actual NEET PG and
                other Medical PG exams ('best answer 1 of 4' style MCQs) and are
                meticulously crafted based on both previous exam questions and
                the syllabus. We focus on core questions most likely to appear
                in the exams and consistently update our question bank to
                maintain the highest quality. To ensure you are well-prepared
                for exam conditions, we offer unlimited practice ‘Timed tests’
                at no additional cost, tailored to your preferences.
              </AboutParaTypo>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <AboutParaTypo variant="p">
                Prepare with confidence and efficiency with PGNEET©, your
                trusted partner in medical postgraduate entrance exam revision.
              </AboutParaTypo>
            </div>
            {/* <div>
              <List>
                {categoryList.map((item) => (
                  <ListItem sx={{ padding: "0 0 0 .5rem" }}>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <DoubleArrow
                        fontSize="small"
                        style={{ color: "#f38424" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <AboutParaTypo variant="p">{item}</AboutParaTypo>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div> */}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Box
              style={{
                border: "1.5px solid #EBECF0",
                borderRadius: "6px",
                marginTop: ".5rem",
              }}
            >
              <div
                style={{
                  padding: ".5rem",
                  backgroundColor:
                    theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7",
                }}
              >
                <Typography variant="body2" fontWeight={600} marginLeft={1}>
                  Question Bank Subscription options
                </Typography>
              </div>
              <div
                style={{
                  padding: ".3rem",
                }}
              >
                <div
                  style={{
                    border: "1px solid #EBECF0",
                    borderRadius: "4px",
                    padding: ".5rem",
                  }}
                >
                  {/* <List>
                    <ListItem sx={{ padding: ".5rem 0 .5rem 0" }}>
                      <AboutParaTypo1
                        variant="body2"
                        fontWeight={"bold"}
                      >{`QBank 1 month subscription ₹ 2,499`}</AboutParaTypo1>
                    </ListItem>
                    <AboutParaTypo1 variant="body2">{`(30 days validity from the time of purchase)`}</AboutParaTypo1>
                    <ListItem
                      sx={{ padding: ".5rem 0 .5rem 0" }}
                      style={{ marginTop: "1rem" }}
                    >
                      <AboutParaTypo1
                        variant="body2"
                        fontWeight={"bold"}
                      >{`QBank 3-month subscription ₹ 3,999`}</AboutParaTypo1>
                    </ListItem>
                    <AboutParaTypo1 variant="body2">{`(90 days validity from the time of purchase)`}</AboutParaTypo1>

                    <ListItem
                      sx={{ padding: ".5rem 0 .5rem 0" }}
                      style={{ marginTop: "1rem" }}
                    >
                      <AboutParaTypo1
                        variant="body2"
                        fontWeight={"bold"}
                      >{`QBank 6-month subscription ₹ 5,999`}</AboutParaTypo1>
                    </ListItem>
                    <AboutParaTypo1 variant="body2">{`(180 days validity from the time of purchase)`}</AboutParaTypo1>
                  </List> */}
                  {qbOffer?.map((item, index) => (
                    <div key={item.id}>
                      {index === 0 && (
                        <>
                          <TransparentPara fontWeight={"bold"}>
                            {item.offerAmount === 0 ? (
                              `QBank 1-month subscription ₹ ${item.amount}`
                            ) : (
                              <>
                                <s
                                  style={{
                                    marginBottom: ".3rem",
                                  }}
                                >
                                  <span>
                                    QBank 1-month subscription ₹ {item.amount}
                                  </span>
                                </s>
                                <br />
                                <span
                                  style={{ color: "#de1a24", fontSize: "13px" }}
                                >
                                  Offer :
                                </span>{" "}
                                QBank 1-month subscription ₹ {item.offerAmount}
                              </>
                            )}
                          </TransparentPara>
                          <TransparentPara
                            style={{ marginBottom: "1rem", fontSize: "13px" }}
                          >
                            {offerContent.content1.QBPricingFirst}
                          </TransparentPara>
                        </>
                      )}

                      {index === 1 && (
                        <>
                          <TransparentPara fontWeight={"bold"}>
                            {/* {`QBank 3-month subscription ₹ ${item.amount}`} */}
                            {item.offerAmount === 0 ? (
                              `QBank 3-month subscription ₹ ${item.amount}`
                            ) : (
                              <>
                                <s
                                  style={{
                                    marginBottom: ".3rem",
                                  }}
                                >
                                  <span>
                                    QBank 3-month subscription ₹ {item.amount}
                                  </span>
                                </s>
                                <br />
                                <span
                                  style={{ color: "#de1a24", fontSize: "13px" }}
                                >
                                  Offer :
                                </span>{" "}
                                QBank 3-month subscription ₹ {item.offerAmount}
                              </>
                            )}
                          </TransparentPara>
                          <TransparentPara
                            style={{ marginBottom: "1rem", fontSize: "13px" }}
                          >
                            {offerContent.content1.QBPricingSec}
                          </TransparentPara>
                        </>
                      )}
                      {index === 2 && (
                        <>
                          {/* <TransparentPara fontWeight={"bold"}>
                          {`QBank 6-month subscription ₹ ${item.amount}`}
                        </TransparentPara> */}
                          <TransparentPara fontWeight={"bold"}>
                            {/* {`QBank 3-month subscription ₹ ${item.amount}`} */}
                            {item.offerAmount === 0 ? (
                              `QBank 3-month subscription ₹ ${item.amount}`
                            ) : (
                              <>
                                <s
                                  style={{
                                    marginBottom: ".3rem",
                                  }}
                                >
                                  <span>
                                    QBank 6-month subscription ₹ {item.amount}
                                  </span>
                                </s>
                                <br />
                                <span
                                  style={{ color: "#de1a24", fontSize: "13px" }}
                                >
                                  Offer :
                                </span>{" "}
                                QBank 6-month subscription ₹ {item.offerAmount}
                              </>
                            )}
                          </TransparentPara>
                          <TransparentPara
                            style={{ marginBottom: "1rem", fontSize: "13px" }}
                          >
                            {offerContent.content1.QBPricingThird}
                          </TransparentPara>
                        </>
                      )}
                    </div>
                  ))}
                  <StyledGreenButton
                    style={{
                      marginTop: "1rem",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    onClick={() => navigate("/login", { state: "signup" })}
                  >
                    Subscribe to QBank
                  </StyledGreenButton>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </ContentDiv>
    </div>
  );
}

export default QuestionBankHome;

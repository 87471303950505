import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    useTheme,
} from "@mui/material";
import {Search,} from "@mui/icons-material";
import {blogUsersCount, dashboardBlogHeading, dashboardBlogMonth,} from "../../http/allAdminApi";
import "./blog.css";
import {addBlogEmail} from "../../http/allApis";

export const BlogSearchBar = ({
  handleHeadClick,
  setBlogMC,
  handleWelcome,
}) => {
  const [search, setSearch] = useState("");
  const [blogHeading, setBlogHeading] = useState([]);
  const [blogMonthCount, setBlogMonthCount] = useState([]);
  const [archives, setArchives] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState("");
  const [blogUsrCount, setBlogUsrCount] = useState(0);
  useEffect(() => {
    blogUsersCount()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setBlogUsrCount(res?.data?.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    dashboardBlogHeading()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setBlogHeading(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    dashboardBlogMonth()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setBlogMonthCount(res.data.data);
          // Function to filter months with count greater than zero
          const filterMonths = (data) => {
            const t = [];
            data.forEach((item) => {
              const year = item.year;
              item.month.forEach((monthData) => {
                monthData["year"] = year;
                if (monthData.count > 0) {
                  t.push(monthData);
                }
              });
            });
            setArchives(t);
          };
          // Convert JSON data to tree structure with months having count greater than zero
          const treeData = filterMonths(res.data.data);
          // Print tree structure
          // console.log(treeData);
          setBlogMC(blogMonthCount);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const filteredHeadings = blogHeading.filter((bhValue) =>
    bhValue.blogHeading
      .replace(/\s/g, "")
      .toLowerCase()
      .includes(search.replace(/\s/g, "").toLowerCase()),
  );

  const categoryClick = (value, barType, index) => {
    setSelectedIndex(index);
    handleHeadClick(value, barType, false);
  };
  const handleAddBlogEmail = () => {
    addBlogEmail(email)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setEmail("");
          setMsg(
            "Your subscription was successfully registered. Kindly check your mailbox and confirm your subscription. If you don`t see the email within a few minutes, check spam/junk folder.",
          );
          setErr("#00c04b");
        } else {
          setMsg("Email already registered.");
          setErr("red");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleEmail = (e) => {
    var emailValue = e.target.value;
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]/.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
    setEmail(emailValue);
  };

  const ColoredLine = () => (
    <hr
      style={{
        margin: 0,
        color: theme.palette.mode === "dark" ? "#1f2129d9" : "#fff",
        backgroundColor: theme.palette.mode === "dark" ? "#1f2129d9" : "#fff",
        height: 3,
      }}
    />
  );

  return (
    <Box
      sx={{
        padding: "0 20px 10px 25px",
        width: "100%",
        // maxWidth: 300,
        height: "100%",
        backgroundColor: theme.palette.mode === "dark" ? "#1f2129d9" : "#fff",
        minHeight: "100vh",
      }}
    >
      <div className="form-group" style={{ marginBottom: "1rem" }}>
        <span
          style={{
            color: "rgb(243, 132, 36)",
            fontWeight: "500",
            fontSize: "20px",
            cursor: "pointer",
          }}
          onClick={handleWelcome}
        >
          Welcome
        </span>
      </div>

      <div className="form-group" style={{ marginBottom: "1rem" }}>
        <section>
          <div
            style={{
              paddingRight: "0.5em",
              fontSize: "medium",
            }}
          >
            {/* Add your email here and get notified of every new blog post. */}
            Enter your email address to receive blogs posts by email.
          </div>
          <div>
            <label style={{ fontSize: "medium" }}>
              {/* Email* */}
              <br />
              <div
                style={{
                  color: err,
                  fontWeight: "bold",
                  fontSize: "12px",
                  margin: "5px 0 5px 0",
                }}
              >
                {msg}
              </div>
              {email !== "" && emailError ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  Please enter vaild email Id*
                </span>
              ) : null}
              <OutlinedInput
                fullWidth
                style={{
                  background: "#fff",
                  color: "#333",
                  padding: "0 12px",
                  outline: "0 !important",
                  fontSize: "14px !important",
                  height: "36px",
                  minHeight: "0",
                  lineHeight: "1",
                  borderRadius: "5px",
                }}
                autoComplete="off"
                id="outlined-adornment-emailid"
                type="text"
                placeholder="Email ID"
                value={email}
                onChange={handleEmail}
              />
              <Button
                variant="outlined"
                size="small"
                style={{
                  margin: "1rem 0",
                }}
                onClick={handleAddBlogEmail}
              >
                Submit
              </Button>
              <ColoredLine />
              <div
                style={{
                  padding: "0.5rem 0",
                  fontSize: "medium",
                }}
              >
                Join {blogUsrCount.toLocaleString()} other subscribers
              </div>
              <ColoredLine />
            </label>
          </div>
        </section>
      </div>
      <div className="form-group" style={{ marginBottom: "1rem" }}>
        <span
          style={{
            color: "rgb(243, 132, 36)",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Categories
        </span>
        {/*#31a188*/}
      </div>
      <div style={{ marginBottom: "1rem" }}>
        {filteredHeadings.map((bhValue, index) => (
          <List component="nav" sx={{ p: "0px" }}>
            <ListItem
              sx={{
                pl: "0px",
                pt: "0px",
                pb: "0px",
                "& .Mui-root": {
                  color: "#31a188",
                  "&.Mui-selected": {
                    paddingLeft: "0px",
                  },
                },
                "&.Mui-selected": {
                  color: theme.palette.mode === "dark" ? "#fff" : "black",
                  // fontWeight: "bold",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1f2129d9" : "transparent",
                },
                "&.Mui-focusVisible": {
                  color: "#2e8b57",
                },
                ":hover": {
                  color: "rgb(243, 132, 36)",
                  cursor: "pointer",
                },
              }}
              selected={selectedIndex === index}
              onClick={() => categoryClick(bhValue, "blogSearchBar", index)}
            >
              <ListItemText>{bhValue.blogHeading}</ListItemText>
            </ListItem>
          </List>
        ))}
      </div>
      <div className="form-group" style={{ marginBottom: "1rem" }}>
        <span
          style={{
            color: "rgb(243, 132, 36)",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Search Archives
        </span>
      </div>
      <div className="form-group" style={{ marginBottom: "1rem" }}>
        <OutlinedInput
          fullWidth
          style={{
            background: "#fff",
            color: "#333",
            padding: "0 12px",
            margin: "0",
            outline: "0 !important",
            fontSize: "14px !important",
            height: "36px",
            minHeight: "0",
            lineHeight: "1",
            borderRadius: "5px",
          }}
          id="outlined-adornment-password"
          type="text"
          placeholder="Search here..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      <div className="form-group" style={{ marginBottom: "1rem" }}>
        <span
          style={{
            color: "rgb(243, 132, 36)",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Archives
        </span>
      </div>
      <div className="scroll-container">
        {archives.map((monthData, index) => (
          <List key={index} component="nav" sx={{ p: "0px" }}>
            <ListItem
              key={index}
              sx={{
                pl: "0px",
                pt: "0px",
                pb: "0px",
                "& .Mui-root": {
                  color: "#31a188",
                  "&.Mui-selected": {
                    paddingLeft: "0px",
                  },
                },
                "&.Mui-selected": {
                  color: "rgb(243, 132, 36)",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1f2129d9" : "#fff",
                },
                "&.Mui-focusVisible": {
                  color: "#2e8b57",
                },
                ":hover": {
                  color: "rgb(243, 132, 36)",
                  cursor: "pointer",
                },
              }}
              selected={selectedIndex === index + 1}
              onClick={(event) =>
                handleHeadClick(monthData, monthData.year, "blogMonth")
              }
            >
              <ListItemText>
                {monthData.month}-{monthData?.year}
                {""}
                <span style={{ fontWeight: "bold" }}> ({monthData.count})</span>
              </ListItemText>
            </ListItem>
          </List>
        ))}
      </div>
    </Box>
  );
};

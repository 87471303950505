import {Box, Divider, MenuItem, Select, Typography, useTheme,} from "@mui/material";
import React, {useState} from "react";

function NoOfQuestionSelect({ callBack }) {
  const [noOfQuestion, setnoOfQuestion] = useState(10);
  const numArray = [200, 150, 100, 50, 30, 10];
  // const numArray = [10, 50, 75, 100, 125, 150, 175, 200];
  const theme = useTheme();

  const handleChange = (e) => {
    setnoOfQuestion(e.target.value);
    callBack(e.target.value);
  };

  return (
    <Box
      style={{
        border: "1.5px solid #EBECF0",
        borderRadius: "6px",
        marginTop: ".5rem",
      }}
    >
      <div
        style={{
          padding: ".5rem",
          backgroundColor:
            theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7",
        }}
      >
        <Typography variant="body2" fontWeight={600} marginLeft={1}>
          Number of questions:
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: ".3rem",
        }}
      >
        <Select
          size="small"
          fullWidth
          value={noOfQuestion}
          type="number"
          sx={{ padding: 0 }}
          onChange={handleChange}
        >
          {numArray.map((num) => (
            <MenuItem value={num}>{num} questions</MenuItem>
          ))}
        </Select>
      </div>
    </Box>
  );
}

export default NoOfQuestionSelect;

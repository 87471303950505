import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: null, // To store the authentication token
        tokenType: null, // To store the type of the token (e.g., "Bearer")
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setTokenType: (state, action) => {
            state.tokenType = action.payload;
        },
        clearAuth: (state) => {
            state.token = null;
            state.tokenType = null;
        },
    },
});

export const { setToken, setTokenType, clearAuth } = authSlice.actions;
export default authSlice.reducer;

import React from "react";
import {TransparentHead} from "../../styledcomponents/beforeLoginWidgets";
import Dropzone from "../widgets/DropZone";

function AdminImportCSV({ type }) {
  return (
    <div>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Import CSV
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <Dropzone type={type} accept="application/pdf" route={"mock"} />
      </div>
    </div>
  );
}

export default AdminImportCSV;

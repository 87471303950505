import {Box, Divider, MenuItem, Select, Typography, useTheme,} from "@mui/material";
import React from "react";

function QuestionSelection({ dropvalue, setDropValue }) {
  const theme = useTheme();
  return (
    <Box
      style={{
        border: "1.5px solid #EBECF0",
        borderRadius: "6px",
        marginTop: ".5rem",
      }}
    >
      <div
        style={{
          padding: ".5rem",
          backgroundColor:
            theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7",
        }}
      >
        <Typography variant="body2" fontWeight={600} marginLeft={1}>
          {"Question Selection:"}
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: ".3rem",
        }}
      >
        <Select
          size="small"
          fullWidth
          value={dropvalue}
          type="number"
          onChange={(e) => setDropValue(e.target.value)}
        >
          <MenuItem value={1}>Show me new questions only</MenuItem>
          <MenuItem value={2}>Repeat questions marked incorrect</MenuItem>
        </Select>
      </div>
    </Box>
  );
}

export default QuestionSelection;

import React, {useEffect, useState} from "react";
import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {
  SettingExamApi,
  SettingSubcribAddApi,
  SettingSubcribDeleteApi,
  SettingSubcribEditApi,
  SettingSubscriptionApi,
} from "../../../http/allAdminApi";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  InputBase,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {Form, Modal, Switch} from "antd";
import {default as swal} from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";
import InputAdornment from "@mui/material/InputAdornment";

function AdminSubscription() {
  const [subscription, setSubscription] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subcriName, setSubcriName] = useState("");
  const [subExam, setSubExam] = useState("");
  const [validityDate, setValidityDate] = useState("");
  const [amount, setAmount] = useState(0);
  const [modalFor, setModalFor] = useState("Add");
  const [openModal, setOpenModal] = useState(false);
  const [checkBox, setCheckBox] = useState(true);
  const [examOption, setExamOption] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [offerName, setOfferName] = useState("");
  const [offerAmount, setOfferAmount] = useState(0);
  const [offercheckBox, setofferCheckBox] = useState(true);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = subscription?.filter((item) => {
      // return (
      //   item.exam.toLowerCase().includes(value.toLowerCase()) ||
      //   item.name.toLowerCase().includes(value.toLowerCase())
      // );
      const lowerCaseValue = value.toLowerCase();
      return (
        (item.id && item.id.toString().includes(value)) ||
        (item.exam && item.exam.toLowerCase().includes(lowerCaseValue)) ||
        (item.name && item.name.toLowerCase().includes(lowerCaseValue)) ||
        (item.examId && item.examId.toString().includes(lowerCaseValue)) ||
        (item.validity && item.validity.toString().includes(lowerCaseValue))
      );
    });
    setResData(filteredResults);
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      handleSubscription();
      setAlertstatus("success");
      setOpen(true);
      setAnchorEl(null);
      setOpenModal(false);
      setValidityDate("");
      setAmount("");
      setSubcriName("");
      setSubExam("");
      setCheckBox(true);
      setPercentage(0);
      setOfferName("");
      setOfferAmount(0);
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleExamApi = () => {
    SettingExamApi().then((res) => {
      setExamOption(res.data.data);
    });
  };

  const handleDeleted = (val) => {
    let payload = {
      id: val,
    };
    SettingSubcribDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleSubscriptionMenu = (condition) => {
    setAnchorEl(null);
    if (condition === "Edit") {
      setModalFor("Edit");
      setAmount(paramData?.amount);
      setValidityDate(paramData?.validity);
      setSubExam(paramData?.examId);
      setSubcriName(paramData?.name);
      setCheckBox(paramData?.status);
      setId(paramData?.id);
      setOfferName(paramData?.offerName);
      setOfferAmount(paramData?.offerAmount);
      setPercentage(paramData?.offerPercentage);
      if (
        paramData?.offerPercentage === undefined ||
        paramData?.offerPercentage === null ||
        paramData?.offerPercentage === ""
      ) {
        setOfferAmount(0);
      }
      setofferCheckBox(paramData?.offerStatus);
      setOpenModal(true);
      handleExamApi();
    } else if (condition === "add") {
      setModalFor("Add");
      setOpenModal(true);
      handleExamApi();
    } else if (condition === "Delete") {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          // cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            handleDeleted(paramData?.id);
          }
        });
    }
  };

  const onChange = (checked) => {
    setCheckBox(!checkBox);
  };
  const onChangeOffer = () => {
    setofferCheckBox(!offercheckBox);
    if (offercheckBox === false) {
      setOfferAmount(0);
      setPercentage(0);
    }
  };

  const handleSubAdd = () => {
    if (offercheckBox && percentage === 0) {
      setSuccessMsg("Please Enter  Offerpercentage ");
      setAlertstatus("warning");
      setOpen(true);
      // setAmountError(false);
    } else {
      let payload = {
        amount: amount,
        validityDate: validityDate,
        examId: subExam,
        subscriptionName: subcriName,
        offerPercentage: percentage,
        offerName: offerName,
        status: checkBox,
        offerAmount: offerAmount,
        offerStatus: offercheckBox,
      };
      SettingSubcribAddApi(payload)
        .then((res) => {
          handleSuccess(res);
        })
        .catch((e) => {
          setSuccessMsg("Error!");
          setAlertstatus("warning");
          setOpen(true);
          console.log(e);
        });
    }
  };

  const handleSubEdit = () => {
    let payload = {
      id: id,
      amount: amount,
      validityDate: validityDate,
      examId: subExam,
      subscriptionName: subcriName,
      status: checkBox,
      offerPercentage: percentage,
      offerName: offerName,
      offerAmount: offerAmount,
      offerStatus: offercheckBox,
    };
    if (offercheckBox) {
      if (percentage === "0" || percentage === 0) {
        setSuccessMsg("Please Enter Percentage ");
        setAlertstatus("warning");
        setOpen(true);
      } else {
        SettingSubcribEditApi(payload)
          .then((res) => {
            handleSuccess(res);
          })
          .catch((e) => {
            setSuccessMsg("Error!");
            setAlertstatus("warning");
            setOpen(true);
            console.log(e);
          });
      }
    } else {
      SettingSubcribEditApi(payload)
        .then((res) => {
          handleSuccess(res);
        })
        .catch((e) => {
          setSuccessMsg("Error!");
          setAlertstatus("warning");
          setOpen(true);
          console.log(e);
        });
    }
  };

  const handleSubcribSave = () => {
    if (modalFor == "Add") {
      handleSubAdd();
    } else if (modalFor == "Edit") {
      handleSubEdit();
    }
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const handleSubscription = () => {
    SettingSubscriptionApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setSubscription(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };
  useEffect(() => {
    handleSubscription();
  }, []);

  const handlePerAmount = (e) => {
    if (e.target.value == undefined || e.target.value == null) {
      setPercentage(0);
    }
  };
  // const handleOfferValue = (e) => {
  //   const newPercentage = e.target.value;
  //   if (newPercentage === undefined && newPercentage === null) {
  //     setOfferAmount(0);
  //     console.log(offerAmount, "offerAmount");
  //   }
  //   setPercentage(newPercentage);

  //   if (newPercentage < 100) {
  //     console.log(newPercentage, "123");
  //     if (!isNaN(newPercentage) && !isNaN(amount)) {
  //       const calculatedValue = (amount * newPercentage) / 100;
  //       let totalOferAmount = amount - calculatedValue;
  //       console.log(totalOferAmount, "TAMOUNT");
  //       setOfferAmount(totalOferAmount);
  //     }
  //   } else {
  //     setSuccessMsg("Enter Percentage below 100");
  //     setAlertstatus("warning");
  //     setOpen(true);
  //   }
  // };
  const handleOfferValue = (e) => {
    const newPercentage = e.target.value;
    setPercentage(newPercentage);

    if (
      newPercentage === undefined ||
      newPercentage === null ||
      newPercentage === ""
    ) {
      setOfferAmount(0);
    } else {
      if (newPercentage < 100) {
        if (!isNaN(newPercentage) && !isNaN(amount)) {
          const calculatedValue = (amount * newPercentage) / 100;
          // let totalOfferAmount = amount - calculatedValue;
          let totalOfferAmount = Math.round(amount - calculatedValue);
          setOfferAmount(totalOfferAmount);
        }
      } else {
        setSuccessMsg("Enter Percentage below 100");
        setAlertstatus("warning");
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setSubcriName("");
    setSubExam("");
    setAmount(0);
    setValidityDate("");
    setOfferName("");
    setOfferAmount(0);
    setOpenModal(false);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "exam",
      headerName: "Exam",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.5,
      hide: true,
      // valueGetter: (params) => {
      //   return params?.row?.amount.toFixed(2);
      // },
      // renderCell: (params) => {
      //   return (
      //     <AboutParaTypo variant="body2">
      //       {params?.row?.amount.toFixed(2)}
      //     </AboutParaTypo>
      //   );
      // },
    },
    {
      field: "offerName",
      headerName: "Offer Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "black" }}
            >
              {params.row.offerName !== undefined &&
              params.row.offerName !== null
                ? params.row.offerName
                : "-"}
            </AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "offerPercentage",
      headerName: "Offer Percentage",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "black" }}
            >
              {params.row.offerPercentage ? params.row.offerPercentage : 0} %
            </AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "offerAmount",
      headerName: "Offer Amount",
      flex: 1,
      hide: true,
      // valueGetter: (params) => {
      //   return params?.row?.amount.toFixed(2);
      // },
      // renderCell: (params) => {
      //   return (
      //     <AboutParaTypo variant="body2">
      //       {params?.row?.amount.toFixed(2)}
      //     </AboutParaTypo>
      //   );
      // },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "offerstatus",
      headerName: " Offer Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.offerStatus ? "green" : "red",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
              marginLeft: ".2rem",
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.offerStatus ? "ON" : "OFF"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "validity",
      headerName: "Validity (Days)",
      flex: 0.6,
      hide: true,
    },
    {
      field: "age",
      headerName: "Action",
      flex: 0.6,
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
                margin: ".5rem",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Subscription
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleSubscriptionMenu("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add" ? "Add Subscription" : "Edit Subscription"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <TextField
          style={{ margin: ".5rem 0 1.5rem 0" }}
          size="small"
          fullWidth
          label="Name"
          id="subName"
          value={subcriName}
          onChange={(e) => setSubcriName(e.target.value)}
        />
        <TextField
          id="examOptionField"
          select
          label="Exam"
          placeholder="Select the Exam"
          type="text"
          size="small"
          value={subExam}
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          onChange={(e) => setSubExam(e.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {examOption?.map((item, id) => (
            <MenuItem key={id} value={item.id}>
              {item.exam}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Validity in Days"
          type="text"
          size="small"
          value={validityDate}
          id="validityField"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          onChange={(e) => setValidityDate(e.target.value)}
        />
        <TextField
          label="Amount"
          type="text"
          size="small"
          value={amount}
          id="amountField"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          onChange={(e) => setAmount(e.target.value)}
        />
        {offerAmount != undefined || offerAmount != null || amount > 0 ? (
          <TextField
            label="Offer Amount"
            type="text"
            id="offerAmountField"
            size="small"
            value={offerAmount}
            style={{ margin: ".5rem 0 1.5rem 0" }}
            fullWidth
            disabled
            onChange={(e) => handlePerAmount(e)}
          />
        ) : null}
        <TextField
          label="Offer Detail"
          type="text"
          size="small"
          value={offerName}
          id="offerDetailField"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          onChange={(e) => setOfferName(e.target.value)}
        />

        <TextField
          label="Offer Percentage"
          type="text"
          size="small"
          value={percentage}
          id="offerPercentageField"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          onChange={(e) => handleOfferValue(e)}
        >
          {/* <span style={{ color: "red" }}>{amountError}</span> */}
        </TextField>
        <Form.Item label="Status">
          <Switch checked={checkBox} onChange={onChange} />
        </Form.Item>
        <Form.Item label="Offer On/off">
          <Switch checked={offercheckBox} onChange={onChangeOffer} />
        </Form.Item>

        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              outline: "none",
              backgroundColor:
                subcriName == "" ||
                subExam == "" ||
                validityDate == "" ||
                amount == ""
                  ? "white"
                  : "#2c3643",
              color:
                subcriName == "" ||
                subExam == "" ||
                validityDate == "" ||
                amount == ""
                  ? "black"
                  : "#fff",
            }}
            disabled={
              subcriName == "" ||
              subExam == "" ||
              validityDate == "" ||
              amount == ""
            }
            onClick={handleSubcribSave}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleSubscriptionMenu("Edit")}>Edit</MenuItem>
        <MenuItem onClick={() => handleSubscriptionMenu("Delete")}>
          Delete
        </MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminSubscription;

import {Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {PassBotDiv,} from "../../styledcomponents/styledQBExamWidgets";

function PassBot(theme) {
  const darkMode = sessionStorage.getItem("mode");
  const navigate = useNavigate();
  return (
    <div>
      {/* <HowToUseDiv>
        <Typography variant="caption" color="0c7083">
          How to use the Passbot
        </Typography>
      </HowToUseDiv> */}
      <PassBotDiv style={{ backgroundColor: "rgba(43, 197, 94, 0.16)" }}>
        <Typography variant="h6">Mock Test is now available</Typography>
        <div>
          <Typography variant="body2">
            pgneet.net has released Mock Tests 1-10.This can be added to your
            account by going to 'My Account' then clicking 'Add a resource to my
            account' on the right hand slide of the page
          </Typography>
        </div>
        <div>
          {/* <PassBotBlueButton onClick={(e) => navigate("/user/dashboard")}> */}
          <StyledGreenButton
            style={{ marginTop: "1rem" }}
            onClick={(e) => navigate("/user/dashboard")}
          >
            Go to My Account
          </StyledGreenButton>
          {/* </PassBotBlueButton> */}
        </div>
      </PassBotDiv>
    </div>
  );
}

export default PassBot;

import React, {useEffect, useState} from "react";
import {AboutParaTypo, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {handleBlogHeadingApi,} from "../../../http/allAdminApi";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    InputBase,
    Menu,
    MenuItem,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
// import { Modal } from "antd";
import {default as swal} from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";
import {Form, Modal, Switch} from "antd";

function AdminBlogsHeading() {
  const [headingList, setHeadingList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [blogHeading, setBlogHeading] = useState("");
  const [checkBox, setCheckBox] = useState(true);
  const [blogHeadingId, setBlogHeadingId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalFor, setModalFor] = useState("Add");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = headingList?.filter((item) => {
      let finalValue = value.replace(/\s/g, "").toLowerCase();
      let lowerCaseValue = value.toLowerCase();
      let finalstate = item?.blogHeading?.replace(/\s/g, "").toLowerCase();
      return (
        (item.id && item.id.toString().includes(value)) ||
        (item.state && item.blogHeading.toString().includes(lowerCaseValue)) ||
        (finalstate &&
          finalstate.toLowerCase().includes(finalValue.toLowerCase()))
      );
    });
    setResData(filteredResults);
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      handleBlogTable();
      setBlogHeading("");
      setAlertstatus("success");
      setOpen(true);
      setOpenModal(false);
      setAnchorEl(null);
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };
  const handleDeleteApi = (val, flag) => {
    let payload = {
      id: val,
      flag: flag,
    };
    handleBlogHeadingApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        console.log(e);
      });
  };

  const onChange = (checked) => {
    setCheckBox(!checkBox);
  };

  const handleBlogHeading = (condition) => {
    setAnchorEl(null);
    if (condition === "Edit") {
      setModalFor("Edit");
      setBlogHeadingId(paramData?.id);
      setBlogHeading(paramData?.blogHeading);
      setOpenModal(true);
    } else if (condition === "add") {
      setModalFor("Add");
      setOpenModal(true);
    } else if (condition === "Delete") {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          // cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            handleDeleteApi(paramData?.id, "Delete");
          }
        });
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleBlogHdAddApi = (val) => {
    let payload = {
      blogHeading: blogHeading,
      status: checkBox,
      flag: val,
    };
    handleBlogHeadingApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        console.log(e);
      });
  };

  const handleBlogHdEditApi = (val) => {
    let payload = {
      blogHeading: blogHeading,
      id: blogHeadingId,
      status: checkBox,
      flag: val,
    };
    handleBlogHeadingApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        console.log(e);
      });
  };

  const handleApiClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    if (modalFor == "Add") {
      handleBlogHdAddApi("Add");
    } else if (modalFor == "Edit") {
      handleBlogHdEditApi("Edit");
    }
  };

  const handleBlogTable = () => {
    let payload = {
      flag: "all",
    };
    handleBlogHeadingApi(payload)
      .then((res) => {
        if (res?.data?.data) {
          setResData(res?.data?.data);
          setHeadingList(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    handleBlogTable();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
      hide: true,
    },
    {
      field: "blogHeading",
      headerName: "Blog Category",
      flex: 5,
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 2,
      renderCell: (params) => {
        return (
          <div
            style={{
              color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              textAlign: "center",
              borderRadius: "50px",
              padding: ".2rem 1rem",
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </div>
        );
      },
    },
    {
      field: "age",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor:
                  params.row.blogHeading === "All" ? "gray" : "#6C757D",
                color: params.row.blogHeading === "All" ? "black" : "#fff",
                textTransform: "none",
                outline: "none",
                margin: ".5rem",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
              disabled={params.row.blogHeading === "All"}
            >
              Action{" "}
              <ArrowDropDown
                style={{
                  color: params.row.blogHeading === "All" ? "black" : "#fff",
                }}
              />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Blog Categories
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleBlogHeading("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add" ? "Add Blog Category" : "Edit Blog Category"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <TextField
          placeholder="Blog Heading"
          size="small"
          value={blogHeading}
          id="State Code"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000",
            },
            "& .MuiInputLabel-root": {
              color: "#000",
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px",
            },
          }}
          fullWidth
          onChange={(e) => setBlogHeading(e.target.value)}
        />
        <Form.Item label="Status">
          <Switch checked={checkBox} onChange={onChange} />
        </Form.Item>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              backgroundColor:
                blogHeading == "" || blogHeading == "" ? "white" : "#2c3643",
              color: blogHeading == "" || blogHeading == "" ? "black" : "#fff",
            }}
            disabled={blogHeading == ""}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleBlogHeading("Edit")}>Edit</MenuItem>
        <MenuItem onClick={() => handleBlogHeading("Delete")}>Delete</MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminBlogsHeading;

/**
 * Utility to check if a value is undefined, null, empty, or zero.
 * @param {*} value - The value to check.
 * @returns {boolean} - Returns true if value is invalid, otherwise false.
 */
export const isNullOrEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "string" && value.trim() === "") ||
        (typeof value === "number" && value === 0) ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0)
    );
};

export const isEqualIgnoreCaseAndTrimmed = (str1, str2) => {
    if (!str1 || !str2) return false; // Handle null or undefined cases
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
};

// Merge and resolve conflicts by overriding with the second array
export const mergedArray = (array1, array2) => {
    return [...array1, ...array2];
};

/**
 * Filters an array of objects by a specific id.
 * @param {Array} array - The array to filter.
 * @param {number} id - The id to filter by.
 * @returns {Object|null} - The object with the specified id, or null if not found.
 */
export const filterById = (array, id) => array.find((item) => item.id === id) || null;

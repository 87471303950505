import {Alert, Box, Snackbar, TextField} from "@mui/material";
import React, {useState} from "react";
import {AboutParaTypo, TransparentHead,} from "../styledcomponents/beforeLoginWidgets";
import {StyledGreenButton} from "../styledcomponents/styledDBwidgets";
import useStyles from "../styles/FAQPageStyles";
import {contactApi} from "../../http/allApis";
import ModelFile from "../landing/ModelFile";

function ContactAuth() {
  const classes = useStyles();
  const person = JSON.parse(sessionStorage.getItem("person"));
  const [email, setEmail] = useState(person?.email || "");
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertstatus, setAlertstatus] = useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const handleContactApi = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (comment.length === 0) {
      setMessage("Please Enter Comment Message!");
      setAlertstatus("warning");
      setOpen(true);
    } else if (!emailPattern.test(email)) {
      setMessage("Please Enter Vaild Mail Id", "error");
      setAlertstatus("warning");
      setOpen(true);
    } else {
      const payload = {
        comment: comment,
      };
      contactApi(payload)
        .then(function (response) {
          if (response.data.result === true) {
            setMessage("Form Submitted Successfully!");
            setComment("");
            setAlertstatus("success");
          } else {
            setMessage("Error");
            setAlertstatus("warning");
          }
          setOpen(true);
        })
        .catch((response) => {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      <Box className={classes.faqPageMainBox}>
        <div className={classes.boxHeader}>
          <TransparentHead variant="h5" fontWeight={"bold"}>
            Contact Us
          </TransparentHead>
        </div>
        <div
          style={{
            padding: "1rem",
          }}
        >
          <AboutParaTypo variant="body2">
            If you have any queries, comments or suggestions on how we may
            improve the site, please contact us:
          </AboutParaTypo>
          {person?.signupType === "pgneet" ? (
            <div style={{ marginTop: "1.5rem" }}>
              <TextField
                label="Email"
                type={"email"}
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled
              />
            </div>
          ) : null}
          <div style={{ margin: "1.5rem 0" }}>
            <TextField
              label="Message"
              type={"text"}
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
            />
            {comment.length > 500 ? (
              <span style={{ fontSize: "15px", color: "#FF0000" }}>
                Maximum characters length is 500*
              </span>
            ) : null}
          </div>
          <StyledGreenButton
            onClick={handleContactApi}
            disabled={email === "" || comment === "" || comment.length > 500}
          >
            Send Message
          </StyledGreenButton>
          <div style={{ margin: "1.5rem 0" }}>
            <AboutParaTypo variant="body2">
              Alternatively you can e-mail us at{" "}
              <a
                href="mailto:admin@pgneet.net"
                style={{ color: "#31a188", textDecoration: "none" }}
              >
                {" "}
                admin@pgneet.net
              </a>
            </AboutParaTypo>
          </div>
        </div>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default ContactAuth;

import {styled} from "@mui/material";
import React from "react";
import {Outlet} from "react-router-dom";
import Footer from "./navbar/Footer";
import NavBar from "./navbar/NavBar";

const OutletDiv = styled("div")(({ theme }) => ({
  padding: "1rem 9%",
  minHeight: "100vh",
  overflow: "auto",
  width: "100%",
  cursor: "default",
  [theme.breakpoints.down(1200)]: {
    padding: ".5rem 4%",
    height: "auto",
  },
}));

function Dashboard() {
  return (
    <div>
      <NavBar />
      <OutletDiv>
        <Outlet />
      </OutletDiv>
      <Footer />
    </div>
  );
}

export default Dashboard;

import * as React from "react";
import {Box, Button, Snackbar, useTheme} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import MuiAlert from "@mui/material/Alert";
import {addImproveApi} from "../../../http/allApis";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "fontfamily",
      "fontsize",
      "|",
      "alignment",
      "|",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "subscript",
      "superscript",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
      "|",
    ],
  },
};

function ImproveEditor({ questionid, setShowimprove }) {
  const theme = useTheme();
  const [myimprove, setMyimprove] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");

  const addimprove = (data) => {
    setMyimprove((prevMyimprove) => prevMyimprove + data + ": ");
  };

  const snackbarcall = (message1, status1) => {
    setMessage(message1);
    setAlertstatus(status1);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const submitImprove = () => {
    let payload = {
      myimprove: myimprove,
      questionid: questionid,
    };
    addImproveApi(payload)
      .then((response) => {
        if (response.data.result === true) {
          snackbarcall("Submitted Successfully...", "success");
          setMyimprove("");
          // setShowimprove(false);
        } else {
          snackbarcall(response.data.error.description, "warning");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const removeEmptyParagraphs = (data) => {
    // Use regular expression to remove <p>&nbsp;</p> tags from the data
    return data.replace(/<p>&nbsp;<\/p>/g, "");
  };

  return (
    <div>
      <Box
        color={"black"}
        p={2}
        bgcolor={
          theme.palette.mode === "dark"
            ? theme.palette.background.paper
            : "grey.50"
        }
        sx={{
          border: "1px solid #ccc",
          margin: "1rem 0 2rem 0",
          borderRadius: 2,
        }}
      >
        <StyledGreenButton
          sx={{ marginRight: 2, marginBottom: 2 }}
          startIcon={<AddIcon />}
          onClick={() => addimprove("Not relevant for the exam")}
        >
          {" "}
          Not relevant for the exam
        </StyledGreenButton>
        <StyledGreenButton
          sx={{ marginRight: 2, marginBottom: 2 }}
          startIcon={<AddIcon />}
          onClick={() => addimprove("Wrong Category")}
        >
          {" "}
          Wrong Category
        </StyledGreenButton>
        <StyledGreenButton
          sx={{ marginRight: 2, marginBottom: 2 }}
          startIcon={<AddIcon />}
          onClick={() => addimprove("Explanation not adequate")}
        >
          {" "}
          Explanation not adequate
        </StyledGreenButton>
        <StyledGreenButton
          sx={{ marginRight: 2, marginBottom: 2 }}
          startIcon={<AddIcon />}
          onClick={() => addimprove("Not in keeping with current guidelines")}
        >
          {" "}
          Not in keeping with current guidelines
        </StyledGreenButton>
        <StyledGreenButton
          sx={{ marginRight: 2, marginBottom: 2 }}
          startIcon={<AddIcon />}
          onClick={() => addimprove("Spelling/grammar problems")}
        >
          {" "}
          Spelling/grammar problems
        </StyledGreenButton>

        <CKEditor
          editor={ClassicExtended}
          config={editorConfiguration}
          data={myimprove}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setMyimprove(removeEmptyParagraphs(data));
          }}
        />
        <Box mt={2}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#31A188",
              "&:hover": { backgroundColor: "#F38424" },
              outline: "none",
            }}
            onClick={submitImprove}
          >
            {" "}
            Submit
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ImproveEditor;

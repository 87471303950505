import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {
    Alert,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ContentDiv} from "../styledcomponents/beforeLoginWidgets";
import {profileCheck} from "./profileCheck";
import {StyledButton} from "../styledcomponents/styledQBExamWidgets";
import alert1 from "../../assets/alert1.svg";
import {confirmDelete, dashboardinstructionAPi, deleteUseApi, userApiLogout,} from "../../http/allApis";
import {Modal} from "antd";

function LandingMenu({ type }) {
  const navigate = useNavigate();
  const [qbOpen, setQbOpen] = useState(false);
  const [accOpen, setAccOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const person = JSON.parse(sessionStorage.getItem("person"));
  const [modelWin, setModelWin] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [modalWindow, setModalWindow] = useState(false);
  const [instValue, setInstValue] = useState([]);
  const [approve, setApprove] = useState(false);
  const hanldeLogout = () => {
    const credential = JSON.parse(sessionStorage.getItem("Tokentype"));
    userApiLogout(credential)
      .then((res) => {
        if (res.data.status === "Success") {
          navigate("/");
          sessionStorage.clear();
        } else {
          alert(() => {
            "Something went wrong please try again try";
          });
        }
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          {
            navigate("/");
            sessionStorage.clear();
          }
        }
      });
  };
  const handleAction = (val) => {
    if (profileCheck() === false && val !== "Logout") {
      alert("Update your profile details to proceed!");
    } else {
      if (val === "Review") {
        navigate("/student/review");
      } else if (val === "Dashboard") {
        navigate("/user/dashboard");
      } else if (val === "Performance") {
        navigate("/student/performance");
      } else if (val === "ResetQb") {
        navigate("/student/resetqb");
      } else if (val === "Contact") {
        navigate("/student/contact");
      } else if (val === "Profile") {
        navigate("/user/profile");
      } else if (val === "myinvoices") {
        navigate("/student/myinvoices");
      } else if (val === "Logout") {
        setApprove(true);
      }
    }
  };
  window.scroll(0, 0);
  const handleDelete = () => {
    setModelWin(true);
    // setAnchorE2(null);
  };
  const handleClose = () => {
    setOpen(false);
    setModalWindow(false);
  };
  const handleinst = (item) => {
    return item.status;
  };
  useEffect(() => {
    dashboardinstructionAPi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data.filter(handleinst);
          const firstItems = data.slice(0, 1);
          setInstValue(firstItems);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const handleDeletePgneet = (payload) => {
    deleteUseApi(payload)
      .then((res) => {
        if (res.data.data === true) {
          confirmDelete().then(() => {
            if (res?.data?.status === "Success") {
              navigate("/");
              sessionStorage.clear();
            } else {
              setMessage(res?.data?.error?.description);
              setAlertstatus("warning");
              setOpen(true);
            }
          });
        } else {
          setMessage("Check passwork", "warning");
          setAlertstatus("warning");
          setOpen(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleNotPgneet = () => {
    confirmDelete().then((res) => {
      if (res?.data?.status === "Success") {
        navigate("/");
        sessionStorage.clear();
      } else {
        setMessage("Check passwork", "warning");
        setAlertstatus("warning");
        setOpen(true);
      }
    });
  };
  const handleUserDte = () => {
    let payload = {
      password: passwordValue,
    };
    if (person?.signupType === "pgneet") {
      handleDeletePgneet(payload);
    } else {
      handleNotPgneet();
    }
  };

  return (
    <div>
      {type !== undefined ? (
        <div>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <Divider />
            <ListItemButton onClick={() => handleAction("Dashboard")}>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => setQbOpen(!qbOpen)}>
              <ListItemText primary="Question Bank" />
              {qbOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={qbOpen} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{ backgroundColor: "rgba(43, 197, 94, 0.16)" }}
                disablePadding
              >
                <ListItemButton
                  onClick={() => handleAction("Review")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary="Review" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleAction("Performance")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary="Performance" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleAction("ResetQb")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary="Reset the Question history" />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={() => setAccOpen(!accOpen)}>
              <ListItemText primary="My Account" />
              {accOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={accOpen} timeout="auto" unmountOnExit>
              <List
                component="div"
                sx={{ backgroundColor: "rgba(43, 197, 94, 0.16)" }}
                disablePadding
              >
                <ListItemButton
                  onClick={() => handleAction("Profile")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary="Profile" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => handleAction("myinvoices")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary="Invoices" />
                </ListItemButton>
                <ListItemButton onClick={handleDelete} sx={{ pl: 4 }}>
                  <ListItemText primary="Delete" />
                </ListItemButton>
              </List>
            </Collapse>
            <Divider />
            <ListItemButton onClick={() => handleAction("Contact")}>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => handleAction("Logout")}>
              <ListItemText primary="Log out " />
            </ListItemButton>
            <Divider />
          </List>
        </div>
      ) : (
        <ContentDiv>
          <List>
            <Divider />
            <ListItem onClick={() => navigate("/")}>Home</ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/about")}>About Us</ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/courses")}>Courses</ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/blog")}>Blogs</ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/faqs")}>FAQs</ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/contact")}>Contact Us</ListItem>
            <Divider />
            <ListItem onClick={() => navigate("/login")}>
              Login/Sign Up
            </ListItem>
            <Divider />
          </List>
        </ContentDiv>
      )}
      <Modal
        width={600}
        open={modelWin}
        onOk={() => setModelWin(false)}
        onCancel={() => setModelWin(false)}
        footer={null}
      >
        <div>
          <img src={alert1} alt="Alert" width="50px" height="50px" />
          <span
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              marginTop: "2rem",
              marginLeft: "1rem",
            }}
          >
            Delete your account
          </span>
        </div>

        <Divider
          style={{
            marginTop: ".5rem",
            backgroundColor: "gray",
            marginBottom: "2rem",
          }}
        />
        {/* </div> */}
        <div style={{ marginBottom: "1rem", fontSize: "18px" }}>
          <span style={{ fontWeight: "bold" }}> Are you sure?</span>
          &nbsp; Your profile and related account information will be deleted
          from our site
        </div>
        {person?.signupType === "pgneet" ? (
          <TextField
            // placeholder="ak"
            label="Password"
            size={"small"}
            // placeholder="Password"
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
          />
        ) : null}
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "1.5rem",
          }}
        >
          <StyledButton
            onClick={handleUserDte}
            style={{
              outline: "none",
              backgroundColor: "#dc3545",
              color: "white",
            }}
            disabled={passwordValue === ""}
          >
            Confirm
          </StyledButton>
          <div
            style={{ marginTop: "1rem", marginLeft: "1rem", cursor: "pointer" }}
            onClick={() => setModelWin(false)}
          >
            <span
              style={{
                color: "#31a188",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              {" "}
              No I`ve changed my mind
            </span>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {/*<Modal
        width={600}
        open={approve}
        onOk={() => setApprove(false)}
        onCancel={() => setApprove(false)}
        footer={null}
      >
        <div style={{ width: "100%", textAlign: "center", padding: "2rem" }}>
          <span style={{ fontSize: "20px", color: "#dc3545" }}>
            {" "}
            Are you sure you want to logout !
          </span>
          <div style={{ marginTop: "2rem" }}>
            <Button
              style={{
                border: "1px solid #dc3545",
                width: 100,
                textTransform: "none",
                outline: "none",
                color: "#dc3545"
              }}
              onMouseEnter={(e) => (
                (e.target.style.backgroundColor = "#dc3545"),
                (e.target.style.color = "white")
              )}
              onMouseLeave={(e) => (
                (e.target.style.backgroundColor = ""),
                (e.target.style.color = "#dc3545")
              )}
              onClick={() => setApprove(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#31a188",
                color: "white",
                width: 100,
                textTransform: "none",
                marginLeft: "2rem",
                outline: "none"
              }}
              onClick={hanldeLogout}
            >
              Logout
            </Button>
          </div>
        </div>
      </Modal>*/}
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            Logout Alert{" "}
          </Typography>
        }
        width={370}
        open={approve}
        onOk={() => setApprove(false)}
        onCancel={() => setApprove(false)}
        footer={null}
      >
        <Typography style={{ marginBottom: "1rem" }}>
          Are you sure you wish to logout?
        </Typography>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledButton
            onClick={() => setApprove(false)}
            style={{ outline: "none" }}
          >
            Cancel
          </StyledButton>
          <StyledButton
            onClick={() => hanldeLogout()}
            style={{
              outline: "none",
              marginLeft: "1rem",
              backgroundColor: "#31a188",
            }}
          >
            Logout
          </StyledButton>
        </div>
      </Modal>
    </div>
  );
}

export default LandingMenu;

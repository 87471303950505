import { configureStore } from "@reduxjs/toolkit";
import planValidityReducer from "./planValiditySlice";
import authReducer from "./authSlice";

export const store = configureStore({
    reducer: {
        planValidity: planValidityReducer,
        auth: authReducer, // Add the new reducer
    },
});

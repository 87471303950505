import React, {useMemo} from "react";
import JoditEditor from "jodit-react";
import "./custom-jodit.css";

function AdminTextEditor({ editorRef, editorFor, setContent, content }) {
  const config = useMemo(
      () => ({
        uploader: {
          insertImageAsBase64URI: true, // Upload images as Base64
        },
        /*enter: "p", // Use <p> instead of <br> for new lines
        cleanHTML: {
            removeEmptyElements: true, // Remove empty <br> or other tags
        },*/
        height: 350,
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: 'Start typings...'
      }),
      []
  );
  return (
    <div>
      <JoditEditor
          key={editorFor}
          ref={editorRef}
          config={config}
          value={content}
          onBlur={(val) => setContent(val)}
          style={{ height: config.height }}
      />
    </div>
  );
}

export default AdminTextEditor;

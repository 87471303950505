import React, {useEffect, useState} from "react";
import constantValues from "../components/Common/ConstantValues";
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import pgneet_logo from "../../src/assets/pgneet_logo_text.png";
import success from "../../src/assets/shield.png";
import greetings from "../../src/assets/greetings.png";
import error from "../../src/assets/error.png";
import linkexprie from "../../src/assets/linkexprie.png";
import user from "../../src/assets/user.png";

import {styled} from "@mui/material";

const OutletDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "30px",
  marginTop: "3rem",
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  [theme.breakpoints.between(600, 1300)]: {
    fontSize: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
  [theme.breakpoints.up(1300)]: {
    fontSize: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
  },
}));
const ButtonStyle = styled("Button")(({ theme }) => ({
  backgroundColor: "#31a188",
  color: "#fff",
  textTransform: "none",
  outline: "none",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "#f38424",
    color: "#fff",
  },
  [theme.breakpoints.between(0, 600)]: {
    fontSize: "15px",
  },
  [theme.breakpoints.between(600, 1300)]: {
    fontSize: "15px",
  },
  [theme.breakpoints.up(1300)]: {
    fontSize: "20px",
  },
}));
const ImageStyle = styled("div")(({ theme }) => ({
  marginTop: "2rem",
}));
const VeritfyEmail = () => {
  const navigate = useNavigate();
  const [state, setState] = useState("");
  const [check, SetCheck] = useState(false);
  const [userVer, SetUsrVer] = useState(false);
  const [authIss, setAuthIss] = useState(false);
  const [internalIss, setInternalIss] = useState(false);
  const [suc, setSuc] = useState(false);
  const location = window?.location?.search;
  let authToken = location;
  let email;
  if (location != "") {
    let splitOne = authToken.split("?");
    let secondSplit = splitOne[1].split("pg-auth=");
    email = secondSplit[1].split("&pg-identity.email=");
  }

  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (location != "") {
      let payload = {
        "pg-auth": email[0],
        "pg-identity.email": email[1],
      };
      Axios.post(constantValues.PGNEET_PROD_URL + "/auth/verify-email", null, {
        params: payload,
      })
        .then((res) => {
          if (res?.data?.status === "Success") {
            setState(res.data.message);
            setSuc(true);
          } else if (res.data.error.description === "Email already verified.") {
            setState(res.data.error.description);
            SetCheck(true);
          } else if (res.data.error.description === "User Not Found.") {
            setState(res.data.error.description);
            SetUsrVer(true);
          } else if (
            res?.data?.error?.description === "User Not Found." ||
            res?.data?.error?.description === "Invalid Data"
          ) {
            setState("You are not authorised! This page");
            setAuthIss(true);
          }
        })
        .catch((err) => {
          setState("An error occurred. Please try again later.");
          setInternalIss(true);
        });
    }
  }, []);

  return (
    <div style={{ margin: "20px" }}>
      <img
        src={pgneet_logo}
        width={"auto"}
        style={{ cursor: "pointer", height: "5rem" }}
        alt="pg_neet_logo"
      />

      <ImageStyle>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          {authIss === true ? (
            <img src={error} alt="error" width={"100px"} height={"100px"} />
          ) : null}
          {suc === true ? (
            <img src={success} alt="success" width={"100px"} height={"100px"} />
          ) : null}
          {userVer === true ? (
            <img src={user} alt="user" width={"100px"} height={"100px"} />
          ) : null}
          {check === true ? (
            <img
              src={greetings}
              alt="greetings"
              width={"100px"}
              height={"100px"}
            />
          ) : null}
          {internalIss == true ? (
            <img
              src={linkexprie}
              alt="linkexprie"
              width={"100px"}
              height={"100px"}
            />
          ) : null}
        </div>
      </ImageStyle>

      <OutletDiv>&nbsp; {state} &nbsp;</OutletDiv>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {internalIss ? null : (
          <ButtonStyle
            style={{
              cursor: "pointer",
              padding: ".5rem .3rem .5rem .3rem",
              borderColor: "transparent",
              fontSize: "15px",
            }}
            onClick={handleLogin}
          >
            Click me back to&nbsp;
            {userVer ? <span>Sign up</span> : <span>login</span>}
          </ButtonStyle>
        )}
      </div>
    </div>
  );
};

export default VeritfyEmail;

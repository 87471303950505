import React, {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import {ConfirmUserApi, socialSignUpApi} from "../../../http/allApis";
import {Typography} from "@mui/material";
import {StyledButton} from "../../styledcomponents/styledQBExamWidgets";
import {Modal} from "antd";
// import Go from "../../../";

const GoogleLoginButton = ({ buttonFor, callBackFunction, handleAlert }) => {
  const [modelWin, setModelWin] = useState(false);
  const [resp, setResp] = useState();

  const handleCredentialResponse = (response) => {
    // console.log("Encoded JWT ID token: " + response.credential);
    const userData = jwtDecode(response.credential);
    setResp(response);
    let payload = {
      email: userData.email,
      // usrFullName: userData.name,
      webToken: response?.credential,
      signupType: "Google",
    };
    socialSignUpApi(payload, buttonFor)
      .then((res) => {
        if (res.data.status === "Success") {
          sessionStorage.setItem("token", res.data.data.accessToken);
          sessionStorage.setItem("Tokentype", JSON.stringify(res?.data?.data));
          callBackFunction(res.data.data);
        } else if (res.data.status === "Failure") {
          handleAlert(res.data.message, "warning");
        } else {
          if (
            res.data.error.description ===
            "You Are Already Login.You Need to Continue This Session."
          ) {
            setModelWin(true);
          } else {
            handleAlert(res.data.error.description, "warning");
          }
        }
      })
      .catch((response) => {
        console.log(response);
      });
  };
  const handleLoginIssue = (select) => {
    if (select === "Continue") {
    } else {
    }
    let payload = {
      webToken: resp?.credential,
      signupType: "Google",
    };
    ConfirmUserApi(payload).then((res) => {
      if (res.data.status === "Success") {
        setModelWin(false);
        socialSignUpApi(payload, buttonFor)
          .then((res) => {
            if (res.data.status === "Success") {
              sessionStorage.setItem("token", res.data.data.accessToken);
              sessionStorage.setItem(
                "Tokentype",
                JSON.stringify(res?.data?.data),
              );
              callBackFunction(res.data.data);
            } else if (res.data.status === "Failure") {
              handleAlert(res.data.message, "warning");
            } else {
              handleAlert(res.data.error.description, "warning");
              setModelWin(true);
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleGoogleSignIn = () => {
    let buttonElement = document.getElementById("buttonDiv");
    const isMobile = window.innerWidth <= 500;
    const buttonWidth = isMobile ? "350px" : "250px";
    const buttonInnerWidth = isMobile ? "400px" : "100%";
    window?.google?.accounts?.id?.initialize({
      client_id:
        "439133459566-5h8k4bhd20jhh2f18qsddpegetg7cftc.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    const buttonStyle = {
      outline: "none",
      width: "20px",
      innerHeight: "20px",
    };
    window?.google?.accounts?.id?.renderButton(buttonElement, {
      theme: "outline",
      size: "medium",
      type: "icon",
      text: "Google",
      shape: "circle",
      outline: "none",
      width: buttonWidth,
      innerWidth: buttonInnerWidth,
      style: buttonStyle,
      // sessionSelector: [
      //   {
      //     sessionType: "NO",
      //     buttonOption: buttonFor === "signup" ? "SIGN_UP" : "SIGN_IN",
      //     retrieve: false, // Set to false to prevent retrieving existing sessions
      //     prompt: "select_account"
      //   }
      // ]
    });
    // window.google.accounts.id.prompt();
  };

  useEffect(() => {
    handleGoogleSignIn();
  }, []);

  // useEffect(() => {
  //   window?.google?.accounts?.id?.initialize({
  //     client_id:
  //       "439133459566-5h8k4bhd20jhh2f18qsddpegetg7cftc.apps.googleusercontent.com",
  //     callback: handleCredentialResponse
  //   });

  //   let buttonElement = document.getElementById("buttonDiv");
  //   const isMobile = window.innerWidth <= 500;
  //   const buttonWidth = isMobile ? "350px" : "250px";
  //   const buttonInnerWidth = isMobile ? "300px" : "100%";

  //   const buttonOption = "SIGN_IN";

  //   window.google.accounts.id.renderButton(buttonElement, {
  //     theme: "outline",
  //     size: "large",
  //     type: "standard",
  //     text: `Sign in with Google`,
  //     shape: "rectangular",
  //     width: buttonWidth,
  //     innerWidth: buttonInnerWidth,
  //     sessionSelector: [
  //       {
  //         sessionType: "NO",
  //         buttonOption: buttonOption,
  //         retrieve: false,
  //         prompt: "select_account"
  //       }
  //     ]
  //   });
  // }, []);
  //   window?.google?.accounts?.id?.initialize({
  //     client_id:
  //       "439133459566-5h8k4bhd20jhh2f18qsddpegetg7cftc.apps.googleusercontent.com",
  //     callback: handleCredentialResponse
  //   });
  //   let buttonElement = document.getElementById("buttonDiv");
  //   const isMobile = window.innerWidth <= 500;

  //   const buttonWidth = isMobile ? "350px" : "250px";
  //   const buttonInnerWidth = isMobile ? "300px" : "100%";

  //   window.google.accounts.id.renderButton(buttonElement, {
  //     theme: "outline",
  //     size: "large",
  //     type: "standard",
  //     text: "Custom Sign-In Text",
  //     shape: "rectangular",
  //     width: buttonWidth,
  //     innerWidth: buttonInnerWidth,
  //     sessionSelector: [
  //       {
  //         sessionType: "NO",
  //         buttonOption: buttonFor === "signup" ? "SIGN_UP" : "SIGN_IN",
  //         retrieve: true,
  //         prompt: "select_account"
  //       }
  //     ]
  //   });
  // }, []);

  return (
    <div>
      <div>
        <div id="buttonDiv"></div>
      </div>
      <div>
        <Modal
          title={
            <Typography variant="h6" gutterBottom>
              Login Alert{" "}
            </Typography>
          }
          open={modelWin}
          onOk={() => setModelWin(false)}
          onCancel={() => setModelWin(false)}
          footer={null}
        >
          <Typography style={{ marginBottom: "1rem" }}>
            This message means that you've already signed into your account or
            session on this platform.
          </Typography>
          <Typography>
            To proceed, you don't need to log in again. Instead, you can pick up
            where you left off in your current session without re-entering your
            login credentials.
          </Typography>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <StyledButton
              onClick={() => handleLoginIssue("Cancel")}
              style={{ outline: "none" }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              onClick={() => handleLoginIssue("Continue")}
              style={{
                outline: "none",
                marginLeft: "1rem",
                backgroundColor: "#31a188",
              }}
            >
              Continue
            </StyledButton>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GoogleLoginButton;

import React from "react";
import {useFormik} from "formik";
import {AboutParaTypo} from "../../styledcomponents/beforeLoginWidgets";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {TextField} from "@mui/material";
import {updatePassApi} from "../../../http/allApis";

function ConfirmPassword({ snackbarcall }) {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.oldPassword) {
        errors.oldPassword = "Please enter the old password";
      }
      if (!values.newPassword) {
        errors.newPassword = "Please enter the new password";
      } else if (values.newPassword.length < 8) {
        errors.newPassword = "Password must be at least 8 characters long";
      } else if (values.oldPassword === values.newPassword) {
        errors.newPassword = "Old Password and New Password are same";
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = "Please confirm the password";
      } else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updatePassApi(values)
        .then((res) => {
          if (res?.data?.status === "Success") {
            snackbarcall("Password updated successfully!", "success");
            resetForm();
          } else {
            snackbarcall(res.data.error.description, "warning");
          }
        })
        .catch((e) => {
          snackbarcall("Error!", "warning");
          console.log(e);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <AboutParaTypo>Old Password</AboutParaTypo>
        <TextField
          placeholder="Old Password"
          type="password"
          id="oldPassword"
          name="oldPassword"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          {...formik.getFieldProps("oldPassword")}
          error={formik.touched.oldPassword && formik.errors.oldPassword}
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
        />
        <AboutParaTypo>New Password</AboutParaTypo>
        <TextField
          placeholder="New Password"
          type="password"
          id="newPassword"
          name="newPassword"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          {...formik.getFieldProps("newPassword")}
          error={formik.touched.newPassword && formik.errors.newPassword}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />
        <AboutParaTypo>Password Confirmation</AboutParaTypo>
        <TextField
          placeholder="Confirm Password"
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          {...formik.getFieldProps("confirmPassword")}
          error={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            marginTop: "1rem",
          }}
        >
          <StyledGreenButton
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.values.oldPassword ||
              !formik.values.newPassword ||
              !formik.values.confirmPassword
            }
          >
            Update Password
          </StyledGreenButton>
        </div>
      </form>
    </div>
  );
}

export default ConfirmPassword;

import {InputBase, styled} from "@mui/material";
import React, {useState} from "react";
import {StyledQBBlueButton, StyledQBSearchDiv,} from "../../styledcomponents/styledQBExamWidgets";
import {useNavigate} from "react-router-dom";

const DivStyle = styled("div")(({ theme }) => ({
  width: "35rem",
  [theme.breakpoints.down(600)]: {
    width: "16rem",
  },
}));
function SearchComp({ type, searchData, searchContent, student }) {
  const [search, setSearch] = useState(searchData || "");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (search.length > 2) {
      if (type) {
        searchContent(search);
      } else {
        navigate("/student/highyield/" + search);
      }
    } else {
      alert("please enter atleast 3 characters");
    }
  };
  return (
    <StyledQBSearchDiv>
      {type ? (
        <DivStyle>
          <InputBase
            fullWidth
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </DivStyle>
      ) : (
        // </DivStyle>
        <InputBase
          fullWidth
          sx={{ ml: ".10rem" }}
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      )}
      <StyledQBBlueButton style={{ outline: "none" }} onClick={handleSearch}>
        {type ? "Search" : "Search High Yield"}
      </StyledQBBlueButton>
    </StyledQBSearchDiv>
  );
}

export default SearchComp;

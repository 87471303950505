import { createSlice } from "@reduxjs/toolkit";

const planValiditySlice = createSlice({
    name: "planValidity",
    initialState: {
        data: [], // To store the plan validity data
        selectedPlanData: null
    },
    reducers: {
        setPlanValidity: (state, action) => {
            state.data = action.payload; // Updates the plan validity data
        },
        setSelectedPlanData: (state, action) => {
            state.selectedPlanData = action.payload; // Updates the selected plan data
        },
    },
});

export const { setPlanValidity, setSelectedPlanData } = planValiditySlice.actions;
export default planValiditySlice.reducer;

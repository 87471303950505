import React, {useEffect, useState} from "react";
import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {
    SettingSubjectAddApi,
    SettingSubjectApi,
    SettingSubjectDeleteApi,
    SettingSubjectEditApi,
} from "../../../http/allAdminApi";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    InputBase,
    Menu,
    MenuItem,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {Form, Modal, Switch} from "antd";
import {default as swal} from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";

function AdminMainCategory() {
  const [mainData, setMainData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [checkBox, setCheckBox] = useState(true);
  const [subId, setSubId] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [modalFor, setModalFor] = useState("Add");
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");

  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const handleClose = () => {
    setSubjectVal("");
    setOpenModal(false);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    const filteredResults = mainData?.filter((item) => {
      // return item.subject.toLowerCase().includes(value.toLowerCase());
      const lowerCaseValue = value.toLowerCase();
      return (
        (item.id && item.id.toString().includes(value)) ||
        (item.subject && item.subject.toLowerCase().includes(lowerCaseValue))
      );
    });
    setResData(filteredResults);
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      setSubjectVal("");
      setOpenModal(false);
      handleMainCategory();
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleAddNew = () => {
    let payload = {
      subject: subjectVal,
      status: checkBox,
    };
    SettingSubjectAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleEditRow = () => {
    let payload = {
      subject: subjectVal,
      subjectId: subId,
      status: checkBox,
    };
    SettingSubjectEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleSubjectSave = (Check) => {
    if (Check === "Add") {
      handleAddNew();
    } else if (Check === "Edit") {
      handleEditRow();
    }
  };
  const handleApiClose = () => {
    setOpen(false);
  };
  const handleDeleteApi = (val) => {
    let payload = {
      subjectId: val,
    };
    SettingSubjectDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        setOpenModal(false);
        setSubjectVal("");
        console.log(e);
      });
  };

  const handleMenu = (condition) => {
    setAnchorEl(null);
    if (condition == "edit") {
      setModalFor("Edit");
      setSubjectVal(paramData?.subject);
      setSubId(paramData?.id);
      setCheckBox(paramData?.status);
      setOpenModal(true);
    } else if (condition == "add") {
      setModalFor("Add");
      setOpenModal(true);
    } else if (condition === "delete") {
      swal
        .fire({
          title: "Are you sure?",
          text: "If you delete this, related main categories, sub categories, questions and answers also will be deleted. You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((willDelete) => {
          if (willDelete.isConfirmed) {
            handleDeleteApi(paramData?.id);
          }
        });
    }
  };

  const handleMainCategory = () => {
    SettingSubjectApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setMainData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(true);
        }
      });
  };

  useEffect(() => {
    handleMainCategory();
  }, []);

  const onChange = (checked) => {
    setCheckBox(!checkBox);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 0.6,
      hide: true,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              // color: "#fff",
              backgroundColor: params.row.status ? "green" : "red",
              // padding: "3px",
              // textAlign: "center",
              // borderRadius: "50px",
              // padding: ".2rem 1rem"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "InActive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "age",
      headerName: "Action",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
                margin: "1rem",
              }}
              onClick={(e) => {
                setParamData(params.row);
                setAnchorEl(e.currentTarget);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Subject
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleMenu("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <Modal
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add" ? "Add Category" : "Edit Category"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <TextField
          // label="Subject"
          placeholder="Subject"
          type="text"
          size="small"
          value={subjectVal}
          id="name"
          style={{ margin: ".5rem 0 1.5rem 0" }}
          fullWidth
          onChange={(e) => setSubjectVal(e.target.value)}
          sx={{
            "& .MuiInputBase-input": {
              border: "1px solid #000",
              padding: "0.5rem 0.3rem 0.5rem 0.2rem",
              color: "#000",
            },
            "& .MuiInputLabel-root": {
              color: "#000",
            },
            "& .MuiInputLabel-formControl": {
              top: "-8px",
            },
          }}
        ></TextField>
        <Form.Item label="Status">
          <Switch checked={checkBox} onChange={onChange} />
        </Form.Item>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            style={{
              textTransform: "none",
              backgroundColor: subjectVal === "" ? "white" : "#2c3643",
              color: subjectVal === "" ? "Black" : "#fff",
              outline: "none",
            }}
            disabled={subjectVal == ""}
            onClick={() => handleSubjectSave(modalFor)}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleMenu("edit")}>Edit</MenuItem>
        <MenuItem onClick={() => handleMenu("delete")}>Delete</MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminMainCategory;

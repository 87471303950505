import {ChevronRight} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ChartComponent from "../student/widgets/ChartComponent";
import QuestionSelection from "../student/widgets/QuestionSelection";
import {StyledButton} from "../styledcomponents/styledQBExamWidgets";
import {categoryCountApi, categorySubCountApi, qbselectedquestionsApi,} from "../../http/allApis";
import ModelFile from "../landing/ModelFile";
import {useSelector} from "react-redux";

function FilterQuestions() {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [maxnumber, setMaxnumber] = useState(0);
  const [tqdisplay, setTqdisplay] = useState(0);
  const [aqdisplay, setAqdisplay] = useState(0);
  const [dropvalue, setDropValue] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [questiondata, setQuestiondata] = useState([]);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalWrong, setTotalWrong] = useState(0);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  // Access the plan validity data from Redux
  const planValidity = useSelector((state) => state.planValidity.selectedPlanData);
  useEffect(() => {
    getCategory();
  }, []);
  const getMaxnumber = (dropvalue, category1) => {
    setDropValue(dropvalue);
    setSelectedCategory(category1);
    let payload = {
      category: category1,
      dropvalue: dropvalue,
      planId: planValidity?.id,
    };
    categorySubCountApi(payload)
      .then((response) => {
        setMaxnumber(response.data.finalcount);
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        } else {
          console.log("Error");
        }
      });
  };

  const getCategory = () => {
    let payload = {
      planId: planValidity?.id,
    };

    categoryCountApi(payload)
      .then((response) => {
        const result = response.data.result;
        setCategory(result || []);
        setTotalCorrect(response.data.totalCorrect);
        setTotalWrong(response.data.totalWrong);
        setTqdisplay(
          result.reduce(
            (prevTqdisplay, item) => prevTqdisplay + item.totalQuestionCount,
            0,
          ),
        );
        setAqdisplay(
          result.reduce((prevTqdisplay, item) => prevTqdisplay + item.count, 0),
        );
      })
      .catch((response) => {
        setModelWin(true);
        setCatchError(response?.response?.data?.message);
      });
  };

  const startQuiz = () => {
    let payload = {
      dropvalue: dropvalue,
      category: selectedCategory,
      planId: planValidity?.id,
    };
    qbselectedquestionsApi(payload)
      .then((response) => {
        let mydata = response.data.result.map((item) => item.id);
        let mydataStartnumber = response.data.result.map(
          (item) => item.startNumber,
        );
        setQuestiondata(...questiondata, mydata, mydataStartnumber);
        let propsData = {
          questionIds: mydata,
          dropvalue: dropvalue,
          planId: planValidity?.id,
          startnumber: mydataStartnumber,
          componentIdentity: "startQuiz",
        };
        navigate("/student/quiz", {
          state: propsData,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Typography variant="h6" fontWeight={500} fontSize={18} color="#31a188">
        Filter your questions below ({maxnumber} questions selected):
      </Typography>
      <Box>
        <ChartComponent
          category={category}
          maxnumber={maxnumber}
          tqdisplay={tqdisplay}
          aqdisplay={aqdisplay}
          getMaxnumber={getMaxnumber}
          dropvalue={dropvalue}
          totalCorrect={totalCorrect}
          totalWrong={totalWrong}
        />
      </Box>
      <Box sx={{ mt: "1rem" }}>
        <QuestionSelection
          dropvalue={dropvalue}
          setDropValue={(val) => setDropValue(val)}
        />
      </Box>
      <div style={{ marginTop: "1rem" }}>
        <StyledButton
          style={{ outline: "none" }}
          disabled={maxnumber === 0}
          onClick={startQuiz}
        >
          Start the questions <ChevronRight />
        </StyledButton>
      </div>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default FilterQuestions;

import {Grid, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {useState} from "react";
import {StyledBeforeFooterDiv, StyledFooterText,} from "../styledcomponents/styledDBwidgets";
import {useNavigate} from "react-router-dom";
import {socialSignUpApi} from "../../http/allApis";
import {SocialIcon} from "react-social-icons";

function FooterBeforeLogin({ buttonFor, callBackFunction, handleAlert }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [modelWin, setModelWin] = useState(false);

  const [resp, setResp] = useState();
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "2841983716098248",
      cookie: true,
      xfbml: true,
      version: "v12.0",
    });

    window.FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        console.log("User is logged in");
      } else {
        console.log("User is not logged in");
      }
    });
  };

  return (
    <StyledBeforeFooterDiv>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div style={{ textAlign: isSmall ? "center" : "start" }}>
            <Typography variant={"caption"}>
              &copy;2024 PGNEET. All rights reserved.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SocialIcon
              network="facebook"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://www.facebook.com/PGNeetnet-107422365370696"
            />
            <SocialIcon
              network="instagram"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://www.instagram.com/pgneet_net/"
            />
            <SocialIcon
              network="x"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://twitter.com/pgneet_net"
            />
            <SocialIcon
              network="snapchat"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
            />
            <SocialIcon
              network="telegram"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
              url="https://t.me/pgneet_net"
            />
            <SocialIcon
              network="whatsapp"
              style={{ margin: "0 .25rem", width: 30, height: 30 }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div
            style={{
              display: "flex",
              justifyContent: isSmall ? "center" : "end",
              marginTop: ".2rem",
            }}
          >
            <StyledFooterText
              onClick={() => navigate("/terms")}
              variant={"caption"}
              style={{ marginRight: "1rem" }}
            >
              Terms and Conditions
            </StyledFooterText>
            <StyledFooterText
              onClick={() => navigate("/privacy-policy")}
              variant={"caption"}
              style={{ marginRight: "1rem" }}
            >
              Privacy Policy
            </StyledFooterText>
            <StyledFooterText
              onClick={() => navigate("/copy-rights")}
              variant={"caption"}
            >
              Copyright Credits
            </StyledFooterText>
          </div>
        </Grid>
      </Grid>
    </StyledBeforeFooterDiv>
  );
}

export default FooterBeforeLogin;

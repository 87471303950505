import React, {useState} from "react";
import {AboutParaTypo, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {Alert, InputBase, Snackbar} from "@mui/material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
import {updatePassApi} from "../../../http/allApis";
import ModelFile from "../../landing/ModelFile";

function AdminChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alertstatus, setAlertstatus] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const handleSubmit = () => {
    if (newPassword === confirmPassword) {
      let payload = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      updatePassApi(payload)
        .then((res) => {
          if (res?.data?.status === "Success") {
            setMessage("Password updated successfully!");
            setAlertstatus("success");
            setOpen(true);
            setOldPassword("");
            setNewPassword("");
            setConfirmPassword("");
          } else {
            setMessage("Error!");
            setAlertstatus("warning");
            setOpen(true);
          }
        })
        .catch((e) => {
          if (e?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(e?.response?.data?.message);
          } else {
            setMessage("Error!");
            setAlertstatus("warning");
            setOpen(true);
          }

          console.log(e);
        });
    } else {
      alert(
        "Oops! It looks like the passwords you entered don't match. Please try again.",
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Change Password
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <AboutParaTypo style={{ color: "black" }}>Old Password</AboutParaTypo>
        <InputBase
          placeholder="Old Password"
          type="password"
          value={oldPassword}
          id="name"
          style={{
            // width: "35rem",
            lineHeight: "1em",
            color: "#000",
            border: "1px solid #000",
            margin: ".5rem 0 1.5rem 0",
            padding: ".3rem 0 .3rem .2rem",
          }}
          fullWidth
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <AboutParaTypo style={{ color: "black" }}>New Password</AboutParaTypo>
        <InputBase
          placeholder="New Password"
          type="password"
          value={newPassword}
          id="name"
          style={{
            // width: "35rem",
            lineHeight: "1em",
            color: "#000",
            border: "1px solid #000",
            margin: ".5rem 0 1.5rem 0",
            padding: ".3rem 0 .3rem .2rem",
          }}
          fullWidth
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <AboutParaTypo style={{ color: "black" }}>
          Password Confirmation
        </AboutParaTypo>
        <InputBase
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          id="name"
          // style={{ margin: ".5rem 0 1.5rem 0" }}
          style={{
            // width: "35rem",
            lineHeight: "1em",
            color: "#000",
            border: "1px solid #000",
            margin: ".5rem 0 1.5rem 0",
            padding: ".3rem 0 .3rem .2rem",
          }}
          fullWidth
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            marginTop: "1rem",
          }}
        >
          <StyledGreenButton
            disabled={
              oldPassword === "" || newPassword === "" || confirmPassword === ""
            }
            onClick={handleSubmit}
          >
            Update Password
          </StyledGreenButton>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminChangePassword;

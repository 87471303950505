import React, {useEffect} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import dayjs from "dayjs";

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import {Line} from "react-chartjs-2";
import {AboutParaTypo} from "../../styledcomponents/beforeLoginWidgets";
import useFetch from "../../customHooks/useFetch";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Performance over time",
    },
  },
};

function PerformanceOverTime() {
  const [mylabel, setMylabel] = React.useState([]);
  const [data1, setData1] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const { loading: chartLoad, resData: chartResdata } = useFetch("/charttime");

  useEffect(() => {
    if (chartResdata) {
      let mydata = chartResdata?.mydatescore?.map((item) =>
        dayjs(item?.examDate?.date).format("DD-MM-YYYY"),
      );
      setMylabel(mydata);
      let mydata1 = chartResdata?.mydatescore?.map((item) =>
        parseInt((item?.totalmark * 100) / chartResdata?.myquestions),
      );
      setData1(mydata1);
      let mydata2 = chartResdata?.mydatescore?.map((item) =>
        parseInt(
          (chartResdata?.totalright * 100) / chartResdata?.totalquestions,
        ),
      );
      setData2(mydata2);
    }
  }, [chartResdata]);

  const labels = mylabel;
  const data = {
    labels,
    datasets: [
      {
        label: "Your Score",
        data: data1,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Average User Score",
        data: data2,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return chartLoad ? (
    <Backdrop open={chartLoad} invisible>
      <CircularProgress style={{ color: "#f38424" }} />
    </Backdrop>
  ) : (
    <div>
      <Line options={options} data={data} />
      <Box mt={2} mb={2}>
        <AboutParaTypo variant="body2" gutterBottom>
          The above chart shows how your cumulative average score has changed
          since you started answering questions. It is based on first attempts
          to allow meaningful comparisons with other users. If at any point
          you've reset your account the data will also have been reset.
        </AboutParaTypo>
      </Box>
    </div>
  );
}

export default PerformanceOverTime;

import {Box, Divider, Typography, useTheme} from "@mui/material";
import {Select} from "antd";
import React from "react";

function QuestionBank({
  qbdata,
  handleChangeQuestion,
  selectedqbdrop,
  planValidity,
}) {
  const theme = useTheme();

  // const isOptionDisabled = (option) => {
  //   return planValidity.some((validity) => validity.exam1 === "Question Bank");
  // };
  const isOptionDisabled = (option) => {
    const isValid = planValidity.map((validity) => {
      const expiryDate = new Date(validity?.expiry?.date);
      const currentDate = new Date();
      return validity.exam1 === "Question Bank" && expiryDate > currentDate;
    });
    return isValid.some((val) => val);
  };

  return (
    <Box
      style={{
        border: "1.5px solid #EBECF0",
        borderRadius: "6px",
        marginTop: ".5rem",
      }}
    >
      <div
        style={{
          padding: ".5rem",
          backgroundColor:
            theme.palette.mode === "dark" ? "#2d2e34f7" : "#f1f1f1",
        }}
      >
        <Typography variant="body2" fontWeight={600} marginLeft={1}>
          Question Bank
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: ".3rem",
          backgroundColor:
            theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
        }}
      >
        <Select
          size="large"
          placeholder="Please select"
          onChange={handleChangeQuestion}
          style={{
            width: "100%",
          }}
          value={selectedqbdrop}
          options={qbdata.map((option) => ({
            ...option,
            disabled: isOptionDisabled(option),
          }))}
        />
      </div>
    </Box>
  );
}

export default QuestionBank;

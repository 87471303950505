import {Box, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme,} from "@mui/material";
import React, {useState} from "react";
import PropTypes from "prop-types";
import FilterQuestions from "../tabs/FilterQuestions";
import SubDays from "./widgets/SubDays";
import PerformanceContent from "./widgets/PerformanceContent";
import SearchComp from "./widgets/SearchComp";
import PassBot from "./widgets/PassBot";
import TimedTest from "../tabs/TimedTest";
import HighYield from "../tabs/HighYield";
import {useLocation} from "react-router-dom";
import useFetch from "../customHooks/useFetch";
import {useSelector} from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function QbExam() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(900));
  const [value, setValue] = useState(0);
  const { state } = useLocation();
  // Access the plan validity data from Redux
  const planValidity = useSelector((state) => state.planValidity.selectedPlanData);
  let planDetails = state;
  if (value === 1) {
    sessionStorage.setItem("TimedTest", "Yes");
  } else if (value !== 1) {
    sessionStorage.removeItem("TimedTest");
  }
  const { loading, resData: percentageRes } = useFetch(
    "/chartsummary",
    state?.PlanValidity,
      planValidity?.id,
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ marginTop: ".7rem" }}>
      <Grid container spacing={isSmall ? 0 : 3}>
        <Grid item xs={12} md={6}>
          <div style={{ padding: "1px 0" }}>
            <Typography variant="h5" fontWeight={500} color={"#31a188"}>
              Question Bank
            </Typography>
            <Box sx={{ width: "100%", mt: "1rem" }}>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "transparent",
                      // borderBloColor: "red",
                      borderColor: "green",
                    },
                  }}
                  sx={{
                    overflow: "hidden",
                    "& .MuiTab-root": {
                      color: "#31a188",
                      borderBottom: "2px solid #EBECF0",
                      textTransform: "none",
                    },
                    "& .Mui-selected": {
                      borderTop: "2px solid #EBECF0",
                      borderRight: "2px solid #EBECF0",
                      borderLeft: "2px solid #EBECF0",
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      borderBottom: "none",
                      borderColor: "#EBECF0",
                      outline: "none",
                      color: theme.palette.text.primary,
                    },
                  }}
                  textColor="#31a188"
                >
                  <Tab disableRipple label="Questions" {...a11yProps(0)} />
                  <Tab disableRipple label="Timed Test" {...a11yProps(1)} />
                  <Tab disableRipple label="High Yield" {...a11yProps(2)} />
                  {Array.from(new Array(3)).map((obj) => (
                    <Tab disabled label="         " {...a11yProps(2)} />
                  ))}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <div style={{ padding: "1rem 0" }}>
                  <FilterQuestions qbExamState={state} />
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ padding: "1rem 0" }}>
                  <TimedTest planId={state?.planId} />
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div style={{ padding: "1rem 0" }}>
                  <HighYield />
                </div>
              </TabPanel>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div
            style={{
              padding: ".5rem 0",
            }}
          >
            <SubDays expiryDate={state} />
            <PerformanceContent percentage={percentageRes?.myscore} />
            <SearchComp />
            <PassBot />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default QbExam;

import React, {useEffect, useState} from "react";
// import useStyle from "./AdminQueSetStyle";
// import { textfieldSty } from "./AdminQueSetStyle";
import {Alert, Backdrop, Button, CircularProgress, MenuItem, Snackbar, styled, TextField,} from "@mui/material";
import {MockQuestionSetLimit, QuestionSetLimit, SettingSubjectApi,} from "../../../http/allAdminApi";
import {TransparentHead} from "../../styledcomponents/beforeLoginWidgets";

const textfieldSty = styled("textfield")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.between(769, 1024)]: {
    width: "80%",
  },
  [theme.breakpoints.down(769)]: {
    width: "100%",
  },
  [theme.breakpoints.up(1025)]: {
    width: "50%",
  },
}));
const AdminQuesSetLimit = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [limitVal, setLimitVal] = useState("");
  const [catOptions, setCatOptions] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  useEffect(() => {
    SettingSubjectApi().then((res) => {
      setCatOptions(res.data.data);
    });
  }, []);

  const handleSubmit = () => {
    let payload = {
      mainCategoryId: subject,
      limitVal: limitVal,
      type: "qb",
    };
    let payloadMock = {
      limitVal: limitVal,
      type: "mock",
    };

    if (type !== "mock") {
      QuestionSetLimit(payload).then((res) => {
        if (res.data.status === "success") {
          setLimitVal("");
          setAlertstatus("success");
          setOpen(true);
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      });
    } else {
      MockQuestionSetLimit(payloadMock).then((res) => {
        if (res.data.status === "success") {
          setLimitVal("");
          setAlertstatus("success");
          setOpen(true);
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      });
    }
  };

  const handleApiClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        {type !== "mock" ? "QUESTIONS" : " MOCK QUESTIONS"} SET LIMIT
      </TransparentHead>
      <div
        style={{
          margin: ".7rem 0",
          backgroundColor: "#fff",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {type === "mock" ? null : (
          <TextField
            select
            label="Subject"
            size="small"
            value={subject}
            id="name"
            style={{
              margin: ".5rem 0 1.5rem 0",
              width: "50%",
            }}
            onChange={(e) => setSubject(e.target.value)}
          >
            <MenuItem value={""} />
            {catOptions?.map((item, id) => (
              <MenuItem key={id} value={item.id}>
                {item.subject}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          type="number"
          label="Set limit"
          size="small"
          value={limitVal}
          id="name"
          style={{
            margin: ".5rem 0 1.5rem 0",
            width: "50%",
          }}
          onChange={(e) => setLimitVal(e.target.value)}
        ></TextField>

        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button
            style={{
              marginTop: ".8rem",
              textTransform: "none",
              backgroundColor: "#2c3643",
              color: "#fff",
              outline: "none",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminQuesSetLimit;

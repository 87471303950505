import React, {useEffect, useState} from "react";
import {
    AboutBannerHead,
    ArticalFileDiv,
    BlogDiv,
    BlogWelDiv,
    BlogWelParaTypo,
    BlueContainerSection,
    RespDiv,
} from "../styledcomponents/beforeLoginWidgets";
import {blogWelcome} from "../Common/beforeLoginPageContents";
import {Grid, TextField} from "@mui/material";
import {BlogSearchBar} from "./BlogSearchBar";
import {BlogDetails} from "./BlogDetails";
import {Select} from "antd";
import ArticleFile from "./ArticleFile";
import {dashboardBlogHeading, handleBlogHeadinghApi, handleBlogMonthhApi,} from "../../http/allAdminApi";
import {useNavigate} from "react-router-dom";
import {useSharedValue} from "../Common/SharedValueContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebook, faInstagram, faTelegram, faWhatsapp, faXTwitter,} from "@fortawesome/free-brands-svg-icons";
import {Button} from "@material-ui/core";
import {addBlogComment} from "../../http/allApis";
import {StyledGreenButton} from "../styledcomponents/styledDBwidgets";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

function Blog() {
  const [selectedValue, setSelectedValue] = useState(null);
  const [blogList, setBlogList] = useState([]);
  const [boolean, setBoolean] = useState(false);
  const navigate = useNavigate();
  const [blogHeading, setBlogHeading] = useState([]);
  const [readMoreBoolen, setReadmoreBoolen] = useState(true);
  const [articleObject, setArticleObject] = useState("");
  const [welcomeBoolean, setWelcomeBoolean] = useState(false);
  const { sharedValue, setSharedValue } = useSharedValue();
  const [isCopied, setIsCopied] = useState(false);
  const [valueCom, setValueCom] = useState({
    comment: "",
    name: "",
    email: "",
    website: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");

  useEffect(() => {
    if (window.location.pathname === "/blog") {
      setWelcomeBoolean(false);
      setReadmoreBoolen(true);
      dashboardBlogHeading().then((res) => {
        if (res?.data?.status === "Success") {
          setBlogHeading(res?.data?.data);
          handleHeadClick({ id: 0 }, "blogSearchBar");
        }
      });
    } else if (window.location.pathname === "/blog/welcome") {
      setWelcomeBoolean(true);
      setReadmoreBoolen(true);
    }
  }, [window.location.pathname]);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    const selectedBlog = blogHeading.find(
      (content) => content.blogHeading === value,
    );
    if (selectedBlog) {
      handleHeadClick(selectedBlog, "blogSearchBar");
    }
    setBoolean(true);
  };

  const handleReadMore = (articleObject) => {
    setReadmoreBoolen(false);
    setArticleObject(articleObject);
    setSharedValue(articleObject);
    navigate(`/blog/article/${articleObject.id}`, { state: articleObject });
  };

  const handleHeadClick = (value, condition, BooleanValue) => {
    navigate(`/blog`);
    let condBoolean = BooleanValue === "blogMonth" ? false : BooleanValue;
    setWelcomeBoolean(condBoolean);
    const payload =
      condition === "blogSearchBar"
        ? { id: value?.id }
        : {
            month: value?.month,
            year: condition,
          };

    const apiCall =
      condition === "blogSearchBar"
        ? handleBlogHeadinghApi
        : handleBlogMonthhApi;

    apiCall(payload).then((res) => {
      if (res?.data?.status === "Success") {
        setBlogList(res.data.data);
      }
    });
  };

  const handleCommentvalue = (e, cond) => {
    const { value } = e.target;
    let updatedValueCom = { ...valueCom, [cond]: value };
    setValueCom(updatedValueCom);
  };

  const handleSubmit = () => {
    addBlogComment(valueCom, 0).then((res) => {
      if (res?.data?.status === "Success") {
        setValueCom({
          comment: "",
          name: "",
          email: "",
          website: "",
        });
        setSuccessMsg(res?.data?.message);
        setAlertstatus("success");
        setOpen(true);
      } else {
        setSuccessMsg(res?.data?.error?.description);
        setAlertstatus("warning");
        setOpen(true);
      }
    });
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    });
  };

  const handleIcon = (cond) => {
    const sharedMessage = `${sharedValue?.title} - ${window.location.href}`;
    if (cond === "whatapp") {
      window.open(
        `https://web.whatsapp.com/send?text=${encodeURIComponent(sharedMessage)}`,
      );
    } else if (cond === "facebook") {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedMessage)}`,
      );
    } else if (cond === "instgram") {
      window.open("https://www.instagram.com/pgneet_net/");
    } else if (cond === "twitter") {
      window.open("https://twitter.com/pgneet_net");
    }
  };

  return (
    <div>
      <BlueContainerSection>
        <AboutBannerHead variant="h4" fontWeight={"bold"} color={"#fff"}>
          Blog
        </AboutBannerHead>
      </BlueContainerSection>
      <BlogDiv style={{ width: "100%", padding: "2rem 9%" }}>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid>
              {welcomeBoolean ? (
                <BlogWelDiv>
                  {blogWelcome &&
                    Object.values(blogWelcome).map((content, index) => (
                      <React.Fragment key={index}>
                        <div>
                          <BlogWelParaTypo variant="p">
                            {content}
                          </BlogWelParaTypo>
                        </div>
                        {index !== Object.values(blogWelcome).length - 1 && (
                          <div>&nbsp;</div>
                        )}
                      </React.Fragment>
                    ))}
                  <BlogWelParaTypo style={{ marginTop: "1rem" }}>
                    Warm regards,
                  </BlogWelParaTypo>
                  <BlogWelParaTypo>PGNEET© Team</BlogWelParaTypo>
                  <div>
                    <StyledGreenButton
                      style={{
                        marginTop: "2rem",
                        outline: "none",
                      }}
                      onClick={() => navigate("/blog")}
                    >
                      Back{" "}
                    </StyledGreenButton>
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        Share :
                      </Grid>
                      <Grid item xs={7}>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{
                            width: 10,
                            height: 10,
                            cursor: "pointer",
                          }}
                          onClick={copyUrlToClipboard}
                        />
                        <FontAwesomeIcon
                          icon={faFacebook}
                          style={{
                            margin: "0 .25rem",
                            width: 20,
                            height: 20,
                          }}
                          onClick={() => handleIcon("facebook")}
                        />
                        <FontAwesomeIcon
                          icon={faInstagram}
                          style={{
                            margin: "0 .25rem",
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                          }}
                          onClick={() => handleIcon("instgram")}
                        />
                        <FontAwesomeIcon
                          icon={faXTwitter}
                          onClick={() => handleIcon("twitter")}
                          style={{
                            margin: "0 .25rem",
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTelegram}
                          style={{
                            margin: "0 .25rem",
                            width: 20,
                            height: 20,
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{
                            margin: "0 .25rem",
                            cursor: "pointer",
                            width: 20,
                            height: 20,
                          }}
                          onClick={() => handleIcon("whatapp")}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {isCopied && (
                    <span
                      style={{
                        color: "rgb(243, 132, 36)",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    >
                      Copied!
                    </span>
                  )}
                  <div style={{ fontSize: "20px", marginTop: "2rem" }}>
                    Leave a Comment
                  </div>
                  <div style={{ marginTop: ".5rem" }}>
                    <textarea
                      style={{
                        width: "100%",
                        height: "150px",
                        borderColor: "gray",
                        padding: "10px",
                      }}
                      name="body"
                      placeholder="Write a Comments........"
                      onChange={(e) => handleCommentvalue(e, "comment")}
                      value={valueCom.comment}
                    />
                    <div style={{ marginTop: "1rem" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            label="Email-Id*"
                            type={"email"}
                            size="Normal"
                            fullWidth
                            value={valueCom.email}
                            onChange={(e) => handleCommentvalue(e, "email")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Name*"
                            size="Normal"
                            fullWidth
                            value={valueCom.name}
                            onChange={(e) => handleCommentvalue(e, "name")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Website"
                            size="Normal"
                            fullWidth
                            value={valueCom.website}
                            onChange={(e) => handleCommentvalue(e, "website")}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <StyledGreenButton
                      style={{
                        marginTop: "2rem",
                        outline: "none",
                        marginLeft: "1rem",
                      }}
                      disabled={
                        emailError ||
                        valueCom.comment === "" ||
                        valueCom.email === "" ||
                        valueCom.name === ""
                      }
                      onClick={handleSubmit}
                    >
                      Posts Comment
                    </StyledGreenButton>
                  </div>
                </BlogWelDiv>
              ) : (
                <>
                  {readMoreBoolen ? (
                    <BlogDetails
                      blogList={blogList}
                      handleReadMore={handleReadMore}
                    />
                  ) : (
                    <ArticleFile
                      articleData={articleObject}
                      setReadmoreBoolen={setReadmoreBoolen}
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <BlogSearchBar
              setBlogList={setBlogList}
              handleHeadClick={handleHeadClick}
              handleWelcome={() => setWelcomeBoolean(true)}
            />
          </Grid>
        </Grid>
      </BlogDiv>
      {readMoreBoolen ? (
        <RespDiv>
          <Select
            size="large"
            placeholder="Select Categories"
            onChange={handleSelectChange}
            style={{ width: "100%" }}
            value={selectedValue}
          >
            {blogHeading.map((content, index) => (
              <Select.Option key={index} value={content.blogHeading}>
                {content.blogHeading}
              </Select.Option>
            ))}
          </Select>
          <div style={{ marginTop: "2rem" }}>
            {blogList && blogList.length !== 0 ? (
              <Grid container spacing={3}>
                {blogList.map((item, index) => (
                  <Grid item key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={item.titleImage}
                        alt="Blog"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        style={{
                          padding: "1rem",
                          width: "100%",
                          height: "auto",
                          overflow: "hidden",
                        }}
                      >
                        <div style={{ fontSize: "20px", color: "#f38424" }}>
                          {item.title}
                        </div>
                        <div
                          style={{
                            fontFamily: "Arial",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.description?.slice(0, 250),
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "1rem",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              textTransform: "none",
                              outline: "none",
                              fontSize: "10px",
                            }}
                            onClick={() => handleReadMore(item)}
                          >
                            Read More
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5rem",
                }}
              >
                <span style={{ color: "gray", fontSize: "15px" }}>
                  Select Categories
                </span>
              </div>
            )}
          </div>
        </RespDiv>
      ) : (
        <ArticalFileDiv>
          <ArticleFile
            articleData={articleObject}
            setReadmoreBoolen={setReadmoreBoolen}
          />
        </ArticalFileDiv>
      )}
    </div>
  );
}
export default Blog;

// import React from "react";
import React, {useCallback, useEffect, useState} from "react";
import {AboutParaTypo, AboutParaTypoDiv, TransparentHead,} from "../../styledcomponents/beforeLoginWidgets";
import {handleinstructionApi,} from "../../../http/allAdminApi";
import {
  Alert,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import {Add, ArrowDropDown} from "@mui/icons-material";
import {StyledGreenButton} from "../../styledcomponents/styledDBwidgets";
// import { Modal } from "antd";
import Swal from "sweetalert2";
import AdminDataGrid from "../widgets/AdminDataGrid";
import ModelFile from "../../landing/ModelFile";
import {Modal} from "antd";
import {useDropzone} from "react-dropzone";
import JoditEditor from "jodit-react";

export const AdminInstruction = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageDesign, setImageDesign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [paramData, setParamData] = useState();
  const [searchVal, setSearchVal] = useState("");
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [modalFor, setModalFor] = useState("Add");
  const [openModal, setOpenModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isCancelBtnEnabled, setIsCancelBtnEnabled] = useState(false);
  const [instructionMessageObj, setInstructionMessageObj] = useState({
    name: "",
    description: "",
    textColor: "#ffffff",
    backgroundColor: "#652020",
    backgroundType: true,
    announcementStatus: false,
    backgroundImage: "",
  });
  const [bannerId, setBannerId] = useState("");
  const theme = useTheme();
  const handleImageDesign = () => {
    setImageDesign(false);
    setIsCancelBtnEnabled(true);
  };
  const handleCancelImageEdit = () => {
    setImageDesign(true);
    setIsCancelBtnEnabled(false);
  };
  const onDrop = useCallback((files) => {
    setFiles(
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  }, []);

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          width={"30%"}
          height={"30%"}
          src={file.preview}
          onLoad={() => {
            setIsCancelBtnEnabled(false);
            URL.revokeObjectURL(file.preview);
          }}
          alt=""
        />
      </div>
    </div>
  ));
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".gif", ".jpeg", ".pjpeg", ".png"],
    },
  });
  const handleInputChange = (event) => {
    const value = event.target.value;
    const filteredResults = rowData?.filter((item) => {
      let finalValue = value.replace(/\s/g, "").toLowerCase();
      let finalinstHeading = item.instHeading.replace(/\s/g, "").toLowerCase();
      return (
        item.id.toString().includes(value) ||
        finalinstHeading.toLowerCase().includes(finalValue.toLowerCase())
      );
    });
    setSearchVal(value);
    setResData(filteredResults);
  };
  const handleInstructionMenu = (condition, bannerObject) => {
    setAnchorEl(null);
    if (condition === "Edit") {
      setModalFor("Edit");
      setOpenModal(true);
      setBannerId(bannerObject.id);
      setInstructionMessageObj({
        name: bannerObject.name,
        description: bannerObject.description,
        textColor: bannerObject.textColor,
        backgroundColor: bannerObject.colour,
        backgroundType: bannerObject.backgroundType,
        announcementStatus: bannerObject.announcementStatus,
        backgroundImage: bannerObject.backgroundImage,
      });
      const isImageHave = bannerObject.backgroundImage ? true : false;
      setImageDesign(isImageHave);
      setIsCancelBtnEnabled(isImageHave);
    } else if (condition === "add") {
      setModalFor("Add");
      setOpenModal(true);
      setImageDesign(false);
      setIsCancelBtnEnabled(false);
    }
  };

  const handleDelete = () => {
    setAnchorEl(null);
    let paylaod = {
      flag: "delete",
      id: paramData?.id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        handleinstructionApi(paylaod).then((res) => {
          if (res?.data?.status === "Success") {
            handleBannerAnnouncement();
          }
        });
      }
    });
  };

  const handleSucces = () => {
    setOpenModal(false);
    setAnchorEl(null);
    setFiles([]);
    handleBannerAnnouncement();
    setInstructionMessageObj({
      name: "",
      description: "",
      textColor: "#ffffff",
      backgroundColor: "#652020",
      backgroundType: true,
      announcementStatus: false,
      backgroundImage: "",
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setFiles([]);
  };

  const handleApiClose = () => {
    setOpen(false);
  };

  const handleAddApi = () => {
    // if (!instructionMessageObj.backgroundType) {
    const imageFile = files[0];
    const addInstPayload = new FormData();
    addInstPayload.append("name", instructionMessageObj.name);
    addInstPayload.append("description", instructionMessageObj.description);
    addInstPayload.append("flag", modalFor);
    addInstPayload.append("textColor", instructionMessageObj.textColor);
    addInstPayload.append(
      "announcementStatus",
      instructionMessageObj.announcementStatus,
    );
    addInstPayload.append(
      "backgroundType",
      instructionMessageObj.backgroundType,
    );
    addInstPayload.append(
      "backgroundColor",
      !instructionMessageObj.backgroundType
        ? " #ffffff"
        : instructionMessageObj.backgroundColor,
    );
    addInstPayload.append(
      "backgroundImage",
      instructionMessageObj.backgroundType ? "no Image" : imageFile,
    );

    handleinstructionApi(addInstPayload)
      .then((res) => {
        if (res.data.status === "Success") {
          setSuccessMsg(res?.data?.message);
          setAlertstatus("success");
          setOpen(true);
          setLoading(false);
          handleSucces();
        } else {
          setSuccessMsg(res?.data?.error?.description);
          setAlertstatus("warning");
          setOpen(true);
          return Promise.reject(res);
        }
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        } else {
          setSuccessMsg(response.data.error.description);
          setAlertstatus("warning");
          setOpen(true);
        }
      });
    // };
    // reader.readAsDataURL(imageFile);
    // } else {
    //   handleinstructionApi(payload)
    //     .then((res) => {
    //       if (res.data.status === "Success") {
    //         setSuccessMsg(res?.data?.message);
    //         setAlertstatus("success");
    //         setOpen(true);
    //         setLoading(false);
    //         handleSucces();
    //       } else {
    //         setSuccessMsg(res?.data?.error?.description);
    //         setAlertstatus("warning");
    //         setOpen(true);
    //         return Promise.reject(res);
    //       }
    //     })
    //     .catch((response) => {
    //       if (response?.response?.data?.status === 500) {
    //         setModelWin(true);
    //         setCatchError(response?.response?.data?.message);
    //       } else {
    //         setSuccessMsg(response.data.error.description);
    //         setAlertstatus("warning");
    //         setOpen(true);
    //       }
    //     });
    // }
  };
  const handleEditApi = () => {
    if (imageDesign) {
      const editInstPayload = new FormData();
      editInstPayload.append("name", instructionMessageObj.name);
      editInstPayload.append("id", bannerId);
      editInstPayload.append("description", instructionMessageObj.description);
      editInstPayload.append("flag", modalFor);
      editInstPayload.append("textColor", instructionMessageObj.textColor);
      editInstPayload.append(
        "announcementStatus",
        instructionMessageObj.announcementStatus,
      );
      editInstPayload.append(
        "backgroundType",
        instructionMessageObj.backgroundType,
      );
      editInstPayload.append(
        "backgroundColor",
        instructionMessageObj.backgroundColor,
      );
      editInstPayload.append(
        "backgroundImage",
        instructionMessageObj.backgroundImage,
      );
      // let payload = {
      //   id: bannerId,
      //   name: instructionMessageObj.name,
      //   description: instructionMessageObj.description,
      //   flag: modalFor,
      //   textColor: instructionMessageObj.textColor,
      //   announcementStatus: instructionMessageObj.announcementStatus,
      //   backgroundType: instructionMessageObj.backgroundType,
      //   backgroundColor: instructionMessageObj.backgroundColor,
      //   backgroundImage: instructionMessageObj.backgroundImage
      // };

      handleinstructionApi(editInstPayload)
        .then((res) => {
          if (res.data.status === "Success") {
            setSuccessMsg(res?.data?.message);
            setAlertstatus("success");
            setOpen(true);
            setLoading(false);
            handleSucces();
          } else {
            setSuccessMsg(res?.data?.error?.description);
            setAlertstatus("warning");
            setOpen(true);
            return Promise.reject(res);
          }
        })
        .catch((response) => {
          if (response?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(response?.response?.data?.message);
          } else {
            setSuccessMsg(response.data.error.description);
            setAlertstatus("warning");
            setOpen(true);
          }
        });
    } else {
      // let base64Image;
      // let payload = {
      // id: bannerId,
      // name: instructionMessageObj.name,
      // description: instructionMessageObj.description,
      // flag: modalFor,
      // textColor: instructionMessageObj.textColor,
      // announcementStatus: instructionMessageObj.announcementStatus,
      // backgroundType: instructionMessageObj.backgroundType,
      // backgroundColor: instructionMessageObj.backgroundColor,
      // backgroundImage: base64Image
      // };
      const editInstPayload = new FormData();
      const imageFile = files[0];

      editInstPayload.append("name", instructionMessageObj.name);
      editInstPayload.append("id", bannerId);
      editInstPayload.append("description", instructionMessageObj.description);
      editInstPayload.append("flag", modalFor);
      editInstPayload.append("textColor", instructionMessageObj.textColor);
      editInstPayload.append(
        "announcementStatus",
        instructionMessageObj.announcementStatus,
      );
      editInstPayload.append(
        "backgroundType",
        instructionMessageObj.backgroundType,
      );
      editInstPayload.append(
        "backgroundColor",
        instructionMessageObj.backgroundColor,
      );
      editInstPayload.append("backgroundImage", imageFile);
      handleinstructionApi(editInstPayload)
        .then((res) => {
          if (res.data.status === "Success") {
            setSuccessMsg(res?.data?.message);
            setAlertstatus("success");
            setOpen(true);
            setLoading(false);
            handleSucces();
          } else {
            setSuccessMsg(res?.data?.error?.description);
            setAlertstatus("warning");
            setOpen(true);
            return Promise.reject(res);
          }
        })
        .catch((response) => {
          if (response?.response?.data?.status === 500) {
            setModelWin(true);
            setCatchError(response?.response?.data?.message);
          } else {
            setSuccessMsg(response.data.error.description);
            setAlertstatus("warning");
            setOpen(true);
          }
        });
    }
  };
  const handleSave = (cond) => {
    if (cond === "Add") {
      handleAddApi();
    } else {
      handleEditApi();
    }
  };
  const handleBannerAnnouncement = () => {
    const allInstructionPayload = new FormData();
    allInstructionPayload.append("flag", "all");
    handleinstructionApi(allInstructionPayload)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setRowData(res?.data?.data);
        }
      })
      .catch((e) => {
        if (e?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(e?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    handleBannerAnnouncement();
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.4,
      hide: true,
    },
    {
      field: "name",
      headerName: "Banner Name",
      flex: 4,
    },
    {
      field: "description",
      headerName: "Banner Description",
      flex: 4,
      renderCell: (params) => {
        return (
          <div
            // style={{
            //   color: "#fff",
            //   // backgroundColor: params.row.status ? "green" : "red",
            //   padding: "3px",
            //   textAlign: "center",
            //   borderRadius: "50px",
            //   padding: ".2rem 1rem"
            // }}

            dangerouslySetInnerHTML={{
              __html: params?.row?.description.slice(0, 150),
              // `${item.description.slice(0, 150)} [....]`
            }}
          />
        );
      },
    },
    {
      field: "textColor",
      headerName: "Text Color",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div
            style={{
              border: "1px solid #b0abab",
              padding: "1rem 0 1rem .2rem",
              width: "100%",
              height: "30px",
              backgroundColor: params?.row?.textColor,
              // backgroundColor: "black"
            }}
          ></div>
        );
      },
    },
    {
      field: "backgroundColor",
      headerName: "Background Color",
      flex: 2,
      renderCell: (params) => {
        // return (
        //   <div
        //     style={{
        //       border: "1px solid #b0abab",
        //       padding: "1rem 0 1rem .2rem",
        //       width: "100%",
        //       height: "30px",
        //       backgroundColor: params.row.backgroundType
        //         ? // ? params?.row?.backgroundColor
        //           "#652020"
        //         : "black"
        //     }}
        //   ></div>
        // );
        return params.row.backgroundType ? (
          <div
            style={{
              border: "1px solid #b0abab",
              padding: "1rem 0 1rem .2rem",
              width: "100%",
              height: "30px",
              backgroundColor: params?.row?.backgroundColor,
            }}
          ></div>
        ) : (
          <div
            style={{
              padding: "1rem 0 1rem .2rem",
              width: "100%",
              height: "30px",
              fontSize: "10px",
            }}
          >
            No back-ground-Color
          </div>
        );
      },
    },
    {
      field: "backgroundImage",
      headerName: "Background Image",
      flex: 2,
      renderCell: (params) => {
        return !params.row.backgroundType ? (
          <div
            style={{
              width: "100%",
              overflow: "auto",
              padding: "1rem 0 1rem .2rem",
            }}
          >
            <img
              src={params?.row?.backgroundImage}
              alt="image"
              width={"100px"}
              height={"100px"}
            />
          </div>
        ) : (
          <div style={{ fontSize: "10px" }}>No image</div>
        );
      },
    },
    {
      field: "5",
      headerName: "Announcement Status",
      flex: 2,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              backgroundColor: params.row.announcementStatus ? "green" : "red",
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.announcementStatus ? "ON" : "OFF"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none",
                margin: ".5rem",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setParamData(params.row);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      {" "}
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Instruction
      </TransparentHead>
      <div
        style={{
          width: "100%",
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem",
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <StyledGreenButton
            onClick={() => handleInstructionMenu("add")}
            style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
          >
            <Add fontSize="small" /> Add New
          </StyledGreenButton>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem",
            color: "black",
          }}
        >
          <InputBase
            placeholder="Search..."
            value={searchVal}
            size="small"
            style={{
              width: "35rem",
              lineHeight: "1em",
              color: "#000",
              border: "1px solid #000",
              margin: ".4rem 0 .5rem 0",
              padding: ".3rem 0 .3rem .2rem",
            }}
            onChange={handleInputChange}
          />
        </div>
        <AdminDataGrid loading={loading} columns={columns} rows={resData} />
      </div>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
      <Modal
        width={650}
        title={
          <Typography variant="h6" gutterBottom>
            {modalFor === "Add"
              ? "Add Announcement Banner"
              : "Edit Announcement Banner"}
          </Typography>
        }
        open={openModal}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <div
          style={{
            ...(instructionMessageObj.backgroundType === false
              ? {
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundImage:
                    "url(" + instructionMessageObj.backgroundImage + ")",
                }
              : {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : instructionMessageObj.backgroundColor,
                }),
            display: "flex",
            height: "40%",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            padding: "1rem 0.75rem 1rem 1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            {/*<div style={{marginRight: "1rem"}}>
              <Campaign style={{ color: theme.palette.mode === "dark" ? theme.palette.background.default : instructionMessageObj.textColor }}></Campaign>
            </div>*/}
            <div>
              <p
                style={{
                  textAlign: "center",
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : instructionMessageObj.textColor,
                }}
                dangerouslySetInnerHTML={{
                  __html: instructionMessageObj.description,
                }}
              ></p>
            </div>
          </div>
        </div>
        <form>
          <div className="form-group">
            <AboutParaTypo>Banner Name</AboutParaTypo>
            <OutlinedInput
              style={{ margin: ".5rem 0 1.5rem 0" }}
              size="small"
              fullWidth
              value={instructionMessageObj.name}
              onChange={(e) => {
                setInstructionMessageObj({
                  ...instructionMessageObj,
                  name: e.target.value,
                });
              }}
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </div>
          <Divider>
            <Chip label="Message" />
          </Divider>
          <div className="form-group">
            <AboutParaTypo>Banner text</AboutParaTypo>
            {/*<OutlinedInput
                fullWidth
                multiline
                rows={4}
                value={instructionMessageObj.description}
                onChange={(e) => {
                  setInstructionMessageObj({...instructionMessageObj, description: e.target.value});
                }}
                inputProps={{
                  'aria-label': 'weight',
                }}
            />*/}
            <JoditEditor
              key={"BannerDescription"}
              value={instructionMessageObj.description}
              onChange={(val) => {}}
              tabIndex={1}
              onBlur={(val) =>
                setInstructionMessageObj({
                  ...instructionMessageObj,
                  description: val,
                })
              }
              style={{ height: "100px" }}
            />
          </div>
          <Divider />
          <div className="form-group">
            <AboutParaTypo>Banner text color</AboutParaTypo>
            <OutlinedInput
              type="text"
              style={{ margin: ".5rem 0 1.5rem 0" }}
              value={instructionMessageObj.textColor}
              size="small"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <input
                    type="color"
                    value={instructionMessageObj.textColor}
                    onChange={(e) => {
                      setInstructionMessageObj({
                        ...instructionMessageObj,
                        textColor: e.target.value,
                      });
                    }}
                  />
                </InputAdornment>
              }
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </div>
          <Divider />

          <div className="form-group">
            <FormLabel id="demo-radio-buttons-group-label">
              Select background type of description (color / Image):
            </FormLabel>
            <RadioGroup
              row
              defaultValue={instructionMessageObj.backgroundType}
              onChange={(e) => {
                setInstructionMessageObj({
                  ...instructionMessageObj,
                  backgroundType: JSON.parse(e.target.value),
                });
              }}
            >
              <FormControlLabel
                value="true"
                checked={instructionMessageObj.backgroundType === true}
                control={<Radio />}
                label="Background Colors"
              />
              <FormControlLabel
                value="false"
                checked={instructionMessageObj.backgroundType === false}
                control={<Radio />}
                label="Background Image"
              />
            </RadioGroup>
          </div>

          {instructionMessageObj.backgroundType === true ? (
            <div className="form-group">
              <AboutParaTypo>Banner Background Colors</AboutParaTypo>
              <OutlinedInput
                type="text"
                style={{ margin: ".5rem 0 1.5rem 0" }}
                value={instructionMessageObj.backgroundColor}
                size="small"
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <input
                      type="color"
                      value={instructionMessageObj.backgroundColor}
                      onChange={(e) => {
                        setInstructionMessageObj({
                          ...instructionMessageObj,
                          backgroundColor: e.target.value,
                        });
                      }}
                    />
                  </InputAdornment>
                }
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </div>
          ) : null}
          <Divider />

          {instructionMessageObj.backgroundType === false ? (
            <div className="form-group">
              <AboutParaTypo>Banner Background Image</AboutParaTypo>
              {imageDesign ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={instructionMessageObj?.backgroundImage}
                    alt="image"
                    width={300}
                    height={200}
                  />
                  <div style={{ marginTop: "1rem" }}>
                    <Button
                      style={{
                        textTransform: "none",
                        backgroundColor: "black",
                      }}
                      onClick={handleImageDesign}
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      {...getRootProps()}
                      style={{
                        border: "2px gray dashed",
                      }}
                    >
                      <input type="file" id="image" {...getInputProps()} />
                      {isDragActive ? (
                        <p style={{ color: "gray" }}>
                          Drop your image files here
                        </p>
                      ) : (
                        <p style={{ color: "gray", margin: "1rem" }}>
                          Drag and drop some Image here, or click to select
                          Image
                        </p>
                      )}
                    </div>
                  </div>
                  {isCancelBtnEnabled ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <Button
                        style={{
                          textTransform: "none",
                          backgroundColor: "black",
                        }}
                        onClick={handleCancelImageEdit}
                      >
                        cancel
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : null}
          {/*<div className="form-group">
            <AboutParaTypo>Allow banner dismissal</AboutParaTypo>
            <Switch checked={ImageCheckBox} onChange={onChangeImage}/>
          </div>*/}
          <Divider />

          <div className="form-group">
            <FormLabel id="demo-radio-buttons-group-label">
              Is Enabled this Content to the user:
            </FormLabel>
            <RadioGroup
              row
              defaultValue={instructionMessageObj.announcementStatus}
              onChange={(e) => {
                setInstructionMessageObj({
                  ...instructionMessageObj,
                  announcementStatus: JSON.parse(e.target.value),
                });
              }}
            >
              <FormControlLabel
                value="true"
                checked={instructionMessageObj.announcementStatus === true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="false"
                checked={instructionMessageObj.announcementStatus === false}
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </div>
        </form>
        <div style={{ marginTop: "1rem" }}>{thumbs}</div>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button onClick={() => handleSave(modalFor)}>Save</Button>
        </div>
      </Modal>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem onClick={() => handleInstructionMenu("Edit", paramData)}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete("Delete", paramData)}>
          Delete
        </MenuItem>
      </Menu>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};
